import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { ANSWER_TYPE, QUESTION_TYPE } from "const";
import { setModal } from "features/modalSlice";
import {
  createSection,
  getSection,
  getSectionsMenu,
  setSectionsMenu,
  updateSection,
} from "features/sectionSlice";
import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { PayloadType, SectionType } from "type";
import { closeModal, getBody } from "utils";
import * as yup from "yup";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
interface CUExamSettingProp {
  getSectionsData: () => void;
}
function CUExamSetting({ getSectionsData }: CUExamSettingProp) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const modal = useSelector((state: any) => state.modalReducer);
  const { section } = useSelector((state: any) => state.sectionReducer);
  //validate
  const examSettingSchema = yup.object().shape({
    code: yup.string().required("Mã phần thi không được để trống!"),
    name: yup.string().required("Tên phần thi không được để trống!"),
    year: yup.string().required("Năm của phần thi không được để trống!"),
    isShowSide: yup
      .string()
      .required("Vui lòng chọn ẩn/hiện trên menu hệ thống!"),
    questionType: yup
      .string()
      .required("Dạng câu hỏi phần thi không được để trống!"),
    answerType: yup
      .string()
      .required("Dạng câu trả lời phần thi không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await examSettingSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    if (!!modal) {
      form.resetFields();
    }
  }, [modal]);
  const initValues = !!section
    ? {
        ...section,
        year: moment(_.get(section, "year", moment()), "YYYY"),
      }
    : {
        code: "",
        name: "",
        answerType: null,
        des: "",
        id: "",
        isShowSide: true,
        questionType: null,
        year: moment(),
      };
  // Cập nhật lại sidebar khi thêm/sửa/xóa thành công
  const reloadSibar = () => {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          page: 1,
          limit: 99999,
          year: "",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(sections) {
          dispatch(setSectionsMenu(sections));
        },
        failed(errorMessage) {
          console.log("Lấy danh sách menu thất bại!");
        },
      },
    };
    dispatch(getSectionsMenu(payload));
  };
  //Tạo mới
  function handleCreateSection(values: SectionType) {
    const payload: PayloadType = {
      body: getBody(
        {
          ...values,
          year: moment(_.get(values, "year", moment())).get("year"),
        },
        []
      ),
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới phần thi thành công!",
          });
          closeModal(dispatch, setModal);
          form.resetFields();
          getSectionsData();
          reloadSibar();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới phần thi thất bại!",
              description: "Tên phần thi đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Tạo mới phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createSection(payload));
  }
  function handleUpdateSection(values: SectionType) {
    const payload: PayloadType = {
      params: _.get(section, "id", ""),
      body: {
        ...values,
        year: moment(_.get(values, "year", moment())).get("year"),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật phần thi thành công!",
          });
          closeModal(dispatch, setModal);
          form.resetFields();
          getSectionsData();
          reloadSibar();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Cập nhật phần thi thất bại!",
              description: "Tên phần thi đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Cập nhật phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateSection(payload));
  }
  return (
    <Form
      labelCol={{ span: 8 }}
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={!section ? handleCreateSection : handleUpdateSection}
    >
      <div className="ant-modal-body">
        <Form.Item required rules={[yupSync]} label={"Năm"} name={"year"}>
          <DatePicker
            picker="year"
            placeholder="Chọn năm"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          required
          rules={[yupSync]}
          label={"Mã phần thi"}
          name={"code"}
        >
          <Input placeholder="Nhập vào mã phần thi" />
        </Form.Item>
        <Form.Item
          required
          rules={[yupSync]}
          label={"Tên phần thi"}
          name={"name"}
        >
          <Input placeholder="Nhập vào tên phần thi" />
        </Form.Item>
        <Form.Item
          required
          rules={[yupSync]}
          label={"Hiển thị trên sidebar"}
          name={"isShowSide"}
        >
          <Radio.Group defaultValue={true}>
            <Radio value={true} defaultChecked>
              Có
            </Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          required
          rules={[yupSync]}
          label={"Dạng câu hỏi"}
          name={"questionType"}
        >
          <Select options={QUESTION_TYPE} placeholder={"Chọn dạng câu hỏi"} />
        </Form.Item>
        <Form.Item
          required
          rules={[yupSync]}
          label={"Dạng đáp án"}
          name={"answerType"}
        >
          <Select options={ANSWER_TYPE} placeholder={"Chọn dạng đáp án"} />
        </Form.Item>
        <Form.Item label={"Mô tả"} name={"des"}>
          <TextArea rows={3} placeholder={"Nhập mô tả"} />
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button type="primary" htmlType="submit">
          Xác nhận
        </Button>
      </div>
    </Form>
  );
}

export default CUExamSetting;

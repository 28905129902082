import { createSlice } from "@reduxjs/toolkit";

export const routeSlice: any = createSlice({
  name: "routeSlice",
  initialState: {
    routes: [],
    route: {},
  },
  reducers: {
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
  },
});
export const { setRoutes } = routeSlice.actions;
export default routeSlice.reducer;

/**
 * Reducer cho lĩnh vực
 */
import { createSlice } from "@reduxjs/toolkit";

export const fieldSlice: any = createSlice({
  name: "fieldSlice",
  initialState: {
    field: {},
    queryField: {},
  },
  reducers: {
    getFieldBySectionID: (state, action) => {},
    getFieldBySectionIDSuccess: (state, action) => {
      state.field = action.payload;
    },
  },
});
export const { getFieldBySectionID, getFieldBySectionIDSuccess } =
  fieldSlice.actions;
export default fieldSlice.reducer;

import { getRoleSuccess, getRolesSuccess } from "features/roleSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET, POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType, RoleType } from "type";
var _ = require("lodash");
export function* getRoles(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.role}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getRolesSuccess(_.get(res, "data.results", {})));
      callback.success(_.get(res, "data", {}));
      return _.get(res, "data", {});
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getRoleByName(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.role}?${query ?? ""}`;
  console.log(params)
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      const results = _.get(res, "data.results", []);
      console.log(results)
      const role: RoleType = results.find(
        (item: RoleType) => item.name === params
      );
      yield put(getRoleSuccess(role));
      callback.success(_.get(role, "id", ""));
      return _.get(res, "data", {});
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* roleSaga() {
  yield takeLatest("roleSlice/getRoles", getRoles);
  yield takeLatest("roleSlice/getRoleByName", getRoleByName);
}

import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const sectionSlice: any = createSlice({
  name: "sectionSlice",
  initialState: {
    sections: [],
    sectionsMenu: [],
    section: {},
    fields: {},
    query: {
      page: 1,
      limit: 10,
      year: moment().get("year")
    },
    sectionActive: null // phần thi đang được chọn ở sidebar
  },
  reducers: {
    getSections: (state, action) => {},
    getSectionsMenu: (state, action) => {},
    setSectionsMenu: (state, action) => {
      state.sectionsMenu = action.payload;
    },
    getSection: (state, action) => {},
    getFields: (state,action) => {},
    getFieldsSuccess: (state,action) => {
      state.fields = action.payload;
    },
    setSection: (state, action) => {
      state.section = action.payload;
    },
    setSectionActive: (state, action) => {
      state.sectionActive = action.payload;
    },
    updateSection: (state, action) => {},
    getSectionsMenuSuccess: (state, action) => {
      state.sections = action.payload;
    },
    getSectionsSuccess: (state, action) => {
      state.sections = action.payload;
    },
    createSection: (state, action) => {},
    addFields: (state, action) => {},
    deleteSection: (state, action) => {},
    setQuery: (state, acion) => {
      state.query = acion.payload;
    },
  },
});
export const {
  getSections,
  getSectionsMenu,
  getSectionsSuccess,
  getSectionsMenuSuccess,
  getSection,
  setSection,
  setQuery,
  createSection,
  deleteSection,
  updateSection,
  getFields,
  getFieldsSuccess,
  setSectionActive,
  addFields,
  setSectionsMenu
} = sectionSlice.actions;
export default sectionSlice.reducer;

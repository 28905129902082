/**
 * Màn hình Thông tin tài khoản > Thiết lập tài khoản
 */
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import * as yup from "yup";
import { PayloadType } from "type";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { changePassword } from "features/accountSlice";
const { Title } = Typography;
interface ResetpassFormType {
  oldPassword: string;
  newPassword: string;
}
function SecuritySettingAccount() {
  const [newPass, setNewPass] = useState("");
  const dispatch = useDispatch();
  const info = localStorage.getItem("accountInfo");
  const userInfo = !!info ? JSON.parse(info) : null;
  let settingBasicSchema = yup.object().shape({
    oldPassword: yup.string().required("Mật khẩu cũ không được để trống!"),
    newPassword: yup
      .string()
      .required("Mật khẩu mới không được để trống!")
      .test(
        "password",
        "Mật khẩu phải dài ít nhất 8 kí tự bao gồm chữ thường, chữ hoa , số và kí tự đặc biệt!",
        (password) => {
          const reg =
          /[^wd]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))/g;
          return reg.test(password);
        }
      ),
    newPasswordConfirm: yup
      .string()
      .required("Vui lòng xác nhận lại mật khẩu!")
      .test(
        "check-confirm-pass",
        "Mật khẩu không khớp!",
        (newConfirmPass) => newConfirmPass === newPass
      ),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await settingBasicSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const handleChangePassword = (values: ResetpassFormType) => {
    const payload: PayloadType = {
      params: _.get(userInfo, "id", ""),
      body: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Thay đổi mật khẩu thành công!",
          });
        },
        failed(errorMessage) {
          if (errorMessage){
            notification.error({
              message: "Thay đổi mật khẩu thất bại!",
              description: errorMessage,
            });
            return;
          }
          notification.error({
            message: "Thay đổi mật khẩu thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(changePassword(payload));
  };
  return (
    <Space
      direction="vertical"
      size={"middle"}
      style={{
        width: "100%",
      }}
    >
      <Title level={4}>Cài đặt bảo mật</Title>
      <Form
        initialValues={{ remember: true }}
        onFinish={(values) => {
          handleChangePassword(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col
            span={6}
            style={{
              minWidth: 300,
            }}
          >
            <Form.Item
              label="Nhập mật khẩu cũ"
              name="oldPassword"
              labelAlign="left"
              required
              rules={[yupSync]}
            >
              <Input.Password allowClear placeholder="Nhập mật khẩu cũ" />
            </Form.Item>
            <Form.Item
              label="Nhập mật khẩu mới"
              name="newPassword"
              required
              labelAlign="left"
              rules={[yupSync]}
            >
              <Input.Password
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
                allowClear
                placeholder="Nhập mật khẩu"
              />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu mới"
              required
              name="newPasswordConfirm"
              labelAlign="left"
              rules={[yupSync]}
            >
              <Input.Password allowClear placeholder="Nhập lại mật khẩu" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}

export default SecuritySettingAccount;

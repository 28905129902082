/**
 * Form thiết lập câu hỏi
 */
import { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Select, Space, Upload } from "antd";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { FieldsBySectionID, PayloadType, SelectOptionType } from "type";
import { useSelector } from "react-redux";
import { addFields, getFields } from "features/sectionSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormInstance } from "antd/es/form";
import { QUESTION_TYPE } from "const";
const parse = require("html-react-parser");
interface NewFieldData {
  value: string;
  error: string;
}
interface QuestionForm {
  form: FormInstance;
}
function QuestionForm({ form }: QuestionForm) {
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  const dispatch = useDispatch();
  const { dataUpdate } = useSelector((state: any) => state.modalReducer);

  const [newFieldData, setNewFieldData] = useState<NewFieldData | null>(null);
  const [questionTypeOption, setQuestionTypeOption] = useState<
    SelectOptionType | any
  >();
  const [fieldOptions, setFieldOptions] = useState<SelectOptionType[]>([]);
  // Khởi tạo giá trị cho form sửa
  useEffect(() => {
    handleGetFields(); // Lấy options cho ô select lĩnh vực
    if (!!dataUpdate) {
      // Khởi tạo form sửa
      const questionT = _.get(dataUpdate, "questionType", "");
      form.setFieldValue("questionType", questionT);
      form.setFieldValue("field", _.get(dataUpdate, "field", ""));
      form.setFieldValue("content", _.get(dataUpdate, "content", ""));
      form.setFieldValue(
        "videoLink",
        _.get(dataUpdate, "othersContent.video.url", "")
      );
      form.setFieldValue(
        "failProcess",
        _.get(dataUpdate, "failProcess", "continue")
      );
      form.setFieldValue(
        "isRandomOptions",
        _.get(dataUpdate, "isRandomOptions", true)
      );

      setQuestionTypeOption(
        QUESTION_TYPE.find((item: SelectOptionType) => item.value === questionT)
      );
      return;
    }
    // Khởi tạo form thêm mới
    form.resetFields();
    const questionT = _.find(QUESTION_TYPE, function (o) {
      return o.value === _.get(sectionActive, "questionType", "");
    });
    setQuestionTypeOption(questionT);
    form.setFieldValue("questionType", questionT?.value);
  }, [dataUpdate]);
  // Lấy danh sách lựa chọn lĩnh vực
  function handleGetFields() {
    const payload: PayloadType = {
      params: sectionActive?.id ?? "",
      skipUpdateReducer: true,
      callback: {
        success(fields: FieldsBySectionID) {
          if (!!fields && Array.isArray(fields?.fields)) {
            setFieldOptions(
              fields.fields.map((item: string) => {
                return {
                  label: item,
                  value: item,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getFields(payload));
  }
  //Thêm mới 1 lĩnh vực
  function handleAddNewField() {
    setFieldOptions([
      ...fieldOptions,
      {
        label: newFieldData?.value,
        value: newFieldData?.value,
      },
    ]);
    const payload: PayloadType = {
      params: sectionActive?.id ?? "",
      body: {
        field: newFieldData?.value,
      },
      callback: {
        success(fields: FieldsBySectionID) {},
        failed(errorMessage) {},
      },
    };
    dispatch(addFields(payload));
  }
  function htmlDecode(input: any) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }
  return (
    <Form
      form={form}
      labelCol={{
        span: 24,
      }}
    >
      <Form.Item
        name={"questionType"}
        label={"Dạng câu hỏi"}
        initialValue={
          !!dataUpdate
            ? _.get(dataUpdate, "questionType", "")
            : _.get(sectionActive, "questionType", "")
        }
      >
        <Select
          options={QUESTION_TYPE}
          placeholder="Chọn dạng câu hỏi"
          onChange={(value, option) => {
            if (!_.isArray(option)) setQuestionTypeOption(option);
          }}
        />
      </Form.Item>
      <Form.Item name={"field"} label={"Lĩnh vực"} initialValue={"Chung"}>
        <Select
          options={fieldOptions}
          onDropdownVisibleChange={() => {
            setNewFieldData(null);
          }}
          placeholder="Nhập lĩnh vực"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }} align="start">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Input
                    placeholder="Nhập tên lĩnh vực mới..."
                    value={newFieldData?.value}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setNewFieldData({
                        value: inputVal,
                        error: !!inputVal
                          ? ""
                          : "Lĩnh vực không được để trống!",
                      });
                    }}
                    style={{
                      borderColor:
                        newFieldData != null && !!newFieldData.error
                          ? "red"
                          : undefined,
                    }}
                  />
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {newFieldData != null && newFieldData?.error}
                  </span>
                </div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    if (newFieldData == null) {
                      setNewFieldData({
                        value: "",
                        error: "Lĩnh vực không được để trống!",
                      });
                      return;
                    }
                    if (!newFieldData?.error) {
                      handleAddNewField();
                    }
                  }}
                >
                  Tạo mới
                </Button>
              </Space>
            </>
          )}
        />
      </Form.Item>
      <Form.Item
        required
        name={"content"}
        label={"Câu hỏi"}
        rules={[
          () => ({
            validator(_, value) {
              try {
                let plainText = value.replace(/<[^>]+>/g, "");
                if (!plainText.trim())
                  return Promise.reject("Câu hỏi không được để trống!");
                return Promise.resolve();
              } catch (error) {
                return Promise.reject("Câu hỏi không được để trống!");
              }
            },
          }),
        ]}
      >
        <ReactQuill
          theme="snow"
          value={form.getFieldValue("content")}
          onChange={(value, delta, source, editor) => {
            form.setFieldValue("content", value);
          }}
        />
        <div className="ant-form-item-explain-error">
          {form.getFieldError("content")}
        </div>
      </Form.Item>
      {questionTypeOption?.value === QUESTION_TYPE[1].value ? (
        <>
          {/* <Form.Item
            required
            name={"videoFile"}
            label={"Tải video"}
            initialValue={!!dataUpdate ? form.getFieldValue("videoFile") : ""}
            style={{
              width: "100%",
            }}
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              maxCount={1}
              style={{
                width: "50%",
              }}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item> */}
          <Form.Item
            required
            name={"videoLink"}
            label={"Hoặc nhập URL của video"}
            initialValue={!!dataUpdate ? form.getFieldValue("videoLink") : ""}
            rules={[
              {
                required: true,
                message: "URL video không được để trống!",
              },
            ]}
          >
            <Input placeholder="Nhập URL video" />
          </Form.Item>
        </>
      ) : null}
    </Form>
  );
}

export default QuestionForm;

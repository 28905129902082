/**
 * Màn hình Thông tin tài khoản > Cài đặt cơ bản
 */
import { useState, useEffect } from "react";
import * as yup from "yup";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
  notification,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { memo } from "react";
import moment from "moment";
import { PayloadType } from "type";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "features/userSlice";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { convertToBase64 } from "utils";
const { Title } = Typography;
const UserAvatarDefault = require("assets/img/user-avatar-default.jpg");
interface UserFormType {
  name: string;
  email: string;
  roleId: string;
  address: string;
  gender: string;
  isPasswordChange: boolean;
  status: number;
  createdAt: string;
  dateOfBirth: string;
  updatedAt: string;
  id: string;
}
interface BasicSettingAccount {
  user: any;
  handleGetUserData: () => void;
}
function BasicSettingAccount(props: BasicSettingAccount) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const accountInfo = JSON.parse(localStorage.getItem("accountInfo") || "{}");
  const [avatar, setAvatar] = useState(
    _.get(accountInfo, "avatar", false) || UserAvatarDefault
  );
  const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState<any>();
  const { user } = useSelector((state: any) => state.userReducer);
  let settingBasicSchema = yup.object().shape({
    name: yup
      .string()
      .required("Tên người dùng không được để trống!")
      .test((str) => {
        return true;
      }),
    dateOfBirth: yup.string().required("Ngày sinh không được để trống!"),
    gender: yup.string().required("Giới tính không được để trống!"),
    address: yup.string().required("Địa chỉ không được để trống!"),
    email: yup
      .string()
      .email("Địa chỉ email không hợp lệ!")
      .required("Địa chỉ email không được để trống!"),
    phone: yup
      .string()
      .required("Số điện thoại không được để trống!")
      .test("check-phone", "Số điện thoại không đúng định dạng!", (phone) => {
        const reg = /0[1-9][0-9]{8}/g;
        return reg.test(phone) && phone.length === 10;
      }),
    accountType: yup.string().required("Loại tài khoản không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await settingBasicSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleUpdateUser(values: UserFormType) {
    setIsLoading(true);
    const { address, dateOfBirth, email, gender, name, phone }: any = values;
    if (_.get(imgFile, "size", -1) > 200000) {
      //Kiểm tra dung lượng ảnh
      notification.error({
        message: "Upload ảnh thất bại!",
        description: "Không thể upload ảnh quá 200KB!",
      });
      setIsLoading(false);
      return;
    }
    const payload: PayloadType = {
      params: _.get(user, "id", ""),
      body: {
        avatar: imgBase64,
        address,
        email,
        name,
        phone,
        gender,
        roleId: _.get(user, "roleId", ""),
        dateOfBirth: !!dateOfBirth
          ? moment(dateOfBirth).format("MM/DD/YYYY")
          : null,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật thông tin tài khoản thành công!",
            duration: 2,
            onClose() {},
          });
          localStorage.setItem("accountInfo", JSON.stringify(values));
          setIsLoading(false);
          props.handleGetUserData();
          window.location.reload();
        },
        failed(errorMessage) {
          let err = errorMessage;
          if (errorMessage?.includes("Email already taken")) {
            err = "Email này đã được đăng ký!";
          }
          notification.error({
            message: "Cập nhật thông tin tài khoản thất bại!",
            duration: 2,
            description: err,
            onClose() {
              setIsLoading(false);
            },
          });
        },
      },
    };
    dispatch(updateUser(payload));
  }
  function handleUploadChange(info: UploadChangeParam<UploadFile<any>>) {
    const { fileList } = info;
    if (_.isArray(fileList) && fileList.length > 0) {
      const fileInfo = _.get(fileList[0], "originFileObj", {});
      setImgFile(fileInfo);
      convertToBase64(fileInfo, (base64) => {
        setImgBase64(base64);
        setAvatar(base64);
      });
    }
  }
  return (
    <Space
      direction="vertical"
      size={"middle"}
      style={{
        width: "100%",
      }}
    >
      <Title level={4}>Cài đặt cơ bản</Title>
      <Form
        initialValues={{ remember: true }}
        onFinish={(values) => {
          handleUpdateUser(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col xxl={5} lg={7}style={{
            minWidth: 250
          }}>
            <Form.Item
              label="Tên tài khoản"
              required
              name="name"
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "name", "")}
            >
              <Input allowClear placeholder="Nhập tên tài khoản" />
            </Form.Item>
            <Form.Item
              required
              name="gender"
              label="Giới tính"
              rules={[yupSync]}
              initialValue={_.get(props.user, "gender", "")}
            >
              <Radio.Group>
                <Radio value="Nam">Nam</Radio>
                <Radio value="Nữ">Nữ</Radio>
                <Radio value="Khác">Khác</Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item
              required
              name="dateOfBirth"
              label="Ngày sinh"
              rules={[yupSync]}
              initialValue={
                !!_.get(props.user, "dateOfBirth", "")
                  ? moment(_.get(props.user, "dateOfBirth", undefined))
                  : ""
              }
            >
              <DatePicker
                placeholder="Chọn ngày"
                style={{
                  width: "100%",
                }}
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item> */}
            <Form.Item
              label="Email"
              name="email"
              required
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "email", "")}
            >
              <Input allowClear disabled placeholder="Nhập Email" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại"
              name="phone"
              required
              labelAlign="left"
              rules={[yupSync]}
              initialValue={_.get(props.user, "phone", "")}
            >
              <Input allowClear placeholder="Nhập số điện thoại" />
            </Form.Item>
            <Form.Item
              label="Địa chỉ"
              // required
              name={"address"}
              // rules={[yupSync]}
              initialValue={_.get(props.user, "address", "")}
            >
              <TextArea placeholder="Nhập địa chỉ" rows={3} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Lưu lại
              </Button>
            </Form.Item>
          </Col>
          <Col
            style={{
              marginLeft: 48,
            }}
            xxl={8}
          >
            <Form.Item
              name="upload"
              style={{
                width: "100%",
              }}
              labelAlign="right"
              // valuePropName="fileList"
              getValueFromEvent={() => {}}
              extra=""
            >
              <Space direction="vertical">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <label>Ảnh đại diện</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar src={<Image src={avatar} />} size={72} />
                </div>
                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="picture"
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleUploadChange}
                >
                  <Button icon={<UploadOutlined />}>
                    Tải lên ảnh đại diện
                  </Button>
                </Upload>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}

export default BasicSettingAccount;

import { notification } from "antd";
import { getSchoolSuccess, getSchoolsSuccess } from "features/schoolSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getClassrooms } from "sagas/classroomSaga";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getSchools(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.school}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      // const classrooms = yield all(
      //   res.data.data.map((school: any) => {
      //     return call(getClassrooms, {
      //       payload: {
      //         query: queryString.stringify({
      //           schoolId: _.get(school, "id", ""),
      //           page: 1,
      //           limit: 1,
      //         }),
      //         callback: {
      //           success(values) {},
      //           failed(errorMessage) {},
      //         },
      //       },
      //       type: "classroomSlice/getClassrooms",
      //     });
      //   })
      // );
      // const newSchool = _.get(res, "data.data", []).map(
      //   (school: any, index: number) => {
      //     return {
      //       ...school,
      //       classroomCount: _.get(classrooms[index], "totalResults", ""),
      //     };
      //   }
      // );
      // const data = _.get(res, "data", {});
      // yield put(
      //   getSchoolsSuccess({
      //     ...data,
      //     data: newSchool,
      //   })
      // );
      // callback.success({
      //   ...data,
      //   data: newSchool,
      // });
      if (!skipUpdateReducer)
        yield put(getSchoolsSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getSchool(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.school}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getSchoolSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createSchool(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.school;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateSchool(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.school}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteSchool(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.school}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* schoolSaga() {
  yield takeLatest("schoolSlice/getSchools", getSchools);
  yield takeLatest("schoolSlice/getSchool", getSchool);
  yield takeLatest("schoolSlice/createSchool", createSchool);
  yield takeLatest("schoolSlice/updateSchool", updateSchool);
  yield takeLatest("schoolSlice/deleteSchool", deleteSchool);
}

import _ from "lodash";
import React from "react";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, MenuProps, Space, Typography } from "antd";
import { Layout } from "antd";
import Logo from "assets/img/bfd.png";
import AvatarImage from "assets/img/user-avatar-default.jpg";
import { Link, useHistory } from "react-router-dom";
const { Header } = Layout;
const { Title, Text } = Typography;
const AdminHeader: React.FC = () => {
  const accountInfo = JSON.parse(localStorage.getItem("accountInfo") || "{}");
  const history = useHistory();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a href="/admin/account-info">
          <Space direction="horizontal">
            <UserOutlined /> Tài khoản
          </Space>
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href="/admin/account-setting">
          <Space direction="horizontal">
            <SettingOutlined /> Cài đặt
          </Space>
        </a>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            localStorage.clear();
            history.push("/auth/login");
          }}
        >
          <Space direction="horizontal">
            <LogoutOutlined /> Thoát
          </Space>
        </a>
      ),
    },
  ];
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#1765AD",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={"/"}>
          <img alt="logo" className="demo-logo" src={Logo} height={52} />
        </Link>
        <Title
          style={{
            color: "#FFFFFF",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 500,
            marginBottom: 0,
            marginLeft: 12,
          }}
          level={5}
        >
          PHẦN MỀM QUẢN LÝ THI TRẮC NGHIỆM
        </Title>
      </div>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Dropdown menu={{ items }} placement="bottom" trigger={["click"]}>
          <Button
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
          >
            <Avatar
              src={
                <img
                  src={_.get(accountInfo, "avatar", false) || AvatarImage}
                  alt="avatar"
                />
              }
            />
            <Text
              style={{
                color: "#FFFFFF",
                marginBottom: 0,
                marginLeft: 12,
              }}
            >
              {_.get(accountInfo, "name", "")}
            </Text>
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AdminHeader;

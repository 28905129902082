import { createSlice } from "@reduxjs/toolkit";

export const ExamGroupSlice: any = createSlice({
  name: "examGroupSlice",
  initialState: {
    examGroups: [],
    query: {
      page: 1,
      limit: 10,
    },
    question: {},
    filters: null,
    isUpdateForm: false,
  },
  reducers: {
    getExamGroups: (state, action) => {},
    getExamGroup: (state, action) => {},
    getExamGroupsSuccess: (state, action) => {
      state.examGroups = action.payload;
    },
    getExamGroupSuccess: (state, action) => {
      state.question = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setStateExamGroup: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createExamGroup: (state, action) => {},
    updateExamGroup: (state, action) => {},
    deleteExamGroup: (state, action) => {},
  },
});
export const {
  getExamGroups,
  getExamGroup,
  updateExamGroup,
  getExamGroupsSuccess,
  getExamGroupSuccess,
  setQuery,
  setFilter,
  createExamGroup,
  deleteExamGroup,
  setStateExamGroup,
} = ExamGroupSlice.actions;
export default ExamGroupSlice.reducer;

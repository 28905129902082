/**
 * Xoá các key có trong deleteKeys nếu values[key] là giá trị null
 * Thay thế các key có trong replaceKeys nếu values[key] là giá trị falsely
 * @param values
 * @param deleteKeys
 * @param replaceKeys
 * @returns Object
 */
export const checkBodyRequest = (
  values: Object,
  deleteKeys: string[],
  replaceKeys: string[]
) => {
  const tmp: any = { ...values };
  deleteKeys.map((key: string) => {
    if (!tmp[`${key}`]) {
      delete tmp[`${key}`];
    }
  });
  replaceKeys.map((key: string) => {
    if (tmp[`${key}`] !== null) {
      tmp[`${key}`] = null;
    }
  });
  return tmp;
};

/**
 * Kiểm tra xem có sự thay đổi nào trong các object hay không?
 * @param oldObject
 * @param newObject
 * @returns true nếu khác nhau, false nếu giống nhau
 */
export const checkAnyCheckObject = (oldObject: any, newObject: any) => {
  const newObjectKey = Object.keys(newObject);
  return newObjectKey.some((key: string) => {
    return oldObject[`${key}`] !== newObject[`${key}`];
  });
};

/**
 * Đóng modal
 * @param dispatch useDispatch()
 * @param setModal features/modalSlice
 */
export function closeModal(
  dispatch: (param: any) => void,
  setModal: (values: any) => {}
) {
  dispatch(
    setModal({
      open: false,
      content: "",
      title: "",
      dataUpdate: null,
    })
  );
}

/**
 * - Dùng trong columns trong định nghĩa Table
 * Kiểm tra có phải phần tử cuối của mảng
 * @param index chỉ số hiện tại
 * @param arr mảng hiện tại
 * @param results kết quả trả về nếu là cuối mảng
 * @returns [results] or []
 */
export default function getRuleOfTable(
  index: number,
  arr: any[],
  results: any
) {
  if (index !== arr.length - 1 || arr.length === 1) return [results];
  return [];
}

/**
 * Tạo validate form
 * @param schema yupSchema
 * @returns yupSync
 */
export function getYupSync(schema: any) {
  const yupSync = {
    async validator({ field }: any, value: any) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };
  return yupSync;
}

/**
 * Lấy ra giá trị validate theo key
 * @param schema
 * @param key
 * @returns
 */
export const getValidateField = (schema: any, key: string) => {
  const yupSync = {
    async validator({ field }: any, value: any) {
      await schema.validateSyncAt(key, { [key]: value });
    },
  };
  return yupSync;
};

/**
 *
 */
export const getBody = (body: any, ignoreKeys: string[]) => {
  const keys = Object.keys(body);
  if (keys.length === 0) return body;
  keys.map((key: any) => {
    if (
      !ignoreKeys.includes(key) &&
      (body[`${key}`] === undefined || body[`${key}`] === "")
    ) {
      delete body[`${key}`];
    }
  });
  return body;
};

/**
 * Chuyển đổi File -> base64
 */

export function convertToBase64(
  file: any,
  converFinish: (base64: any) => void
) {
  // Tạo một đối tượng FileReader
  const reader = new FileReader();
  let base64;
  // Đọc nội dung của file và chuyển đổi sang chuỗi base64 khi hoàn thành
  reader.onloadend = () => {
    base64 = reader.result;
    converFinish(base64);
  };
  // Đọc file dưới dạng URL data và bắt đầu quá trình chuyển đổi sang base64
  reader.readAsDataURL(file);
}
export const _convertToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    // Tạo một đối tượng FileReader
    const reader = new FileReader();
    // Đọc nội dung của file và chuyển đổi sang chuỗi base64 khi hoàn thành
    reader.onloadend = () => resolve(reader.result);
    // Đọc file dưới dạng URL data và bắt đầu quá trình chuyển đổi sang base64
    reader.readAsDataURL(file);
  });

/**
 * làm tròn điểm thi
 * @param value diểm gốc
 * @returns 
 */
export function getScore(value: number | string) {
  let a = parseFloat(value.toString());
  return Math.round(a * 100) / 100;
}

import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Popconfirm,
  Select,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalInfoType, PayloadType, SelectOptionType } from "type";
import { PlusOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import CUExamSetting from "pages/ExamSetting/CUExamSetting";
import queryString from "query-string";
import {
  deleteSection,
  getSection,
  getSections,
  getSectionsMenu,
  setQuery,
  setSection,
  setSectionsMenu,
} from "features/sectionSlice";
import _ from "lodash";
import { ANSWER_TYPE, QUESTION_TYPE } from "const";
const { Title } = Typography;
interface DataType {
  year: Date;
  code: string;
  name: string;
  questionType: string;
  answerType: string;
  des: string;
  createdAt: Date;
}
const data: DataType[] = [
  {
    year: moment().toDate(),
    code: "PT001",
    name: "Trắc nghiệm",
    questionType: "Trắc nghiệm",
    answerType: "Trắc nghiệm",
    des: "Phần thi trắc nghiệm",
    createdAt: moment().toDate(),
  },
];
function ExamSetting() {
  const dispatch = useDispatch();
  const { query, sections } = useSelector((state: any) => state.sectionReducer);
  const [isLoading, setIsLoading] = useState(false);
  // Cập nhật lại sidebar khi thêm/sửa/xóa thành công
  const reloadSidebar = () => {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          page: 1,
          limit: 99999,
          year: "",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(sections) {
          dispatch(setSectionsMenu(sections));
        },
        failed(errorMessage) {
          console.log("Lấy danh sách menu thất bại!");
        },
      },
    };
    dispatch(getSectionsMenu(payload));
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Năm",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 100,
      render(value, record, index) {
        return value;
      },
    },
    {
      title: "Mã phần thi",
      dataIndex: "code",
      key: "code",
      align: "left",
    },
    {
      title: "Tên phần thi",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Dạng câu hỏi",
      dataIndex: "questionType",
      key: "questionType",
      align: "left",
      render(value, record, index) {
        const questionOption = _.find(
          QUESTION_TYPE,
          function (question: SelectOptionType) {
            return question.value === value;
          }
        );
        return !!questionOption ? questionOption.label : "";
      },
    },
    {
      title: "Dạng đáp án",
      dataIndex: "answerType",
      key: "answerType",
      align: "left",
      render(value, record, index) {
        const answerOption = _.find(
          ANSWER_TYPE,
          function (answer: SelectOptionType) {
            return answer.value === value;
          }
        );
        return !!answerOption ? answerOption.label : "";
      },
    },
    {
      title: "Mô tả",
      dataIndex: "des",
      key: "des",
      align: "left",
    },
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   align: "center",
    //   render(value, record, index) {
    //     return !!value ? moment(value).format("DD/MM/YYYY") : "";
    //   },
    // },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 120,
      fixed: 'right',
      render(value, record, index) {
        return (
          <Space>
            <a
              onClick={() => {
                const payload: PayloadType = {
                  params: _.get(record, "id", ""),
                  callback: {
                    success(values) {
                      const payload: ModalInfoType = {
                        open: true,
                        title: "Sửa phần thi",
                        content: (
                          <CUExamSetting getSectionsData={getSectionsData} />
                        ),
                        idUpdate: _.get(record, "id", ""),
                        size: 480,
                      };
                      dispatch(setModal(payload));
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Lấy thông tin phần thi thất bại!",
                      });
                    },
                  },
                };
                dispatch(getSection(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa phần thi này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = _.get(record, "id", null);
                if (!!id) {
                  const payload: PayloadType = {
                    params: id,
                    callback: {
                      success(values) {
                        notification.success({
                          message: "Xoá phần thi thành công!",
                        });
                        getSectionsData();
                        reloadSidebar();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: "Xoá phần thi thất bại!",
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(deleteSection(payload));
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  // Danh sách phần thi
  const getSectionSideBar = () => {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        page: 1,
        limit: 999999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(section) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          setIsLoading(false);
        },
      },
    };
    dispatch(getSections(payload));
  };
  const getSectionsData = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
      }),
      callback: {
        success(values) {
          getSectionSideBar();
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSections(payload));
  };
  useEffect(() => {
    getSectionsData();
  }, [query]);
  useEffect(() => {
    dispatch(
      setQuery({
        ...query,
        year: moment().get("year"),
      })
    );
  }, []);
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4} style={{
            marginBottom: 0,
            
          }}>Thiết lập phần thi</Title>
        </div>
        <>
          <div
            className="space-between"
            style={{
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <Title level={5}>Danh sách phần thi</Title>
            <Space className="custom-placeholder-select">
              <DatePicker
                placeholder="Chọn năm"
                picker="year"
                defaultValue={moment()}
                onChange={(value) => {
                  const year = moment(value).get("year");
                  if (!!year) {
                    dispatch(
                      setQuery({
                        ...query,
                        year,
                        page: 1,
                        limit: 10,
                      })
                    );
                  }
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  dispatch(setSection(null));
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Thêm mới phần thi",
                    content: (
                      <CUExamSetting getSectionsData={getSectionsData} />
                    ),
                    size: 480,
                  };
                  dispatch(setModal(payload));
                }}
              >
                Thêm mới
              </Button>
            </Space>
          </div>
          <Spin tip={"Đang tải..."} spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={_.get(sections, "results", [])}
              pagination={{
                current: _.get(sections, "page", 1),
                pageSize: _.get(sections, "limit", 1),
                total: _.get(sections, "totalResults", 1),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
                onChange(page, pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: page,
                      limit: pageSize,
                    })
                  );
                },
              }}
              scroll={{ x: 1300 }}
            />
          </Spin>
        </>
      </Space>
    </Content>
  );
}

export default ExamSetting;

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { setModal } from "features/modalSlice";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  notification,
} from "antd";
import _ from "lodash";
import * as yup from "yup";
import { useForm } from "antd/lib/form/Form";
import { Fragment, useEffect, useState } from "react";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { PayloadType } from "type";
import { uploadQuestionFile } from "features/questionbanksSlice";
import { closeModal } from "utils";
const { Text } = Typography;
interface UploadQuestionType {
  handleGetQuestionBank: () => void;
}
function UploadQuestion({ handleGetQuestionBank }: UploadQuestionType) {
  //Modal
  const [formValue] = useForm();
  const dispatch = useDispatch();
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  const modal = useSelector((state: any) => state.modalReducer);
  const uploadSchema = yup.object().shape({
    type: yup.object().required("Loại phần thi không được để trống!"),
    typeQuestion: yup.object().required("Loại câu hỏi không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await uploadSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const [fileUpload, setFileUpload] = useState<any>(null);
  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    const { fileList } = info;
    if (!!fileList) {
      setFileUpload(_.get(fileList[0], "originFileObj", null));
    }
  }
  function handleCloseForm() {
    closeModal(dispatch, setModal);
    formValue.setFieldValue("list",[]);
  }
  function confirmUpload() {
    let formData = new FormData();
    formData.append("file", fileUpload);
    const payload: PayloadType = {
      params: _.get(sectionActive, "id", ""),
      data: formData,
      callback: {
        success(values) {
          notification.success({
            message: "Upload File thành công!",
          });
          handleGetQuestionBank();
          handleCloseForm();
        },
        failed(errorMessage) {
          notification.error({
            message: "Upload File thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(uploadQuestionFile(payload));
  }
  useEffect(()=>{
    console.log(formValue.getFieldValue("list"))
  },[formValue.getFieldValue("list")])
  return (
    <Form labelCol={{ span: 24 }} form={formValue}>
      <div className="ant-modal-body">
        <Form.Item
          required
          label={
            <Text>
              Danh sách câu hỏi{" "}
              <a
                href={process.env.REACT_APP_TEMPLATE_EXCEL}
                style={{
                  fontStyle: "italic",
                }}
                target="_blank"
              >
                (Tải xuống mẫu)
              </a>
            </Text>
          }
          name={"list"}
          // rules={[yupSync]}
        >
          {/* <Space align="start"> */}
            <Upload
              name="logo"
              action="/upload.do"
              listType="picture"
              fileList={_.get(formValue.getFieldValue("list"),"fileList",[])}
              beforeUpload={() => false}
              maxCount={1}
              onChange={handleChange}
              accept=".xlsx"
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          {/* </Space> */}
        </Form.Item>
      </div>
      <div
        className="ant-modal-footer"
        style={{
          width: "100%",
        }}
      >
        <Button
          type="default"
          onClick={() => {
            dispatch(
              setModal({
                ...modal,
                open: false,
              })
            );
          }}
        >
          Huỷ
        </Button>
        <Button htmlType="submit" type="primary" onClick={confirmUpload}>
          Xác nhận
        </Button>
      </div>
    </Form>
  );
}

export default UploadQuestion;

import { ReactElement, useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import { ColumnsType } from "antd/lib/table";
import { useForm } from "antd/lib/form/Form";
import {
  PlusSquareOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import getRuleOfTable, { closeModal, getValidateField } from "utils";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  ExamQuestionType,
  FieldType,
  PayloadType,
  QuestionBankType,
  SectionType,
  SelectOptionType,
} from "type";
import {
  getQuestionBanks,
  statisticalFieldBysection,
} from "features/questionbanksSlice";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import {
  createExamQuestion,
  updateExamQuestion,
} from "features/examQuestionSlice";
import { addFields } from "features/sectionSlice";
const { Title } = Typography;
interface DataType {
  key: number;
  field: string | ReactElement;
  questionAmount: number | ReactElement;
  timeEachQuestion: number | ReactElement;
  pointEachQuestion: number | ReactElement;
  isRandomOptions: boolean | ReactElement;
  isQuestionShuffle: boolean | ReactElement;
  isAnswerInOrder: boolean | ReactElement;
  isAdd: boolean;
}
interface CUExam {
  handleGetExamQuestion: () => void;
  examQuestion?: ExamQuestionType;
}
const initDataSource = [
  {
    key: 0,
    field: "",
    questionAmount: 0,
    timeEachQuestion: 0,
    pointEachQuestion: 0,
    isRandomOptions: false,
    isQuestionShuffle: false,
    isAnswerInOrder: false,
    isAdd: true,
  },
];

function getInitDataSource(
  examQuestion: ExamQuestionType | undefined
): DataType[] {
  if (!!examQuestion && Array.isArray(examQuestion?.fieldSettings)) {
    const dataSource = examQuestion.fieldSettings.map(
      (item: FieldType, index: number) => {
        return {
          key: index,
          field: item.field,
          questionAmount: item.questionAmount,
          timeEachQuestion: item.timeEachQuestion,
          pointEachQuestion: item.pointEachQuestion,
          isRandomOptions: item.isRandomOptions,
          isQuestionShuffle: item.isQuestionShuffle,
          isAnswerInOrder: item.isAnswerInOrder,
          isAdd: false,
        };
      }
    );
    return [
      ...dataSource,
      {
        key: dataSource.length,
        field: "",
        questionAmount: 0,
        timeEachQuestion: 0,
        pointEachQuestion: 0,
        isRandomOptions: false,
        isQuestionShuffle: false,
        isAnswerInOrder: false,
        isAdd: true,
      },
    ];
  }
  return [...initDataSource];
}
function CUExam({ handleGetExamQuestion }: CUExam) {
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [newField, setNewField] = useState("");
  const [errNewField, setErrNewField] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.questionbanksReducer);
  const [fieldOptions, setFieldOptions] = useState<SelectOptionType[]>([]);
  const [currentFieldOptions, setCurrentFieldOptions] = useState<
    SelectOptionType[]
  >([]);
  const [formLeft] = useForm();
  const [formRight] = useForm();
  const [currentExamQuestion, setCurrentExamQuestion] =
    useState<ExamQuestionType>();
  const { examQuestion } = useSelector(
    (state: any) => state.examQuestionReducer
  );
  const { open } = useSelector((state: any) => state.modalReducer);
  const [dataSource, setDataSource] = useState<any>(
    getInitDataSource(currentExamQuestion)
  );
  useEffect(() => {
    if (open) {
      formLeft.resetFields();
      formRight.resetFields();
      setDataSource(initDataSource);
      setCurrentExamQuestion(undefined);
      return;
    }
  }, [open]);
  useEffect(() => {
    const results = _.get(examQuestion, "results");
    if (!!results && Array.isArray(results) && results.length > 0) {
      setCurrentExamQuestion(results[0]);
    }
  }, [examQuestion]);
  //Gọi cập nhật danh sách câu hỏi
  function handleGetQuestionBank() {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        section: sectionActive?.id,
      }),
      skipUpdateReducer: true,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Lỗi lấy danh sách câu hỏi!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getQuestionBanks(payload));
  }
  // Lấy options cho ô select lĩnh vực
  const getFieldsOptions = () => {
    if (!!sectionActive?.id) {
      const payload: PayloadType = {
        params: sectionActive?.id,
        callback: {
          success(fields) {
            if (!!fields && Array.isArray(fields)) {
              const options = fields.map((item: any) => {
                return {
                  label: `${_.get(item, "_id", "")} (${item?.count ?? ""})`,
                  value: item?._id ?? "",
                  count: item?.count ?? "",
                };
              });
              setFieldOptions(options);
              setCurrentFieldOptions(options);
            }
          },
          failed(errorMessage) {},
        },
      };
      dispatch(statisticalFieldBysection(payload));
    }
  };
  useEffect(() => {
    getFieldsOptions();
  }, [open, currentExamQuestion]);
  //api thêm mới lĩnh vực
  function handleAddNewField() {
    const payload: PayloadType = {
      params: sectionActive?.id ?? "",
      body: {
        field: newField,
      },
      callback: {
        success(fields) {},
        failed(errorMessage) {},
      },
    };
    dispatch(addFields(payload));
  }
  const addFieldSelect = (field: string) => {
    setFieldOptions([
      {
        label: field,
        value: field,
      },
      ...fieldOptions,
    ]);
    handleAddNewField();
  };
  // Khởi tạo giá trị cho form sửa/thêm mới
  useEffect(() => {
    if (!!examQuestion) {
      const dataSourceUpdate = getInitDataSource(currentExamQuestion);
      //Form trái
      formLeft.setFieldValue(
        "startTime",
        moment(_.get(currentExamQuestion, "startTime", moment()))
      );
      formLeft.setFieldValue(
        "endTime",
        moment(_.get(currentExamQuestion, "endTime", moment()))
      );
      formLeft.setFieldValue(
        "isFieldShuffle",
        _.get(currentExamQuestion, "options.isFieldShuffle")
      );
      formLeft.setFieldValue(
        "isKeepPosAcrossField",
        _.get(currentExamQuestion, "options.isKeepPosAcrossField")
      );
      formLeft.setFieldValue(
        "isQuestionShuffleAll",
        _.get(currentExamQuestion, "options.isQuestionShuffleAll")
      );
      formLeft.setFieldValue(
        "isQuestionShuffleAll",
        _.get(currentExamQuestion, "options.isQuestionShuffleAll")
      );
      formLeft.setFieldValue(
        "bkgImg",
        _.get(currentExamQuestion, "uiSettings.bkgImg")
      );
      formLeft.setFieldValue(
        "bkgColor",
        _.get(currentExamQuestion, "uiSettings.bkgColor")
      );
      formLeft.setFieldValue(
        "textColor",
        _.get(currentExamQuestion, "uiSettings.textColor")
      );
      formLeft.setFieldValue(
        "bkgMusic",
        _.get(currentExamQuestion, "uiSettings.bkgMusic")
      );

      //Form phải
      formRight.setFieldValue(
        "key",
        dataSourceUpdate.map((item: DataType) => item.key)
      );
      formRight.setFieldValue(
        "field",
        dataSourceUpdate.map((item: DataType) => item.field)
      );
      formRight.setFieldValue(
        "isRandomOptions",
        dataSourceUpdate.map((item: DataType) => item.isRandomOptions)
      );
      formRight.setFieldValue(
        "isAnswerInOrder",
        dataSourceUpdate.map((item: DataType) => item.isAnswerInOrder)
      );
      formRight.setFieldValue(
        "isQuestionShuffle",
        dataSourceUpdate.map((item: DataType) => item.isQuestionShuffle)
      );

      formRight.setFieldValue(
        "questionAmount",
        dataSourceUpdate.map((item: DataType) => item.questionAmount)
      );
      formRight.setFieldValue(
        "timeEachQuestion",
        dataSourceUpdate.map((item: DataType) => item.timeEachQuestion)
      );
      formRight.setFieldValue(
        "pointEachQuestion",
        dataSourceUpdate.map((item: DataType) => item.pointEachQuestion)
      );
      setDataSource(dataSourceUpdate);
      return;
    }
    formLeft.resetFields();
    formRight.resetFields();
    setDataSource(initDataSource);
  }, [currentExamQuestion]);
  //Bảng chọn đáp án
  const examSchema = yup.object().shape({
    field: yup.string().required("Lĩnh vực không được để trống!"),
    questionAmount: yup
      .number()
      .typeError("Số câu hỏi không được để trống!")
      .integer("Số câu hỏi phải là số nguyên!")
      .required("Số câu hỏi không được để trống!")
      .test("check-num", "Số câu hỏi phải lớn hơn 0!", (num) => {
        return num > 0;
      }),
    timeEachQuestion: yup
      .number()
      .typeError("Thời gian trả lời không được để trống!")
      .min(1, "Thời gian trả lời phải lớn hơn 1!")
      .integer("Thời gian trả lời phải là số nguyên!")
      .required("Thời gian trả lời không được để trống!"),
    pointEachQuestion: yup
      .number()
      .typeError("Điểm mỗi câu không được để trống!")
      .required("Điểm mỗi không được để trống!")
      .test("check-num", "Điểm mỗi câu phải lớn hơn 0!", (num) => {
        return num > 0;
      }),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      render(value, record, index) {
        return value + 1;
      },
    },
    {
      title: "Lĩnh vực",
      dataIndex: "field",
      key: "field",
      align: "center",
      width: 200,
      render(value, record: DataType, index) {
        return (
          <Form.Item
            initialValue={value}
            name={["field", record.key]}
            rules={getRuleOfTable(
              index,
              dataSource,
              getValidateField(examSchema, "field")
            )}
          >
            <Select
              placeholder="Chọn lĩnh vực"
              maxTagTextLength={100}
              options={fieldOptions}
              onDropdownVisibleChange={() => {
                // setErrNewField(null);
                // setNewField("");
              }}
              onChange={(value, option) => {
                setFieldOptions(
                  fieldOptions.filter(
                    (item: SelectOptionType) => item.value != value
                  )
                );
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    &nbsp;
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Input
                        style={{
                          borderColor:
                            errNewField != null && !newField
                              ? "red"
                              : undefined,
                        }}
                        placeholder="Thêm lĩnh vực mới"
                        onChange={(e) => {
                          if (!e.target.value) {
                            setErrNewField("Lĩnh vực không được để trống!");
                          }
                          setNewField(e.target.value);
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {!newField ? errNewField : ""}
                      </span>
                    </div>
                    &nbsp;
                    <Button
                      type="primary"
                      onClick={() => {
                        if (!!newField) addFieldSelect(newField);
                      }}
                      style={{
                        width: 40,
                      }}
                      icon={<PlusOutlined />}
                    />
                    &nbsp;
                  </div>
                </>
              )}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Số câu hỏi",
      dataIndex: "questionAmount",
      key: "questionAmount",
      align: "center",
      width: 140,
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["questionAmount", record.key]}
            rules={[
              ...getRuleOfTable(
                index,
                dataSource,
                getValidateField(examSchema, "questionAmount")
              ),
              () => ({
                validator(info, value) {
                  try {
                    const fieldName = formRight.getFieldValue([
                      "field",
                      record.key,
                    ]);
                    const fieldOption = currentFieldOptions.find(
                      (fieldOption: any) => fieldOption.value === fieldName
                    );
                    const totalQuestion = _.get(fieldOption, "count", -1);
                    if (totalQuestion === -1) {
                      return Promise.resolve();
                    }
                    if (parseInt(value) <= 0) {
                      return Promise.reject(
                        new Error(`Số câu hỏi phải lớn hơn 0!`)
                      );
                    }
                    if (parseInt(value) > totalQuestion) {
                      return Promise.reject(
                        new Error(`Số câu hỏi phải nhỏ hơn ${totalQuestion}!`)
                      );
                    }
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(new Error("Vui lòng nhập số!"));
                  }
                },
              }),
            ]}
          >
            <Input type="number" placeholder="Nhập số" />
          </Form.Item>
        );
      },
    },
    {
      title: "Thời gian trả lời mỗi câu (giây)",
      width: 140,
      dataIndex: "timeEachQuestion",
      key: "timeEachQuestion",
      align: "center",
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["timeEachQuestion", record.key]}
            rules={getRuleOfTable(
              index,
              dataSource,
              getValidateField(examSchema, "timeEachQuestion")
            )}
          >
            <Input type="number" placeholder="Nhập số" />
          </Form.Item>
        );
      },
    },
    {
      title: "Điểm mỗi câu",
      width: 140,
      dataIndex: "pointEachQuestion",
      key: "pointEachQuestion",
      align: "center",
      render(value, record, index) {
        return (
          <Form.Item
            name={["pointEachQuestion", record.key]}
            rules={getRuleOfTable(
              index,
              dataSource,
              getValidateField(examSchema, "pointEachQuestion")
            )}
          >
            <Input type="number" placeholder="Nhập số" />
          </Form.Item>
        );
      },
    },
    {
      title: "Đảo đáp án",
      dataIndex: "isRandomOptions",
      key: "isRandomOptions",
      align: "center",
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["isRandomOptions", record.key]}
            valuePropName="checked"
            initialValue={
              !!currentExamQuestion
                ? formRight.getFieldValue(["isRandomOptions", record.key])
                : false
            }
          >
            <Switch />
          </Form.Item>
        );
      },
    },
    {
      title: "Đảo câu hỏi",
      dataIndex: "isQuestionShuffle",
      key: "isQuestionShuffle",
      align: "center",
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["isQuestionShuffle", record.key]}
            valuePropName="checked"
            initialValue={
              !!currentExamQuestion
                ? formRight.getFieldValue(["isQuestionShuffle", record.key])
                : false
            }
          >
            <Switch />
          </Form.Item>
        );
      },
    },
    {
      title: "TL theo thứ tự",
      dataIndex: "isAnswerInOrder",
      key: "isAnswerInOrder",
      align: "center",
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["isAnswerInOrder", record.key]}
            valuePropName="checked"
            initialValue={
              !!currentExamQuestion
                ? formRight.getFieldValue(["isAnswerInOrder", record.key])
                : false
            }
          >
            <Switch />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "isAdd",
      key: "isAdd",
      align: "center",
      fixed: "right",
      render(value, record, index) {
        if (value) {
          return (
            <PlusSquareOutlined
              onClick={async () => {
                try {
                  const values = await formRight.validateFields();
                  if (!!values) handleAdd();
                } catch (error) {}
              }}
              style={{
                fontSize: 18,
                color: "#1890FF",
              }}
            />
          );
        }
        return (
          <Popconfirm
            title={"Bạn có chắc chắn muốn xóa đề thi này?"}
            onConfirm={() => {
              handleDelete(record.key);
            }}
            cancelText={"Huỷ"}
          >
            <CloseCircleOutlined
              style={{
                fontSize: 18,
                color: "#FF7875",
              }}
            />
          </Popconfirm>
        );
      },
    },
  ];
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const handleAdd = () => {
    const newItem: DataType = {
      key: 0,
      field: "",
      timeEachQuestion: 0,
      isQuestionShuffle: false,
      isRandomOptions: false,
      isAnswerInOrder: false,
      pointEachQuestion: 0,
      questionAmount: 0,
      isAdd: true,
    };
    const newData = [...dataSource, newItem];
    newData.map((item: DataType, index: number) => {
      item.key = index;
      if (index === newData.length - 1) {
        item.isAdd = true;
      } else item.isAdd = false;
    });
    setDataSource([...newData]);
  };
  const deleteFieldFormRight = (fieldName: string, iRemove: number) => {
    const arrField = formRight.getFieldValue(fieldName);
    if (!!arrField && Array.isArray(arrField)) {
      formRight.setFieldValue(
        fieldName,
        arrField.filter((item: string, index: number) => index !== iRemove)
      );
    }
  };
  const handleDelete = (key: number) => {
    let newData = dataSource.filter((item: DataType) => {
      return item.key !== key && item.key !== dataSource.length - 1;
    });
    newData.push({
      key: newData.length,
      field: "",
      questionAmount: 0,
      timeEachQuestion: 0,
      pointEachQuestion: 0,
      isRandomOptions: false,
      isAnswerInOrder: false,
      isQuestionShuffle: false,
      isAdd: true,
    });
    newData.map((item: DataType, index: number) => {
      item.key = index;
    });
    deleteFieldFormRight("field", key);
    deleteFieldFormRight("isRandomOptions", key);
    deleteFieldFormRight("isAnswerInOrder", key);
    deleteFieldFormRight("isQuestionShuffle", key);
    deleteFieldFormRight("questionAmount", key);
    deleteFieldFormRight("timeEachQuestion", key);
    deleteFieldFormRight("pointEachQuestion", key);
    setDataSource(newData);
  };
  //Xử lý submit cả 2 form
  const handleSubmitForm = async () => {
    try {
      formLeft.submit();
      formRight.submit();
      const valuesFormLeft = await formLeft.validateFields();
      const valuesFormRight = await formRight.validateFields();
      if (_.get(valuesFormRight, "field", []).length <= 1) {
        notification.error({
          message: "Thất bại!",
          description: "Thêm thiết lập câu hỏi trước khi xác nhận!",
        });
        return;
      }
      if (!!currentExamQuestion) {
        handleUpdateExamQuestion(valuesFormLeft, valuesFormRight);
        return;
      }
      handleCreateExamQuestion(valuesFormLeft, valuesFormRight);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateExamQuestion = (
    valuesFormLeft: any,
    valuesFormRight: any
  ) => {
    const fieldArr = _.get(valuesFormRight, "field", []);
    const body = {
      section: sectionActive?.id,
      startTime: moment(
        _.get(valuesFormLeft, "startTime", moment())
      ).toISOString(),
      endTime: moment(_.get(valuesFormLeft, "endTime", moment())).toISOString(),
      options: {
        isFieldShuffle: _.get(valuesFormLeft, "isFieldShuffle", false),
        isKeepPosAcrossField: _.get(
          valuesFormLeft,
          "isKeepPosAcrossField",
          false
        ),
        isQuestionShuffleAll: _.get(
          valuesFormLeft,
          "isQuestionShuffleAll",
          false
        ),
      },
      fieldSettings: fieldArr
        .slice(0, fieldArr.length - 1)
        .map((item: any, index: number) => {
          return {
            field: fieldArr[index],
            questionAmount: _.get(valuesFormRight, "questionAmount", [])[index],
            timeEachQuestion: _.get(valuesFormRight, "timeEachQuestion", [])[
              index
            ],
            pointEachQuestion: _.get(valuesFormRight, "pointEachQuestion", [])[
              index
            ],
            isRandomOptions: _.get(valuesFormRight, "isRandomOptions", [])[
              index
            ],
            isQuestionShuffle: _.get(valuesFormRight, "isQuestionShuffle", [])[
              index
            ],
            isAnswerInOrder: _.get(valuesFormRight, "isAnswerInOrder", [])[
              index
            ],
          };
        }),
      uiSettings: {
        bkgImg: _.get(valuesFormLeft, "bkgImg", ""),
        bkgColor: _.get(valuesFormLeft, "bkgColor", ""),
        textColor: _.get(valuesFormLeft, "textColor", ""),
        bkgMusic: _.get(valuesFormLeft, "bkgMusic", ""),
      },
    };
    const payload: PayloadType = {
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Thêm mới kỳ thi thành công!",
          });
          handleCloseForm();
          handleGetQuestionBank();
          handleGetExamQuestion();
        },
        failed(errorMessage) {
          notification.error({
            message: "Thêm mới kỳ thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };

    dispatch(createExamQuestion(payload));
  };
  const handleUpdateExamQuestion = (
    valuesFormLeft: any,
    valuesFormRight: any
  ) => {
    setIsLoading(true);
    const fieldArr = _.get(valuesFormRight, "field", []);
    const body = {
      section: currentExamQuestion?.section?.id,
      startTime: moment(
        _.get(valuesFormLeft, "startTime", moment())
      ).toISOString(),
      endTime: moment(_.get(valuesFormLeft, "endTime", moment())).toISOString(),
      options: {
        isFieldShuffle: _.get(valuesFormLeft, "isFieldShuffle", false),
        isKeepPosAcrossField: _.get(
          valuesFormLeft,
          "isKeepPosAcrossField",
          false
        ),

        isQuestionShuffleAll: _.get(
          valuesFormLeft,
          "isQuestionShuffleAll",
          false
        ),
      },
      fieldSettings: fieldArr
        .slice(0, fieldArr.length - 1)
        .map((item: any, index: number) => {
          return {
            field: fieldArr[index],
            questionAmount: _.get(valuesFormRight, "questionAmount", [])[index],
            timeEachQuestion: _.get(valuesFormRight, "timeEachQuestion", [])[
              index
            ],
            pointEachQuestion: _.get(valuesFormRight, "pointEachQuestion", [])[
              index
            ],
            isRandomOptions: _.get(valuesFormRight, "isRandomOptions", [])[
              index
            ],
            isQuestionShuffle: _.get(valuesFormRight, "isQuestionShuffle", [])[
              index
            ],
            isAnswerInOrder: _.get(valuesFormRight, "isAnswerInOrder", [])[
              index
            ],
          };
        }),
      uiSettings: {
        bkgImg: _.get(valuesFormLeft, "bkgImg", ""),
        bkgColor: _.get(valuesFormLeft, "bkgColor", ""),
        textColor: _.get(valuesFormLeft, "textColor", ""),
        bkgMusic: _.get(valuesFormLeft, "bkgMusic", ""),
      },
    };
    const payload: PayloadType = {
      params: currentExamQuestion?.id ?? "",
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật kỳ thi thành công!",
          });
          handleGetQuestionBank();
          handleGetExamQuestion();
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật kỳ thi thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };

    dispatch(updateExamQuestion(payload));
  };
  const handleCloseForm = () => {
    formLeft.resetFields();
    formRight.resetFields();
    setDataSource(initDataSource);
    closeModal(dispatch, setModal);
  };
  const disabledDateStart: RangePickerProps["disabledDate"] = (current) => {
    // Không thể chọn trước ngày hôm nay
    return current && current < moment().startOf("day");
  };
  const disabledDateEnd: RangePickerProps["disabledDate"] = (current) => {
    // Không thể chọn trước ngày hôm nay
    return current && current < moment().startOf("day");
  };
  const formLeftSchema = yup.object().shape({
    startTime: yup.string().required("Thời gian bắt đầu không được để trống!"),
    endTime: yup.string().required("Thời gian kết thúc không được để trống!"),
    bkgImg: yup
      .string()
      .required("Màu ảnh nền không được để trống!")
      .test(
        "check-color-value",
        "Màu không đúng định dạng!",
        (colorString: string) => {
          const reg = /^#[0-9A-F]{6}$/i;
          return reg.test(colorString);
        }
      ),
    bkgColor: yup.string().required("Màu ảnh nền không được để trống!"),
    textColor: yup.string().required("Màu ảnh nền không được để trống!"),
  });
  const yupSyncFormLeft = {
    async validator({ field }: any, value: any) {
      await formLeftSchema.validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <div>
      <Row className="ant-modal-body">
        <Col
          span={8}
          style={{
            paddingRight: 24,
          }}
        >
          <Form
            form={formLeft}
            labelAlign="left"
            labelCol={{
              xxl: 8,
              xl: 24,
              md: 24,
              sm: 24,
              xs: 24,
            }}
          >
            <Form.Item
              required
              name={"startTime"}
              label={"Thời gian bắt đầu"}
              rules={[yupSyncFormLeft]}
            >
              <DatePicker
                format={"HH:mm:ss DD/MM/YYYY"}
                disabledDate={disabledDateStart}
                showTime
                placeholder="Chọn Ngày - Giờ"
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              required
              name={"endTime"}
              label={"Thời gian kết thúc"}
              rules={[yupSyncFormLeft]}
            >
              <DatePicker
                format={"HH:mm:ss DD/MM/YYYY"}
                disabledDate={disabledDateEnd}
                showTime
                placeholder="Chọn Ngày - Giờ"
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"isFieldShuffle"}
              label={"Đảo lĩnh vực"}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={"isQuestionShuffleAll"}
              label={"Trộn câu hỏi"}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={"isKeepPosAcrossField"}
              label={
                <label>
                  Tương xứng câu hỏi &nbsp;
                  <Tooltip
                    placement="top"
                    title={
                      "Ví dụ: Câu hỏi giải khóa, giải mã cần tương xứng với nhau."
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </label>
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={"#7FFFD4"}
              name={"bkgImg"}
              label={"Màu ảnh nền"}
              rules={[yupSyncFormLeft]}
            >
              <Input placeholder="Nhập mã hex VD: #FFFFFF" />
            </Form.Item>
            <Form.Item
              initialValue={"#EEE"}
              name={"bkgColor"}
              label={"Màu nền"}
            >
              <Input placeholder="Nhập mã hex VD: #FFFFFF" />
            </Form.Item>
            <Form.Item
              initialValue={"#000"}
              name={"textColor"}
              label={"Màu chữ"}
            >
              <Input placeholder="Nhập mã hex VD: #FFFFFF" />
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Title level={5}>Thiết lập câu hỏi cho đề thi</Title>
          <Form form={formRight}>
            <Table
              loading={isLoading}
              className="custom-form-item"
              rowKey={"key"}
              size="middle"
              onRow={(record) => ({
                onBlur: () => handleSave(record),
              })}
              rowClassName={() => "editable-row"}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              scroll={{
                x: 1000,
                y: 700,
              }}
            />
          </Form>
        </Col>
      </Row>
      <div className="ant-modal-footer">
        <Button type="default" onClick={handleCloseForm}>
          Huỷ
        </Button>
        <Button type="primary" onClick={handleSubmitForm}>
          {!!currentExamQuestion ? "Cập nhật" : "Xác nhận"}
        </Button>
      </div>
    </div>
  );
}

export default CUExam;

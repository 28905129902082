import axios from "axios";
import queryString from "query-string";
import { BASE_URL } from "./ServiceURL";
import _ from "lodash";
import refreshTokens from "sagas/refreshTokenSaga";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { sort: false }),
  },
});

axiosClient.interceptors.request.use(
  async (config) => {
    const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
    if (
      !config.url.includes("api/upload") &&
      !config.url.includes("api/statistic")
    )
      config.headers.authorization = `Bearer ${tokens?.access?.token}`;
    else config.headers.authorization = `${tokens?.access?.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    if (response) return response;
  },
  async function (error) {
    const { config } = error;

    const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
    if (!!tokens && Object.keys(tokens).length > 0) {
      let token = tokens.access.token;
      let refresh = tokens.refresh.token;
      if (
        (
          error?.response?.status === 401) &&
        !!refresh &&
        !!token &&
        !config.url.includes("admin") &&
        ((config.retry || 0) < 4)
      ) {
        config.retry = config.retry ? config.retry + 1 : 1;
        const data = await refreshTokens();
        // setting updated token
        if (!!data) {
          localStorage.setItem("tokens", JSON.stringify(data));
        }
        return axiosClient(config);
      }
      if (config.retry === 4) {
        console.log(config.retry)
        localStorage.clear();
        window.location.replace("/auth/login");
      }
    }
    return Promise.reject(error);
  }
);

const axiosClientFile = axios.create({
  baseURL: BASE_URL,
  timeout: 900000,
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { sort: false }),
  },
});

axiosClientFile.interceptors.request.use(
  async (config) => {
    const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
    if (!!tokens)
      config.headers.authorization = `Bearer ${_.get(tokens, "access.token")}`;
    config.responseType = "blob";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClientFile.interceptors.response.use(
  function (response) {
    if (response) return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);
axiosClientFile.defaults.headers.common["Content-disposition"] =
  "attachment; filename=data.csv";
axiosClientFile.defaults.headers.common["Content-type"] =
  "text/xlsx; charset=utf-8";
const custom = async (url, body, method, host) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // responseType: 'blob',
  };
  return axiosClient({
    baseURL: host,
    url: url,
    data: method === "get" ? undefined : body,
    method: method,
    ...config,
  }).then(
    (response) => {
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { axiosClient, axiosClientFile, custom };

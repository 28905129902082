import { values } from "lodash";
export const DATE_FORMAT = "DD/MM/YYYY";
export const FULL_DATE_FORMAT = "HH:mm:ss DD/MM/YYYY";
export const GENDER = {
  "1": "Nam",
  "0": "Nữ",
  "-1": "Khác",
};
export const ALLOW_CONTINUE = [
  {
    label: "Tiếp tục, điểm từng đáp án",
    value: "continue",
  },
  {
    label: "Dừng lại",
    value: "stop",
  },
];
export const Y_N = [
  {
    label: "Có",
    value: true,
  },
  {
    label: "Không",
    value: false,
  },
];
export const ANSWER_ITEM_REG = /.+:.+/g;
export const IS_OPTION_FORM = [
  ...Y_N,
  {
    label: "Theo thiết lập đề thi",
    value: "",
  },
];
export const QS_TYPE = ["text", "video"];
export const AS_TYPE = [
  "single_choice",
  "multi_choice_text_drag",
  "multi_choice_img_drag",
];
export const QUESTION_TYPE = [
  {
    label: "Văn bản",
    value: QS_TYPE[0],
  },
  {
    label: "Video",
    value: QS_TYPE[1],
  },
];
export const ANSWER_TYPE = [
  {
    label: "Dạng single choice",
    value: AS_TYPE[0],
  },
  {
    label: "Dạng multichoice text, kéo thả",
    value: AS_TYPE[1],
  },
  {
    label: "Dạng multichoice hình ảnh, kéo thả",
    value: AS_TYPE[2],
  },
];

export const USER_SATTUS = [
  {
    label: "Hoạt động",
    value: "active",
  },
  {
    label: "Không hoạt động",
    value: "lock",
  },
];
export const FAILD_PROCESS = [
  {
    label: "Tiếp dục",
    value: "continue",
  },
  {
    label: "Dừng lại",
    value: "stop",
  },
];
export const PROVINCES = [
  { label: "An Giang", value: 0 },
  { label: "Bà Rịa Vũng Tàu", value: 1 },
  { label: "Bình Dương", value: 2 },
  { label: "Bình Phước", value: 3 },
  { label: "Bình Thuận", value: 4 },
  { label: "Bình Định ", value: 5 },
  { label: "Bạc Liêu", value: 6 },
  { label: "Bắc Giang", value: 7 },
  { label: "Bắc Kạn", value: 8 },
  { label: "Bắc Ninh", value: 9 },
  { label: "Bến Tre", value: 10 },
  { label: "Cao Bằng", value: 11 },
  { label: "Cà Mau", value: 12 },
  { label: "Cần Thơ", value: 13 },
  { label: "Gia Lai", value: 14 },
  { label: "Hà Giang", value: 15 },
  { label: "Hà Nam", value: 16 },
  { label: "Hà Nội", value: 17 },
  { label: "Hà Tĩnh", value: 18 },
  { label: "Hòa Bình", value: 19 },
  { label: "Hưng Yên", value: 20 },
  { label: "Hải Dương", value: 21 },
  { label: "Hải Phòng", value: 22 },
  { label: "Hậu Giang", value: 23 },
  { label: "Khánh Hòa", value: 24 },
  { label: "Kiên Giang", value: 25 },
  { label: "Kon Tum", value: 26 },
  { label: "Lai Châu", value: 27 },
  { label: "Long An", value: 28 },
  { label: "Lào Cai", value: 29 },
  { label: "Lâm Đồng", value: 30 },
  { label: "Lạng Sơn", value: 31 },
  { label: "Nam Định", value: 32 },
  { label: "Nghệ An", value: 33 },
  { label: "Ninh Bình", value: 34 },
  { label: "Ninh Thuận", value: 35 },
  { label: "Phú Thọ", value: 36 },
  { label: "Phú Yên", value: 37 },
  { label: "Quảng Bình", value: 38 },
  { label: "Quảng Nam", value: 39 },
  { label: "Quảng Ngãi", value: 40 },
  { label: "Quảng Ninh", value: 41 },
  { label: "Quảng Trị", value: 42 },
  { label: "Sóc Trăng", value: 43 },
  { label: "Sơn La", value: 44 },
  { label: "TP. Hồ Chí Minh", value: 45 },
  { label: "Thanh Hóa", value: 46 },
  { label: "Thái Bình", value: 47 },
  { label: "Thái Nguyên", value: 48 },
  { label: "Thừa Thiên Huế", value: 49 },
  { label: "Tiền Giang", value: 50 },
  { label: "Trà Vinh", value: 51 },
  { label: "Tuyên Quang", value: 52 },
  { label: "Tây Ninh", value: 53 },
  { label: "Vĩnh Long", value: 54 },
  { label: "Vĩnh Phúc", value: 55 },
  { label: "Yên Bái", value: 56 },
  { label: "Điện Biên", value: 57 },
  { label: "Đà Nẵng", value: 58 },
  { label: "Đắk Lắk", value: 59 },
  { label: "Đắk Nông", value: 60 },
  { label: "Đồng Nai", value: 61 },
  { label: "Đồng Tháp", value: 62 },
];

export const FORM_ANSWER_TYPE = [
  {
    label: "Dạng single choice",
    value: 0,
  },
  {
    label: "Dạng multichoice text, kéo thả",
    value: 1,
  },
  {
    label: "Dạng multichoice hình ảnh, kéo thả",
    value: 2,
  },
];

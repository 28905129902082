/**
 * Màn hình Thông tin tài khoản
 */
import { useEffect } from "react";
import { Layout, Menu, Spin, notification } from "antd";
import { memo, useState } from "react";
import type { MenuProps } from "antd";
import BasicSettingAccount from "pages/Account/AccountSetting/BasicSettingAccount";
import SecuritySettingAccount from "pages/Account/AccountSetting/SecuritySettingAccount";
import { PayloadType } from "type";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "features/userSlice";
import _ from "lodash";
const { Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Cài đặt cơ bản", "1"),
  getItem("Cài đặt bảo mật", "2"),
];

function AccountSetting() {
  const [contentDisplay, setContentDisplay] = useState(1);
  const [isLoading,setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const info = localStorage.getItem("accountInfo");
  const userInfo = !!info ? JSON.parse(info) : null;
  const [currentUser, setCurrentUser] = useState(null);
  function handleGetUserData() {
    setIsLoading(true)
    const payload: PayloadType = {
      params: _.get(userInfo, "id", ""),
      callback: {
        success(values) {
          setCurrentUser(values);
          setIsLoading(false)
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin tài khoản thất bại!",
            description: errorMessage,
          });
          setIsLoading(false)
        },
      },
    };
    dispatch(getUser(payload));
  }
  useEffect(() => {
    handleGetUserData();
  }, []);
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        backgroundColor: "white",
      }}
    >
      <Layout
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          height: "100%",
          backgroundColor: "transparent",
          paddingTop: 12,
        }}
      >
        <Menu
          mode="inline"
          style={{
            maxWidth: 256,
            height: "100%",
          }}
          items={items}
          defaultSelectedKeys={["1"]}
          onSelect={({ key }) => {
            try {
              setContentDisplay(parseInt(key));
            } catch (error) {
              console.error("Không thể chọn tab!");
            }
          }}
        />
        <Spin tip={"Đang tải..."} spinning={isLoading} >
          <Content
            style={{
              paddingLeft: 24,
            }}
          >
            {contentDisplay === 1 && !!currentUser ? (
              <BasicSettingAccount
                user={currentUser}
                handleGetUserData={handleGetUserData}
              />
            ) : (
              <SecuritySettingAccount />
            )}
          </Content>
        </Spin>
      </Layout>
    </Content>
  );
}

export default memo(AccountSetting);

import { createSlice } from "@reduxjs/toolkit";

export const ExamQuestionSlice: any = createSlice({
  name: "examQuestionSlice",
  initialState: {
    examQuestions: [],
    query: {
      page: 1,
      limit: 10,
    },
    examQuestion: {},
    filters: null,
    isUpdateForm: false,
    isLoading: false,
  },
  reducers: {
    getExamQuestions: (state, action) => {},
    getExamQuestion: (state, action) => {
      state.isLoading = true;
    },
    getExamQuestionsSuccess: (state, action) => {
      state.examQuestions = action.payload;
    },
    setExamQuestions: (state, action) => {
      state.examQuestions = action.payload;
    },
    getExamQuestionSuccess: (state, action) => {
      state.examQuestion = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setStateExamQuestion: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createExamQuestion: (state, action) => {},
    updateExamQuestion: (state, action) => {},
    deleteExamQuestion: (state, action) => {},
  },
});
export const {
  getExamQuestions,
  getExamQuestion,
  updateExamQuestion,
  getExamQuestionsSuccess,
  getExamQuestionSuccess,
  setQuery,
  setFilter,
  createExamQuestion,
  deleteExamQuestion,
  setStateExamQuestion,
  setExamQuestions,
} = ExamQuestionSlice.actions;
export default ExamQuestionSlice.reducer;

import { Button, Col, Form, Row, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import Item from "antd/lib/list/Item";
import AnswerForm from "components/AnswerForm";
import QuestionForm from "components/QuestionForm";
import { ANSWER_ITEM_REG, ANSWER_TYPE, AS_TYPE, QS_TYPE } from "const";
import { setModal } from "features/modalSlice";
import {
  createQuestionBank,
  updateQuestionBank,
} from "features/questionbanksSlice";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  AnswerQuestionBank,
  Base64Data,
  ModalInfoType,
  PayloadType,
} from "type";
import { _convertToBase64, closeModal, getBody } from "utils";

interface CUForm {
  handleGetQuestionBank: () => void;
}
interface CMCTextDrag {
  // correct answer multiple choice text drag
  stt: number;
  correctAnswer: string;
}
/**
 * Lấy body cho Payload để tạo mới câu hỏi
 * @param valuesAnswer Form câu hỏi
 * @param valuesQuestion Form đáp án
 * @param sectionActive Phần thi đang được chọn
 * @returns body
 */
function getBodyForpayload(
  valuesAnswer: any,
  valuesQuestion: any,
  sectionActive: any
) {
  // Tạo body
  let body = {
    section: _.get(sectionActive, "id", ""),
    //Câu hỏi
    questionType: _.get(valuesQuestion, "questionType", ""),
    field: _.get(valuesQuestion, "field", ""),
    content: _.get(valuesQuestion, "content", ""),
    othersContent: !!_.get(valuesQuestion, "videoLink", "")
      ? {
          video: {
            url: _.get(valuesQuestion, "videoLink", ""),
          },
        }
      : "",
    //Câu trả lời
    isHaveOption: _.get(valuesAnswer, "isHaveOption", ""),
    failProcess: _.get(valuesAnswer, "failProcess", ""),
    isRandomOptions: _.get(valuesAnswer, "isRandomOption", ""),
    isKeepOption: _.get(valuesAnswer, "isKeepOption", ""),
    answerType: _.get(valuesAnswer, "answerType", ""),
    options: [
      _.get(valuesAnswer, "content", [])
        .filter((item: string) => !!item)
        .map((item: string, index: number) => {
          return {
            code: index + 1,
            content: item,
          };
        }),
    ],
    correctAnswer: [""],
  };
  //single_choice
  if (AS_TYPE[0] === body.answerType) {
    const key: number = _.get(valuesAnswer, "key", 0);
    const keyActive = _.get(body, "options[0]", []).find(
      (item: AnswerQuestionBank, index: number) =>
        key.toString() === item.code.toString()
    );
    const correctAnswer = [`0:${_.get(keyActive, "code", 0)}`];
    body["correctAnswer"] = correctAnswer;
    // Lấy dữ liệu
  }

  //multi_choice_text_drag
  // if (AS_TYPE[1] === body.answerType) {
  //   const { answer, stt, content, code } = valuesAnswer;
  //   const correctAnswer: CMCTextDrag[] = [];
  //   if (_.isArray(answer) && _.isArray(stt) && _.isArray(content)) {
  //     stt
  //       .filter((s: string | undefined, index: number) => {
  //         return !!s && !!answer[index];
  //       })
  //       .map((item: number, index: number, arr: any[]) => {
  //         correctAnswer.push({
  //           stt: item,
  //           correctAnswer: _.get(code, `[${index}]`, -1),
  //         });
  //       });
  //     correctAnswer.sort((a: CMCTextDrag, b: CMCTextDrag) => a.stt - b.stt);
  //     body.correctAnswer = correctAnswer
  //       .filter((item: any) => !!_.get(item, "correctAnswer", 0))
  //       .map((item: CMCTextDrag) => `${item.stt}:${item.correctAnswer}`);
  //   }
  // }
  if (AS_TYPE[1] === body.answerType) {
    const { answer, stt, content, code } = valuesAnswer;
    if (_.isArray(answer) && _.isArray(stt) && _.isArray(content)) {
      //Lấy ra đáp án đúng, số thứ tự => sắp xếp theo số thứ tự
      const correctAnswer: CMCTextDrag[] = [];
      stt
        .filter((s: number[] | undefined, index: number) => {
          return !!s && !!answer[index];
        })
        .map((stt: number[] | number, index: number) => {
          if (_.isArray(stt)) {
            stt.map((item) => {
              correctAnswer.push({
                stt: item,
                correctAnswer: _.get(code, `[${index}]`, -1),
              });
            });
            return;
          }
          correctAnswer.push({
            stt,
            correctAnswer: _.get(code, `[${index}]`, -1),
          });
        });
      correctAnswer.sort((a: CMCTextDrag, b: CMCTextDrag) => a.stt - b.stt);
      body.correctAnswer = correctAnswer
        .filter((item: any) => !!_.get(item, "correctAnswer", 0))
        .map((item: CMCTextDrag) => `${item.stt}:${item.correctAnswer}`);
      //Lấy ra ảnh base64 theo key
      // return;
      // const arrBase64: Base64Data[] = _.get(
      //   valuesAnswer,
      //   "imgBase64",
      //   []
      // ).filter((item: Base64Data | undefined) => !!item);
      // if (!!arrBase64) {
      //   body.options[0] = body.options[0].map(
      //     (item: AnswerQuestionBank, index: number) => {
      //       return {
      //         code: item.code,
      //         image: arrBase64[index].base64,
      //       };
      //     }
      //   );
      // }
    }
  }
  //multi_choice_img_drag
  if (AS_TYPE[2] === body.answerType) {
    const { answer, stt, content, code } = valuesAnswer;
    if (_.isArray(answer) && _.isArray(stt) && _.isArray(content)) {
      //Lấy ra đáp án đúng, số thứ tự => sắp xếp theo số thứ tự
      const correctAnswer: CMCTextDrag[] = [];
      stt
        .filter((s: number[] | undefined, index: number) => {
          return !!s && !!answer[index];
        })
        .map((stt: number[] | number, index: number) => {
          if (_.isArray(stt)) {
            stt.map((item) => {
              correctAnswer.push({
                stt: item,
                correctAnswer: _.get(code, `[${index}]`, -1),
              });
            });
            return;
          }
          correctAnswer.push({
            stt,
            correctAnswer: _.get(code, `[${index}]`, -1),
          });
        });
      correctAnswer.sort((a: CMCTextDrag, b: CMCTextDrag) => a.stt - b.stt);
      body.correctAnswer = correctAnswer
        .filter((item: any) => !!_.get(item, "correctAnswer", 0))
        .map((item: CMCTextDrag) => `${item.stt}:${item.correctAnswer}`);
      //Lấy ra ảnh base64 theo key
      // return;
      const arrBase64: Base64Data[] = _.get(
        valuesAnswer,
        "imgBase64",
        []
      ).filter((item: Base64Data | undefined) => !!item);
      if (!!arrBase64) {
        body.options[0] = body.options[0].map(
          (item: AnswerQuestionBank, index: number) => {
            return {
              code: item.code,
              image: arrBase64[index].base64,
            };
          }
        );
      }
    }
  }
  console.log(body);
  return body;
}

const validateFieldValues = (
  body: any,
  valuesQuestion: any,
  valuesAnswer: any
) => {
  let isValid = true;
  const answerType = _.get(valuesAnswer, "answerType", "");
  const correctAnswer = _.get(body, "correctAnswer", []);
  if (!_.isArray(correctAnswer) || correctAnswer.length === 0) {
    notification.error({
      message: "Vui lòng chọn đáp án đúng!",
    });
    isValid = false;
  }
  //validate single choice
  if (AS_TYPE[0] === answerType) {
    const key: number = _.get(valuesAnswer, "key", 0);
    const options = _.get(body, "options[0]", []);
    //Kiểm tra chọn đáp án đúng hay chưa?
    if (key === 0) {
      notification.error({
        message: "Vui lòng chọn đáp án đúng cho câu hỏi!",
      });
      isValid = false;
    }
    //Kiểm tra xem có chọn vào dòng thêm mới không?
    if (!_.isArray(options) || key > options.length) {
      notification.error({
        message: "Không được phép chọn đáp án đúng tại dòng thêm mới!",
      });
      isValid = false;
    }
  }
  //validate multiple choice text + video
  if ([AS_TYPE[1], AS_TYPE[2]].includes(answerType)) {
    const correctAnswer = _.get(body, "correctAnswer", []);
    correctAnswer.map((item: string) => {
      if (item.includes(":")) {
        const content = item.split(":")[1];
        try {
          if (parseInt(content) > _.get(body, "options[0].length", 0)) {
            notification.error({
              message: "Không được phép chọn đáp án đúng tại dòng thêm mới!",
            });
            isValid = false;
          }
        } catch (error) {
          console.log("Lỗi convert");
        }
      }
    });
    const stt: any[] = [];
    let maxSTT = -1;
    const arr = [];
    correctAnswer.map((item: string) => {
      if (item.includes(":")) {
        const sttIndex = parseInt(item.split(":")[0]);
        stt.push(sttIndex);
        if (sttIndex > maxSTT) maxSTT = sttIndex;
      }
    });
    for (let index = 1; index <= maxSTT; index++) {
      if (!stt.includes(index)) arr.push(index);
    }
    if (arr.length != 0) {
      notification.error({
        message: "Biên tập thiếu số thứ tự!",
        description:
          "Không tìm thấy số thứ tự " + arr.join(",") + " của đáp án!",
      });
      isValid = false;
    }
  }
  // return false;
  return isValid;
};
function CUForm({ handleGetQuestionBank }: CUForm) {
  const { dataUpdate } = useSelector((state: any) => state.modalReducer);
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  const dispatch = useDispatch();
  const [formQuestion] = useForm();
  const [formAnswer] = useForm();
  const [formOther] = useForm();
  function handleCloseForm() {
    const payload: ModalInfoType = {
      content: "",
      title: "",
      open: false,
      dataUpdate: null,
      idUpdate: "",
      isUpdate: false,
      size: 1200,
    };
    dispatch(setModal(payload));
  }
  /**
   * Thực hiện gọi api tạo/cập nhật câu hỏi cho phần thi
   * @param valuesQF values question form
   * @param valuesAF values answer form
   */
  async function handleSubmit() {
    const valuesAnswer = formAnswer.getFieldsValue([
      "code",
      "content",
      "correctAnswer",
      "answerType",
      "answer",
      "stt",
      "imgBase64",
      "isHaveOption",
      "failProcess",
      "isRandomOption",
      "isKeepOption",
      "key",
    ]);
    const valuesQuestion = formQuestion.getFieldsValue();
    const param = !!dataUpdate ? { params: _.get(dataUpdate, "id", "") } : {};
    const body = getBody(
      getBodyForpayload(valuesAnswer, valuesQuestion, sectionActive),
      []
    );
    const payload: PayloadType = {
      ...param,
      body,
      callback: {
        success(values) {
          notification.success({
            message: `${
              !!dataUpdate ? "Cập nhật" : "Tạo mới"
            } câu hỏi thành công!`,
          });
          formAnswer.resetFields();
          formQuestion.resetFields();
          closeModal(dispatch, setModal);
          handleGetQuestionBank();
        },
        failed(errorMessage) {
          notification.error({
            message: `${
              !!dataUpdate ? "Cập nhật" : "Tạo mới"
            } câu hỏi không thành công!`,
            description: errorMessage,
          });
        },
      },
    };
    // return;
    if (validateFieldValues(body, valuesQuestion, valuesAnswer)) {
      if (!!dataUpdate) dispatch(updateQuestionBank(payload));
      else dispatch(createQuestionBank(payload));
    }
  }

  async function validateForm() {
    try {
      formQuestion.submit();
      formAnswer.submit();
      formOther.submit();
      await formQuestion.validateFields();
      await formAnswer.validateFields();
      await formOther.validateFields();
      await handleSubmit();
    } catch (error) {
      notification.info({
        message: "Dữ liệu đã nhập không hợp lệ!",
        description: "Vui lòng kiểm tra lại",
      });
      console.log(error);
    }
  }
  return (
    <Form>
      <div className="ant-modal-body">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <QuestionForm form={formQuestion} />
          </Col>
          <Col span={12}>
            <AnswerForm form={formAnswer} />
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button type="default" htmlType="submit" onClick={handleCloseForm}>
          Hủy
        </Button>
        <Button type="primary" htmlType="submit" onClick={validateForm}>
          {!!dataUpdate ? "Cập nhật" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUForm;

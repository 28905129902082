import { createSlice } from "@reduxjs/toolkit";
import { FunctionComponent } from "react";
import { ModalInfoType } from "type";

interface PayloadType {
  type: string;
  payload: ModalInfoType;
}
const initValues: ModalInfoType = {
  open: false,
  title: "",
  content: "",
  size: 350,
  dataUpdate: null
};
export const modalSlice: any = createSlice({
  name: "modalSlice",
  initialState: initValues,
  reducers: {
    setModal: (state, action: PayloadType) => {
      state.open = action.payload.open;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.size = action.payload.size;
      state.dataUpdate = action.payload.dataUpdate;
    },
  },
});
export const { setModal } = modalSlice.actions;
export default modalSlice.reducer;

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import {
  Button,
  Form,
  Popconfirm,
  Select,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import { useForm } from "antd/lib/form/Form";
import {  useEffect, useState } from "react";
import { PayloadType, SectionType, SelectOptionType } from "type";
import {
  uploadQuestionFile,
  uploadQuestionFromSection,
} from "features/questionbanksSlice";
import { closeModal } from "utils";
const { Text } = Typography;
interface UploadQuestionFromSection {
  handleGetQuestionBank: () => void;
}
function UploadQuestionFromSection({
  handleGetQuestionBank,
}: UploadQuestionFromSection) {
  //Modal
  const [formValue] = useForm();
  const dispatch = useDispatch();
  const { sectionActive, sectionsMenu } = useSelector(
    (state: any) => state.sectionReducer
  );
  const modal = useSelector((state: any) => state.modalReducer);
  const [sectionMenuOption, setSectionMenuOption] = useState<SectionType[]>([]);
  function handleCloseForm() {
    closeModal(dispatch, setModal);
  }
  function confirmUpload(values: any) {
    const payload: PayloadType = {
      params: {
        fromSection: _.get(values, "list", ""),
        toSection: _.get(sectionActive, "id", ""),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Import thành công!",
          });
          handleGetQuestionBank();
          handleCloseForm();
        },
        failed(errorMessage) {
          notification.error({
            message: "Import File thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(uploadQuestionFromSection(payload));
  }
  useEffect(() => {
    if (!!modal?.open) {
      setSectionMenuOption(
        _.get(sectionsMenu, "results", [])
          .map((item: SectionType) => ({
            label: item.name,
            value: item.id,
          }))
          .filter((item: SelectOptionType) => item.value !== sectionActive?.id)
      );
      return;
    }
    formValue.resetFields();
  }, [modal]);
  const [ortherSection, setOrtherSection] = useState<any>();
  const [open, setOpen] = useState<any>();
  function getNameSection() {
    console.log(formValue.getFieldValue("list"));
    const sectionSelect = _.find(
      sectionMenuOption,
      function (o: SelectOptionType) {
        return o.value === formValue.getFieldValue("list");
      }
    );
    setOrtherSection(sectionSelect);
    return _.get(sectionSelect, "value"), "";
  }
  return (
    <Form
      labelCol={{ span: 24 }}
      form={formValue}
      onFinish={(values) => {
        // confirmUpload(values);
        setOpen(true);
      }}
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Chọn phần thi khác"}
          name={"list"}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn phần thi để import",
            },
          ]}
        >
          <Select
            options={sectionMenuOption}
            placeholder={"Chọn phần thi khác"}
          />
        </Form.Item>
      </div>
      <div
        className="ant-modal-footer"
        style={{
          width: "100%",
        }}
      >
        <Button
          type="default"
          onClick={() => {
            dispatch(
              setModal({
                ...modal,
                open: false,
              })
            );
          }}
        >
          Huỷ
        </Button>
        <Popconfirm
          open={open}
          title={
            <Text>
              Bạn có chắc chắn muốn import câu hỏi từ phần thi{" "}
              <b>"{_.get(ortherSection, "label", "")}"</b> sang phần thi{" "}
              <b>"{_.get(sectionActive, "name", "")}"</b>
            </Text>
          }
          onOpenChange={(open) => {
            if (open) {
              getNameSection();
            }
          }}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          onConfirm={() => {
            setOpen(false);
            confirmUpload(formValue.getFieldsValue())
          }}
          onCancel={() => {
            setOpen(false);
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            onClick={async () => {
              try {
                await formValue.validateFields();
                setOpen(true);
              } catch (error) {}
            }}
          >
            Xác nhận
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
}

export default UploadQuestionFromSection;

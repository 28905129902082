/**
 * /auth/login
 * Màn hình đăng nhập
 */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import banner_footer from "assets/img/login-pages/footer.png";
import logo from "assets/img/bfd.png";
import { AccountType, GetAccountSuccess, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { accountLogin } from "features/accountSlice";
import { useHistory } from "react-router";
import * as yup from "yup";
import moment from "moment";
import _ from "lodash";
const BackGroundLoginPage = require("assets/img/login1.png");
const { Title, Text, Link } = Typography;
interface FormAccountType {
  email: string;
  password: string;
  remember: boolean;
}
const Login = () => {
  const [isLoadingLogin, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  async function checkLogin() {
    try {
      const accountInfo = await localStorage.getItem("accountInfo");
      const tokens = await localStorage.getItem("tokens");
      if (!!accountInfo && !!tokens) {
        const expires = JSON.parse(tokens).access.expires;
        if (moment(new Date().toISOString()).isBefore(expires)) {
          history.push("/admin/exam-setting");
          return;
        }
        localStorage.clear();
      }
    } catch (error) {}
    setIsLogin(false);
  }
  useEffect(() => {
    checkLogin();
  }, []);
  let loginSchema = yup.object().shape({
    email: yup
      .string()
      .required("Tài khoản không được để trống!")
      .email("Vui lòng nhập đúng định dạng email!"),
    password: yup.string().required("Mật khẩu không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleLogin(values: FormAccountType) {
    setIsLoading(true);
    const payload: PayloadType = {
      body: {
        email: _.get(values, "email", ""),
        password: _.get(values, "password", ""),
      },
      callback: {
        success(values: GetAccountSuccess) {
          setIsLoading(false);
          localStorage.setItem(
            "accountInfo",
            JSON.stringify(_.get(values, "user", {}))
          );
          localStorage.setItem(
            "tokens",
            JSON.stringify(_.get(values, "tokens", {}))
          );
          history.push("/admin/exam-setting");
        },
        failed(err) {
          notification.error({
            message: "Thông tin tài khoản hoặc mật khẩu không chính xác!",
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(accountLogin(payload));
  }
  if (!isLogin)
    return (
      <div
        className="bgLogin"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${BackGroundLoginPage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              // backgroundImage: `url(${banner_footer})`,
              backgroundPosition: "left bottom",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              // width: "100vw",
              // height: "30vh",
              minHeight: 300,
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col span={24}>
              <Space direction="vertical" align="center" size={"small"}>
                {/* <img
                  src={logo}
                  alt="logo"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: 200,
                  }}
                /> */}
                {/* <Title
                  italic
                  level={3}
                  style={{
                    fontSize: 28,
                    fontWeight: 200,
                    textTransform: "uppercase",
                  }}
                >
                  Phầm mềm quản lý thi trắc nghiệm
                </Title> */}
                <Card
                  size="default"
                  style={{
                    width: 500,
                    paddingLeft: 12,
                    paddingRight: 12,
                    // paddingTop: 0,
                    borderRadius: 24,
                  }}
                >
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true, size: "large" }}
                    size={"large"}
                    onFinish={(values) => {
                      handleLogin(values);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 16,
                      }}
                    >
                      <Space align="center">
                        <Text type="secondary" style={{ marginTop: 0 }}>
                          Vui lòng đăng nhập để sử dụng các chức năng quản lý
                        </Text>
                      </Space>
                    </div>
                    <Form.Item name="email" rules={[yupSync]}>
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Nhập email"
                      />
                    </Form.Item>
                    <Form.Item name="password" rules={[yupSync]}>
                      <Input.Password
                        // prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Nhập mật khẩu"
                        visibilityToggle={{
                          visible: false,
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Space
                        direction="horizontal"
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>Ghi nhớ đăng nhập</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                          Quên mật khẩu
                        </a>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{
                          width: "100%",
                        }}
                        loading={isLoadingLogin}
                      >
                        Đăng nhập
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
                {/* <Card style={{
                width: 430
              }} >
                <Space direction="vertical" size={"middle"} >
                  <Text type="secondary" style={{ textAlign: "center" }}>
                    Vui lòng đăng nhập để sử dụng hệ thống
                  </Text>
                  <Input
                    placeholder="Nhập tài khoản"
                    allowClear
                    onChange={() => {}}
                  />
                  <Input.Password placeholder="Nhập mật khẩu" />
                  <div>
                    <Checkbox onChange={() => {}}>Lưu mật khẩu</Checkbox>
                    <Link href="#">Quên mật khẩu khẩu ?</Link>
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: 'space-between'
                  }}>
                    <Button type="default" size="large" style={{width: 150}}  >Hủy bỏ</Button>
                    <Button type="primary" size="large" style={{width: 150}}>Đăng nhập</Button>
                  </div>
                </Space>
              </Card> */}
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    );
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin tip="Đang tải" size="large">
          <div className="content" />
        </Spin>
      </Space>
    </div>
  );
};

export default Login;

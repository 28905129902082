/**
 * Màn hình Thông tin tài khoản > Thông tin tài khoản
 */
import { Avatar, Col, Image, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import CustomLabelValue from "pages/components/CustomLabelValue";
const { Title } = Typography;
const UserAvatarDefault = require("assets/img/user-avatar-default.jpg");
function AccountInfo() {
  const info = localStorage.getItem("accountInfo");
  const userInfo = !!info ? JSON.parse(info) : null;
  const accountInfo = JSON.parse(localStorage.getItem("accountInfo") || "{}");
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        height: "50vh",
      }}
    >
      <Row>
        <Col span={24}>
          <Title level={4}>Thông tin tài khoản</Title>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: 26,
          paddingLeft: 26,
          width: "100%",
        }}
      >
        <Col span={12}>
          <Space direction="vertical" size={"large"}>
            <CustomLabelValue
              label={"Tên tài khoản"}
              value={_.get(userInfo, "name", "")}
              alignX={240}
              isLabelStart={true}
            />
            <CustomLabelValue
              label={"Giới tính"}
              value={
                _.get(userInfo, "gender", "-1") === "0"
                  ? "Nữ"
                  : _.get(userInfo, "gender", "-1") === "1"
                  ? "Nam"
                  : "Khác"
              }
              alignX={240}
            />
            <CustomLabelValue
              label={"Email"}
              value={_.get(userInfo, "email", "")}
              alignX={240}
            />
            <CustomLabelValue
              label={"Số điện thoại"}
              value={_.get(userInfo, "phone", "")}
              alignX={240}
            />
            <CustomLabelValue
              label={"Địa chỉ"}
              value={_.get(userInfo, "address", "")}
              alignX={240}
              isLabelEnd={true}
            />
          </Space>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            shape="circle"
            src={
              <Image
                src={_.get(accountInfo, "avatar", false) || UserAvatarDefault}
              />
            }
            size={220}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default AccountInfo;

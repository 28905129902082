import { ReactNode } from "react";
import React, { useState, useEffect, memo } from "react";
import routes from "routes";
import { Button, Layout, Menu, MenuProps } from "antd";
import { useHistory, useLocation } from "react-router";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { PayloadType, SectionType } from "type";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { getSections } from "features/sectionSlice";
import _ from "lodash";
import { setRoutes } from "features/routeSlice";
import PageCommon from "pages/PageCommon";
import { styled } from "styled-components";
const { Sider } = Layout;
type MenuItem = Required<any>["items"][string];

function getItem(
  label: React.ReactNode,
  key?: string | null,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  hidden?: boolean
): MenuItem {
  if (!key?.includes("/")) {
    return {
      label,
      key,
      icon,
      children,
    } as MenuItem;
  }
  return {
    label: <Link to={key ?? ""}>{label}</Link>,
    key,
    icon,
    children,
  } as MenuItem;
}
let rootSubmenuKeys: any[] = [];
function getRoutes(routeGroup: any[]) {
  return routeGroup.map((route: any) => {
    let r = [];
    if (route?.hidden) {
      return null;
    }
    if (route?.views) {
      rootSubmenuKeys.push(route?.state ?? "");
      r = route.views.map((children: any) => {
        return getItem(
          children?.name ?? "",
          children?.layout + children?.path,
          null,
          null
        );
      });
      return getItem(
        route?.name ?? "",
        route?.state ?? "",
        route?.icon ?? "",
        r
      );
    }
    return getItem(
      route?.name ?? "",
      route?.layout + route?.path,
      route?.icon ?? "",
      null
    );
  });
}

function getCurrentSelectedKeys(routesArr: any[], pathname: any) {
  let routeTemp = null;
  const routeActive = routesArr.find((item) => {
    if (item.collapse && item.state && item.views) {
      routeTemp = [item.state];
      const result = getCurrentSelectedKeys(item.views, pathname);
      if (result.length > 0) {
        routeTemp.unshift(...result);
        return true;
      }
      routeTemp = null;
      return false;
    }
    return (item?.layout ?? "") + item.path === pathname;
  });
  if (!!routeTemp) {
    return routeTemp;
  }
  if (!!routeActive?.parent) {
    return [routeActive.parent];
  }
  if (!!routeActive) return [(routeActive?.layout ?? "") + routeActive?.path];
  return [];
}
const SideBar = () => {
  const history = useHistory();
  const routeActive = getCurrentSelectedKeys(
    routes.routesSM,
    history.location.pathname
  );
  const [routesConfig, setRoutesConfig] = useState<any>([]);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [openKeys, setOpenKeys] = useState<string[]>(routeActive);
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  console.log(openKeys);
  //Lấy danh sách phần thi
  const { sectionsMenu } = useSelector((state: any) => state.sectionReducer);
  useEffect(() => {
    const results = _.get(sectionsMenu, "results", []);
    if (!!results && Array.isArray(results)) {
      const temp = results
        .filter((item: SectionType) => {
          return item.isShowSide;
        })
        .map((item: SectionType) => {
          return {
            ...item,
            path: `/${_.get(item, "id", "")}`,
            name: _.get(item, "name", ""),
            layout: "/admin",
            roles: [],
          };
        });
      routes.routesSM[0].views = [...routes.routesTemp, ...temp];
      setRoutesConfig(getRoutes(routes.routesSM));
      setOpenKeys(
        getCurrentSelectedKeys(routes.routesSM, history.location.pathname)
      );
    }
  }, [sectionsMenu]);
  return (
    <>
      <Sider
        width={256}
        style={{
          backgroundColor: "white",
          position: "relative",
        }}
        collapsed={collapsed}
      >
        <Menu
          mode="inline"
          className="custom-submenu-scroll"
          style={{ flex: 1, borderRight: 0 }}
          triggerSubMenuAction="hover"
          inlineCollapsed={collapsed}
          multiple={false}
          items={routesConfig}
          selectedKeys={openKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={({ key, keyPath }: any) => {
            setOpenKeys(keyPath);
          }}
        />
        <Button
          type="text"
          onClick={toggleCollapsed}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            borderTop: "1px solid #eee",
            textAlign: "left",
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </Sider>
    </>
  );
};

export default memo(SideBar);

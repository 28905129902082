import React, { FunctionComponent } from "react";
import Login from "pages/Login";
//icon
import ResetPassword from "pages/ResetPassword";
import AccountSetting from "pages/Account/AccountSetting";
import AccountInfo from "pages/Account/AccountInfo";
import {
  UserOutlined,
  ApartmentOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import User from "pages/User";
import ExamSetting from "pages/ExamSetting";
import ExamGroupSetting from "pages/ExamGroupSetting";
import SystemSetting from "pages/SystemSetting";
import AdminUser from "pages/AdminUser";

interface RouteItemType {
  hidden?: boolean; //VD: Ẩn các route cho màn thêm, sửa
  collapse?: boolean;
  state?: string; // các route sẽ nhớ và active lại khi f5: [state,path+name]
  name?: string;
  path?: string;
  icon?: any;
  component?: FunctionComponent;
  layout?: string;
  roles?: [];
  views?: any[]; // yêu cầu collapse = true
  questionType?: string;
  answerType?: string;
}

// Route Auth
const routesA = [
  {
    collapse: true,
    name: "Auth",
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Đăng nhập",
        miniName: "L",
        component: Login,
        layout: "/auth",
        roles: [],
      },
      {
        path: "/reset-password",
        name: "Quên mật khẩu",
        miniName: "R",
        component: ResetPassword,
        layout: "/auth",
        roles: [],
      },
    ],
  },
];

// Route Quản trị hệ thống
const routesTemp = [
  {
    path: "/exam-setting",
    name: "Thiết lập phần thi",
    component: ExamSetting,
    layout: "/admin",
    roles: [],
  },
  {
    path: "/group-exam-setting",
    name: "Thiết lập nhóm phần thi",
    component: ExamGroupSetting,
    layout: "/admin",
    roles: [],
  },
  // {
  //   path: "/cu-semaphore",
  //   name: "Form Semaphore",
  //   component: CUSemaphore,
  //   layout: "/admin",
  //   roles: [],
  // },
];
const routesSM: RouteItemType[] = [
  {
    collapse: true,
    name: "Quản lý phần thi",
    icon: React.createElement(ApartmentOutlined),
    state: "TCollapse",
    views: [
      // {
      //   path: "/test",
      //   name: "Trắc nghiệm",
      //   component: Test,
      //   layout: "/admin",
      //   roles: [],
      // },
      // {
      //   path: "/semaphore",
      //   name: "Semaphore",
      //   component: Semaphore,
      //   layout: "/admin",
      //   roles: [],
      // },
      // {
      //   path: "/classified-letter",
      //   name: "Mật thư",
      //   component: StatisticTest,
      //   layout: "/admin",
      //   roles: [],
      // },
      // {
      //   path: "/road-marking",
      //   name: "Dấu đường",
      //   component: StatisticTest,
      //   layout: "/admin",
      //   roles: [],
      // },
      // {
      //   path: "/exam-setting",
      //   name: "Thiết lập phần thi",
      //   component: ExamSetting,
      //   layout: "/admin",
      //   roles: [],
      // },
      // {
      //   path: "/group-exam-setting",
      //   name: "Thiết lập nhóm phần thi",
      //   component: ExamGroupSetting,
      //   layout: "/admin",
      //   roles: [],
      // },
    ],
  },
  {
    collapse: true,
    name: "Quản lý hệ thống",
    icon: React.createElement(SettingOutlined),
    state: "SMCollapse",
    views: [
      {
        path: "/account-list",
        name: "Tài khoản người dùng",
        component: User,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/account-list-admin",
        name: "Tài khoản quản trị",
        component: AdminUser,
        layout: "/admin",
        roles: [],
      },
      // {
      //   path: "/statistic-test",
      //   name: "Hệ thống",
      //   component: SystemSetting,
      //   layout: "/admin",
      //   roles: [],
      // },
    ],
  },
  {
    collapse: true,
    name: "Thông tin cá nhân",
    icon: React.createElement(UserOutlined),
    state: "adminInfoCollapse",
    views: [
      {
        path: "/account-info",
        name: "Thông tin tài khoản",
        component: AccountInfo,
        layout: "/admin",
        roles: [],
      },
      {
        path: "/account-setting",
        name: "Thiết lập tài khoản",
        miniName: "R",
        component: AccountSetting,
        layout: "/admin",
        roles: [],
      },
    ],
  },
];

export default {
  routesA,
  routesTemp,
  routesSM,
};

import {
  Button,
  DatePicker,
  Popconfirm,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { ReactComponent as DemoSideBarIcon } from "assets/svgs/DemoSideBarIcon.svg";
import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalInfoType, PayloadType, SectionType } from "type";
import { PlusOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import CUExamSetting from "pages/ExamSetting/CUExamSetting";
import CUExamGroupSetting from "pages/ExamGroupSetting/CUExamGroupSetting";
import _ from "lodash";
import { getSection } from "features/sectionSlice";
import {
  deleteExamGroup,
  getExamGroup,
  getExamGroups,
  setQuery,
} from "features/examGroupSlice";
import queryString from "query-string";
const { Title } = Typography;
interface SubExam {
  title: string;
  link: string;
}
interface DataType {
  codeGroupTest: string;
  nameGroupTest: string;
  icon: any;
  subExam: SubExam[];
  description: string;
  createdAt: Date;
}
const data: DataType[] = [
  {
    codeGroupTest: "NPT001",
    nameGroupTest: "Mật thư",
    icon: React.createElement(DemoSideBarIcon),
    subExam: [
      {
        title: "Trắc nghiệm",
        link: "",
      },
      {
        title: "Semaphore",
        link: "",
      },
    ],
    description: "Phần thi mật thư",
    createdAt: moment().toDate(),
  },
];
function ExamGroupSetting() {
  const dispatch = useDispatch();
  const { query, examGroups } = useSelector(
    (state: any) => state.examGroupReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  //Lấy danh sách nhóm phần thi
  const handleGetExamGroups = () => {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        populate: "sections",
      }),
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách nhóm phần thi thất bại",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(getExamGroups(payload));
  };
  useEffect(() => {
    handleGetExamGroups();
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Mã nhóm phần thi",
      dataIndex: "code",
      key: "code",
      align: "left",
    },
    {
      title: "Tên nhóm phần thi",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Icon hiển thị",
      dataIndex: "icon",
      key: "icon",
      align: "center",
      render(value, record, index) {
        return <img src={value} width={60} />;
      },
    },
    {
      title: "Phần thi con",
      dataIndex: "sections",
      key: "sections",
      align: "left",
      render(value: SectionType[], record, index) {
        return value.map((item: SectionType, index: number) => {
          return (
            <>
              <a>
                {index + 1}. {item.name}
              </a>{" "}
              <br />
            </>
          );
        });
      },
    },
    {
      title: "Mô tả",
      dataIndex: "des",
      key: "des",
      width: 220,
      align: "left",
    },
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   align: "center",
    //   render(value, record, index) {
    //     return moment(value).format("DD/MM/YYYY");
    //   },
    // },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: 'right',
      width: 120,
      render(value, record, index) {
        return (
          <Space>
            <a
              onClick={() => {
                const payload: PayloadType = {
                  params: _.get(record, "id", ""),
                  query: queryString.stringify({
                    populate: "sections",
                  }),
                  callback: {
                    success(examGroup) {
                      const payload: ModalInfoType = {
                        open: true,
                        title: "Sửa nhóm phần thi",
                        content: <CUExamGroupSetting />,
                        size: 1000,
                        dataUpdate: examGroup,
                      };
                      dispatch(setModal(payload));
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Lấy thông tin phần thi thất bại!",
                      });
                    },
                  },
                };
                dispatch(getExamGroup(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa phần thi này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const payload: PayloadType = {
                  params: _.get(record, "id", ""),
                  callback: {
                    success(values) {
                      notification.success({
                        message: "Xoá nhóm phần thi thành công!",
                      });
                      handleGetExamGroups();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Xoá nhóm phần thi thất bại",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deleteExamGroup(payload));
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Thiết lập nhóm phần thi</Title>
        </div>
        <>
          <div
            className="space-between"
            style={
              {
                // marginTop: 24,
                // marginBottom: 24,
              }
            }
          >
            <Title level={5}>Danh sách phần thi</Title>
            <Space className="custom-placeholder-select">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Thêm mới nhóm phần thi",
                    content: <CUExamGroupSetting />,
                    dataUpdate: "",
                    size: 1000,
                  };
                  dispatch(setModal(payload));
                }}
              >
                Thêm mới
              </Button>
            </Space>
          </div>
          <Spin tip={"Đang tải..."} spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={_.get(examGroups, "results", [])}
              pagination={{
                total: _.get(examGroups, "totalResults", 0),
                current: _.get(examGroups, "page", 0),
                pageSize: _.get(examGroups, "limit", 0),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
              }}
              onChange={({ current, pageSize }) => {
                if (_.get(query, "limit", 0) !== pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: 1,
                      limit: pageSize,
                    })
                  );
                }
                dispatch(
                  setQuery({
                    ...query,
                    page: current,
                    limit: pageSize,
                  })
                );
              }}
              scroll={{ x: 1300, y: 600 }}
            />
          </Spin>
        </>
      </Space>
    </Content>
  );
}

export default ExamGroupSetting;

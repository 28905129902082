import { all, fork } from "redux-saga/effects";
import { accountSaga } from "sagas/accountSaga";
import userSaga from "./userSaga";
import roleSaga from "./roleSaga";
import schoolSaga from "./schoolSaga";
import classroomSaga from "./classroomSaga";
import provinceSaga from "./provinceSaga";
import districtSaga from "./districtSaga";
import awardSaga from "./awardSaga";
import courseSaga from "./courseSaga";
import paymentSaga from "./paymentSaga";
//HDH
import sectionSaga from "./sectionSaga";
import questionBankSaga from "sagas/questionbankSaga";
import fieldSaga from "sagas/fieldSaga";
import examQuestionSaga from "sagas/examQuestionSaga";
import examGroupSaga from "./examGroupSaga";
import examAttemptSaga from "sagas/examAttemptSaga";
function* rootSaga() {
  yield all([fork(accountSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(schoolSaga)]);
  yield all([fork(classroomSaga)]);
  yield all([fork(provinceSaga)]);
  yield all([fork(districtSaga)]);
  yield all([fork(awardSaga)]);
  yield all([fork(courseSaga)]);
  yield all([fork(paymentSaga)]);
  yield all([fork(sectionSaga)]);
  yield all([fork(questionBankSaga)]);
  yield all([fork(fieldSaga)]);
  yield all([fork(examQuestionSaga)]);
  yield all([fork(examGroupSaga)]);
  yield all([fork(examAttemptSaga)]);
}
export default rootSaga;

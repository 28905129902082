import { createSlice } from "@reduxjs/toolkit";

export const pageTypeSlice: any = createSlice({
  name: "pageTypeSlice",
  initialState: {
    questionType: "",
    answerType: "",
  },
  reducers: {
    setPageType: (state, action) => {
      state.questionType = action.payload.questionType;
      state.answerType = action.payload.answerType;
    },
  },
});
export const { setPageType } = pageTypeSlice.actions;
export default pageTypeSlice.reducer;

import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Layout, Modal } from "antd";
import SideBar from "components/Sidebar/Sidebar";
import AdminHeader from "components/Headers/AdminHeader";
import { Redirect, Route } from "react-router";
import routes from "routes";
import { Content, Footer } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import _ from "lodash";
import { closeModal } from "utils";
import { PayloadType, SectionType } from "type";
import PageCommon from "pages/PageCommon";
import queryString from "query-string";
import {
  getSections,
  getSectionsMenu,
  setSectionsMenu,
} from "features/sectionSlice";
const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          render={() => {
            return <PageCommon section={prop} />;
          }}
        />
      );
    } else {
      return null;
    }
  });
};
const Admin: React.FC = () => {
  //Modal
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modalReducer);
  const [routesConfig, setRoutesConfig] = useState<any>([]);
  const { sectionsMenu, query } = useSelector(
    (state: any) => state.sectionReducer
  );
  useEffect(() => {
    if (!modal?.open) {
      dispatch(
        setModal({
          content: "",
        })
      );
    }
  }, [modal?.open]);
  /**
   * Lấy danh sách phần thi để hiển thị trong quản lý phần thi
   */
  const getSectionsData = () => {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          page: 1,
          limit: 99999,
          year: "",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      skipUpdateReducer: true,
      callback: {
        success(sections) {
          dispatch(setSectionsMenu(sections));
        },
        failed(errorMessage) {
          console.log("Lấy danh sách menu thất bại!");
        },
      },
    };
    dispatch(getSectionsMenu(payload));
  };
  useEffect(() => {
    getSectionsData();
  }, []);
  useEffect(() => {
    const results = _.get(sectionsMenu, "results", []);
    if (!!results && Array.isArray(results)) {
      const temp = results
        .filter((item: SectionType) => {
          return item.isShowSide;
        })
        .map((item: SectionType) => {
          return {
            ...item,
            path: `/${_.get(item, "id", "")}`,
            name: _.get(item, "name", ""),
            layout: "/admin",
            roles: [],
          };
        });
      routes.routesSM[0].views = [
        ...routes.routesTemp,
        ...temp,
        // {
        //   fields: [
        //     "Chung",
        //     "Điều lệ Đội",
        //     "Nghi thức Đội",
        //     "Lịch sử Đội",
        //     "Phong trào lớn của Đội",
        //   ],
        //   year: 2023,
        //   code: "TN_2023",
        //   isShowSide: true,
        //   questionType: "semaphore",
        //   answerType: "semaphore",
        //   des: "Trắc nghiệm 2023",
        //   id: "648c4b6b2d35c60012866888",
        //   layout: "/admin",
        //   path: "/648c4b6b2d35c60012866888",
        //   name: "Semaphore",
        //   roles: [],
        // },
      ];
      setRoutesConfig(getRoutes(routes.routesSM));
    }
  }, [sectionsMenu]);
  //kiểm tra đăng nhập
  if (!localStorage.getItem("accountInfo") || !localStorage.getItem("tokens")) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <Layout>
      <AdminHeader />
      <Layout hasSider={true}>
        <SideBar />
        <Modal
          centered
          afterClose={() => {
            closeModal(dispatch, setModal);
          }}
          key={"custom-modal"}
          open={_.get(modal, "open", false)}
          onCancel={() => {
            closeModal(dispatch, setModal);
          }}
          bodyStyle={{
            padding: 0,
          }}
          footer={false}
          title={_.get(modal, "title", "")}
          width={_.get(modal, "size", undefined)}
        >
          {_.get(modal, "content", "")}
        </Modal>
        {routesConfig}
      </Layout>
    </Layout>
  );
};

export default memo(Admin);

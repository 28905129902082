import { accountLoginSuccess } from "features/accountSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* loginRequest(data: ActionType): any {
  const url = ServiceURL.logIn;
  const { callback, body } = data.payload;
  try {
    const res = yield call(POST, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(accountLoginSuccess(_.get(res, "data.user", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* changePassword(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.changepass}/${params}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    if (error?.response?.data?.code === 400) {
      callback.failed("Mật khẩu cũ không chính xác!");
      return;
    }
    callback.failed(err);
  }
}
export function* accountSaga() {
  yield takeLatest("accountSlice/accountLogin", loginRequest);
  yield takeLatest("accountSlice/changePassword", changePassword);
}

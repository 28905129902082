import { call, put, takeLatest } from "redux-saga/effects";
import { GET } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType, PayloadType } from "type";
import _ from "lodash";
import { getFieldBySectionIDSuccess } from "features/fieldSlice";

export function* getFieldBySectionID(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.section}/${ServiceURL.field}/${params}?${
    query ?? ""
  }`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      if (!skipUpdateReducer) yield put(getFieldBySectionIDSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* fieldSaga() {
  yield takeLatest("fieldSlice/getFieldBySectionID", getFieldBySectionID);
}

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { setModal } from "features/modalSlice";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  notification,
} from "antd";
import _ from "lodash";
import * as yup from "yup";
import { useForm } from "antd/lib/form/Form";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { PayloadType } from "type";
import { closeModal } from "utils";
import { importListUser } from "features/userSlice";
const { Text } = Typography;
interface ImportListUserType {
  handleGetListUser: () => void;
  name: ReactElement;
  urlFile: string | undefined;
}
function ImportListUser({ handleGetListUser,name, urlFile }: ImportListUserType) {
  //Modal
  const [isLoading,setIsLoading] = useState(false);
  const [formValue] = useForm();
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modalReducer);
  const { role } = useSelector((state: any) => state.roleReducer);
  useEffect(()=>{
    if (!modal?.open){
      formValue.resetFields();
    }
  },[modal])
  const uploadSchema = yup.object().shape({
    list: yup.lazy((file) => {
      switch (typeof file) {
        case "object":
          return yup
            .object()
            .required("Vui lòng chọn file để tải lên!")
            .test("check-file", "Vui lòng chọn file để tải lên!", () => {
              const fileList = _.get(file, "fileList", []);
              return (_.isArray(fileList) && fileList.length > 0);
            });
        default:
          return yup.string().required("Vui lòng chọn file để tải lên!");
      }
    }),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await uploadSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const [fileUpload, setFileUpload] = useState<any>(null);
  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    const { fileList } = info;
    if (!!fileList) {
      setFileUpload(_.get(fileList[0], "originFileObj", null));
      return;
    }
  }
  function handleCloseForm() {
    closeModal(dispatch, setModal);
  }
  function confirmUpload() {
    setIsLoading(true)
    const roleId = _.get(role,"id","");
    let formData = new FormData();
    formData.append("file", fileUpload);
    if (!roleId){
      notification.error({
        message: "Lỗi Uploadfile!",
        description: "Không tìm thấy quyền của danh sách người dùng!",
      });
      return;
    }
    const payload: PayloadType = {
      params: roleId,
      data: formData,
      callback: {
        success(values) {
          notification.success({
            message: "Upload File thành công!",
          });
          handleGetListUser();
          handleCloseForm();
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Upload File thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(importListUser(payload));
  }
  return (
    <Form
      labelCol={{ span: 24 }}
      form={formValue}
      onFinish={() => {
        confirmUpload();
      }}
      
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={
            <Text>
              {name}&nbsp;
              <a
                href={urlFile}
                style={{
                  fontStyle: "italic",
                }}
                target="_blank"
              >
                (Tải xuống mẫu)
              </a>
            </Text>
          }
          name={"list"}
          rules={[yupSync]}
        >
          <Upload
            name="logo"
            listType="text"
            beforeUpload={() => false}
            maxCount={1}
            onChange={handleChange}
            onRemove={() => {
              formValue.setFieldValue("list", null);
              formValue.validateFields();
            }}
            accept=".xlsx"
          >
            <Button style={{
              width: 200
            }} icon={<UploadOutlined />}>Tải lên</Button>
          </Upload>
        </Form.Item>
      </div>
      <div
        className="ant-modal-footer"
        style={{
          width: "100%",
        }}
      >
        <Button
        disabled={isLoading}
          type="default"
          onClick={() => {
            dispatch(
              setModal({
                ...modal,
                open: false,
              })
            );
          }}
        >
          Huỷ
        </Button>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          Xác nhận
        </Button>
      </div>
    </Form>
  );
}

export default ImportListUser;

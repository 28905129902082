import { createSlice } from "@reduxjs/toolkit";

export const userSlice: any = createSlice({
  name: "userSlice",
  initialState: {
    users: [],
    roles: [],
    query: {
      page: 1,
      limit: 10,
    },
    user: {},
  },
  reducers: {
    getUsers: (state, action) => {},
    getUser: (state, action) => {},
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setStateUser: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createUser: (state, action) => {},
    updateUser: (state, action) => {},
    deleteUser: (state, action) => {},
    setPassByAdmin: (state, action) => {},
    importListUser: (state, action) => {},
  },
});
export const {
  getUsers,
  getUser,
  setUser,
  updateUser,
  getUsersSuccess,
  getUserSuccess,
  setQuery,
  createUser,
  deleteUser,
  setStateUser,
  setPassByAdmin,
  importListUser
} = userSlice.actions;
export default userSlice.reducer;

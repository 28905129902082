/**
 * Màn hình thêm mới câu hỏi cho phần thi
 * VD: Trắc nghiệm, Semaphore, Dấu đường,...
 * TABLE dạng chọn nhiều có hình ảnh và ô select
 */
import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Upload,
} from "antd";
import _ from "lodash";
import { ColumnsType } from "antd/lib/table";
import {
  PlusSquareOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import * as yup from "yup";
import { _convertToBase64, convertToBase64, getValidateField } from "utils";
import { RcFile } from "antd/lib/upload";
import { AnswerQuestionBank, Base64Data } from "type";
import { useSelector } from "react-redux";

interface DataType {
  key: number;
  content: string | ReactElement;
  answer: string | ReactElement;
  isAdd: boolean;
}
function getInitDataSource(dataUpdate: DataType | null) {
  if (!dataUpdate) {
    return [
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ];
  }
  const options = _.get(dataUpdate, "options[0]", []);
  const answers: any = options.map(
    (item: AnswerQuestionBank, index: number) => {
      return {
        key: index,
        content: item.content,
        answer: "",
        isAdd: true,
      };
    }
  );
  return [
    ...answers,
    {
      key: answers.length,
      content: "",
      answer: "",
      isAdd: true,
    },
  ];
}
interface AMultiChoiceImgDrag {
  form: FormInstance;
}
function AMultiChoiceImgDrag({ form }: AMultiChoiceImgDrag) {
  const initKey = 0;
  const [dataSource, setDataSource] = useState<DataType[]>(
    getInitDataSource(null)
  );
  const { dataUpdate } = useSelector((state: any) => state.modalReducer);
  const [imgBase64, setImgBase64] = useState<Base64Data[]>([]);
  const [count, setCount] = useState(initKey + 1);
  const [correctAnswerCount, setCorrectAnserCount] = useState<number>(0);
  // Khởi tạo giá trị cho form sửa
  useEffect(() => {
    if (!!dataUpdate) {
      const stt: any = [];
      const answer: any = [];
      const init: any = getInitDataSource(dataUpdate).map((item: any) => {
        stt.push(undefined);
        answer.push(false);
        return _.get(item, "content", "");
      });
      const options = _.get(dataUpdate, "options[0]", "");
      const correctAnswer = _.get(dataUpdate, "correctAnswer", []);
      let maxSTT = 0;
      if (_.isArray(correctAnswer)) {
        correctAnswer.map((cr: string) => {
          const item = cr.split(":");
          const sttIndex = stt[parseInt(item[1]) - 1] || [];
          stt[parseInt(item[1]) - 1] = [...sttIndex, parseInt(item[0])];
          answer[parseInt(item[1]) - 1] = true;
          stt[parseInt(item[1]) - 1].map((index: number) => {
            if (index > maxSTT) {
              maxSTT = index;
            }
          });
        });
      }
      form.setFieldValue(
        "content",
        options.map((item: AnswerQuestionBank) => item.image)
      );
      setImgBase64(
        options.map((item: AnswerQuestionBank) => ({
          key: item.code - 1,
          base64: item.image,
        }))
      );
      form.setFieldValue("stt", stt);
      form.setFieldValue("answer", answer);
      setCount(init.length);
      setDataSource(getInitDataSource(dataUpdate));
      setCorrectAnserCount(maxSTT);
      return;
    }
    // Khởi tạo form thêm mới
    setCount(1);
    form.resetFields();
    setDataSource([
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ]);
  }, [dataUpdate]);
  const getValuesFromFormTable = () => {
    const { stt, content, answer } = form.getFieldsValue();
    if (!_.isArray(stt) || !_.isArray(content) || !_.isArray(answer))
      return null;
    return content.map((item: string, index: number) => ({
      stt: stt[index],
      answer: answer[index],
      content: item,
    }));
  };
  const MImageSchema = yup.object().shape({
    stt: yup.lazy((stt: any) => {
      switch (typeof stt) {
        case "string":
          return yup.string().required("STT không được để trống!");
        case "object":
          return yup
            .array()
            .required("STT không được để trống!")
            .test(
              "check-arr",
              "STT không được để trống!",
              (sttIndex: number[]) => {
                return sttIndex.length !== 0;
              }
            );

        default:
          return yup.string().required("STT không được để trống");
      }
    }),
    content: yup.lazy((fileList: any) => {
      switch (typeof fileList) {
        case "object":
          return yup
            .array()
            .test(
              "check-icon",
              "Ảnh phải có kích thước nhỏ hơn 200KB!",
              (fileList: any) => {
                if (_.isArray(fileList)) {
                  const checkFile = fileList.some((file: RcFile) => {
                    return file.size <= 200000;
                  });
                  return checkFile;
                }
                return true;
              }
            )
            .required("Vui lòng tải lên ảnh cho đáp án!");

        default:
          return yup.string().required("Không được để trống!");
      }
    }),
  });
  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      content: "",
      answer: "",
      isAdd: true,
    };
    const newDataSource = [...dataSource, newData];
    setDataSource(newDataSource);
    setCount(count + 1);
  };
  const caculatorCorrectAnswer = () => {
    //Tính số đáp án đúng dựa vào các ô được check.
    const arr = form.getFieldValue("answer");
    if (_.isArray(arr)) {
      const count = dataSource.reduce(
        (sum: number, item: DataType, index: number) => {
          if (!!arr[item.key]) return sum + 1;
          return sum;
        },
        0
      );
      if (count > correctAnswerCount) setCorrectAnserCount(count);
      return;
    }
    setCorrectAnserCount(0);
  };
  useEffect(() => {
    if (!!dataUpdate) {
      handleTableChange();
    }
  });
  const handleTableChange = () => {
    caculatorCorrectAnswer();
    form.validateFields();
  };
  const handleChangeContent = (newData: DataType[]) => {
    const newArrImg: any = [...imgBase64];
    const keys = newData.map((item: DataType) => item?.key);
    const itemAdd = newData[newData.length - 1];
    const contents: string[] = (form.getFieldValue("content") || []).map(
      (item: string | undefined, index: number) => {
        if (!keys.includes(index) || index === itemAdd.key) {
          newArrImg[index] = undefined;
        }
        return !keys.includes(index) || index === itemAdd.key
          ? undefined
          : item;
      }
    );

    form.setFieldValue("imgBase64", newArrImg);
    form.setFieldValue("content", contents);
    //Xử lý danh sách đáp án
    const codeAnswer: number[] = [];
    const answers = form.getFieldValue("answer");
    if (_.isArray(answers)) {
      answers.map((answer: undefined | boolean, index: number) => {
        if (!!answer) {
          const ind = dataSource.findIndex(
            (item: DataType) => item.key === index
          );
          codeAnswer.push(ind + 1);
        }
      });
    }
    form.setFieldValue("code", codeAnswer);
  };
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    caculatorCorrectAnswer();
    setDataSource(newData);
    handleTableChange();
  };
  //Định nghĩa cột
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleSetBase64Data = (rcFile: any, key: any) => {
    const isHasKey = imgBase64.some((item: Base64Data) => item.key === key);
    const { fileList } = rcFile;
    if (isHasKey) {
      //Nếu có thì thay thế
      const newImgArr = [...imgBase64];
      convertToBase64(_.get(fileList[0], "originFileObj", {}), (base64) => {
        newImgArr.splice(key, 1, { key, base64 });
        setImgBase64(newImgArr);
      });
      return;
    }
    // Thêm mới
    convertToBase64(_.get(fileList[0], "originFileObj", {}), (base64) => {
      setImgBase64([
        ...imgBase64,
        {
          key,
          base64,
        },
      ]);
    });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      width: "25%",
      align: "center",
      render(value, record, index) {
        try {
          return !!form.getFieldValue(["answer", _.get(record, "key", "")]) ? (
            <Form.Item
              name={["stt", _.get(record, "key", "")]}
              rules={[getValidateField(MImageSchema, "stt")]}
              initialValue={value}
            >
              <Select
                mode="multiple"
                onChange={() => {
                  form.validateFields();
                }}
                options={(function () {
                  const options = [];
                  for (let i = 1; i <= correctAnswerCount; i++) {
                    options.push({
                      label: i,
                      value: i,
                    });
                  }
                  return options;
                })()}
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <Form
                        name="other-form"
                        style={{
                          margin: 0,
                          padding: 0,
                          marginTop: 8,
                          marginLeft: 8,
                          marginRight: 8,
                          display: "flex",
                          flexGrow: 1,
                        }}
                        onFinish={(values) => {
                          console.log(values);
                          if (!!_.get(values, "totalQuestion", "")) {
                            setCorrectAnserCount(
                              parseInt(_.get(values, "totalQuestion", "0"))
                            );
                            form.validateFields();
                          }
                        }}
                      >
                        <Form.Item
                          name={"totalQuestion"}
                          rules={[
                            () => ({
                              validator(_, value) {
                                const totalQuestion = parseInt(value);
                                if (totalQuestion < 1 || totalQuestion > 50) {
                                  return Promise.reject(
                                    new Error("Phải là số từ 1-50")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            name="totalQuestion"
                            type="number"
                            min={0}
                            placeholder="Nhập số"
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 8 }}
                          >
                            Thêm
                          </Button>
                        </Form.Item>
                      </Form>
                    </>
                  );
                }}
              />
            </Form.Item>
          ) : (
            value
          );
        } catch (error) {
          console.log(error);
        }
      },
    },
    {
      title: "Nội dung đáp án",
      dataIndex: "content",
      width: "15%",
      align: "center",
      render(value, record, index) {
        const image = form.getFieldValue("content");
        const urlImage =
          !!image && Array.isArray(image[record.key])
            ? URL.createObjectURL(
                _.get(image[record.key][0], "originFileObj", null)
              )
            : null;
        const urlUpdate = form.getFieldValue([
          "content",
          _.get(record, "key", ""),
        ]);
        return (
          <Form.Item
            name={["content", _.get(record, "key", "")]}
            // valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={
              index != dataSource.length - 1
                ? [getValidateField(MImageSchema, "content")]
                : []
            }
          >
            <Upload
              showUploadList={false}
              maxCount={1}
              beforeUpload={() => false}
              onChange={async (rcFile) => {
                handleSave(record);
                handleSetBase64Data(rcFile, _.get(record, "key", ""));
              }}
            >
              {!!urlImage || !!value || !!urlUpdate ? (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "70%",
                      maxHeight: 70,
                    }}
                    key={urlImage ? urlImage : urlUpdate ? urlUpdate : value}
                    src={urlImage ? urlImage : urlUpdate ? urlUpdate : value}
                    alt="preview"
                  />
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                    className="item-hover"
                  >
                    <Space>
                      <EditOutlined
                        style={{
                          color: "white",
                          fontSize: 24,
                        }}
                      />
                      <span>Sửa</span>
                    </Space>
                  </div>
                </div>
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Tải lên ảnh</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        );
      },
    },
    {
      title: "Đáp án đúng",
      dataIndex: "answer",
      width: "15%",
      align: "center",
      render(value, record, index) {
        return (
          <Form.Item
            name={["answer", _.get(record, "key", "")]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => {
                handleSave(record);
                form.validateFields();
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: "15%",
      align: "center",
      render: (val, record, index) => {
        return dataSource.length >= 1 ? (
          <Space>
            {index === dataSource.length - 1 ? (
              <a
                onClick={() => {
                  handleAdd();
                }}
              >
                <PlusSquareOutlined
                  style={{
                    fontSize: 16,
                    color: "#1890FF",
                  }}
                />
              </a>
            ) : (
              <Popconfirm
                title="Xóa câu trả lời này?"
                onConfirm={() => handleDelete(_.get(record, "key", ""))}
                cancelText={"Huỷ bỏ"}
                okText={"Xác nhận"}
              >
                <CloseCircleOutlined
                  style={{
                    fontSize: 16,
                    color: "#FF7875",
                  }}
                />
              </Popconfirm>
            )}
          </Space>
        ) : null;
      },
    },
  ];
  return (
    <Form
      form={form}
      onFinish={(values) => {
        handleChangeContent(dataSource);
      }}
    >
      <Table
        bordered
        className="custom-form-item"
        rowKey={"key"}
        onRow={(record) => ({
          onChange: () => handleSave(record),
        })}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
        scroll={{ y: 300, x: 700 }}
        pagination={false}
      />
    </Form>
  );
}

export default AMultiChoiceImgDrag;

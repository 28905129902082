import { BASE_URL } from "services/ServiceURL";
import _ from "lodash";
import axios from "axios";

export async function refreshTokens() {
  const url = BASE_URL + "/auth/refresh-tokens";
  const tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
  if (!!tokens && Object.keys(tokens).length>0) {
    const refreshToken = _.get(tokens, "refresh.token", null);
    try {
      const res = await axios.post(url, {
        refreshToken: refreshToken,
      });
      return _.get(res, "data", {});
    } catch (error) {
      return tokens;
    }
  }
  localStorage.clear();
  window.history.pushState("", "", "/auth/login");
  window.location.reload();
  return null;
}
export default refreshTokens;

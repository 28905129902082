/**
 * Màn hình quản trị tổ chức lớp
 */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Popconfirm,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import type { InputRef } from "antd";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { memo, useRef, useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _, { result } from "lodash";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { ColumnType } from "antd/lib/table";
import { PayloadType, SectionType } from "type";
import queryString from "query-string";
import {
  getExamAttempts,
  getExamAttemptsSuccess,
  setExamAttemps,
} from "features/examAttemptSlice";
import moment from "moment";
import { FULL_DATE_FORMAT } from "const";
import { setQuery } from "features/examAttemptSlice";
import { getScore } from "utils";
import StatisticalDetail from "pages/PageCommon/Statistical/StatisticalDetail";
interface DataType {
  totalScore: number;
  totalTime: number;
  status: string;
  examQuestion: string;
  examinee: {
    isPasswordChange: boolean;
    status: string;
    name: string;
    gender: string;
    phone: string;
    email: string;
    roleId: string;
    address: string;
    idNum: string;
    id: string;
  };
  startTime: string;
  completeTime: string;
  id: string;
}
type DataIndex = keyof DataType;
const { Title } = Typography;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
interface PageCommonType {
  section: SectionType;
}
function Statistical({ section }: PageCommonType) {
  const { classrooms, filters } = useSelector(
    (state: any) => state?.classroomReducer
  );
  const { examAttempts, query } = useSelector(
    (state: any) => state.examAttemptReducer
  );
  const { examQuestion } = useSelector(
    (state: any) => state.examQuestionReducer
  );
  const searchInput = useRef<InputRef>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDetail,setIsOpenDetail] = useState(false);
  const dispatch = useDispatch();
  //Bỏ tìm kiếm tên tài khoản
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };
  //Định nghĩa cột trong bảng
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên thí sinh",
      dataIndex: ["examinee", "name"],
      key: "name",
      align: "center",
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Số báo danh",
      dataIndex: ["examinee", "idNum"],
      key: "idNum",
      align: "center",
      // ...getColumnSearchProps("endTime"),
    },
    {
      title: "Thời gian hoàn thành",
      dataIndex: "completeTime",
      key: "completeTime",
      align: "center",
      render(value, record, index) {
        return !!value ? moment(value).format(FULL_DATE_FORMAT) : "";
      },
      // sorter: {
      //   compare: (a, b) => a.numQuestion - b.numQuestion,
      //   multiple: 1,
      // },
    },
    {
      title: "Thời gian thi (giây)",
      dataIndex: "totalTime",
      key: "totalTime",
      align: "center",
      // sorter: {
      //   compare: (a, b) => a.point - b.point,
      //   multiple: 2,
      // },
    },
    {
      title: "Điểm thi",
      dataIndex: "totalScore",
      key: "totalScore",
      align: "center",
      // sorter: {
      //   compare: (a, b) => a.point - b.point,
      //   multiple: 3,
      // },
      render(value, record, index) {
        return getScore(value);
      },
    },
  ];
  // Lấy kì thi dựa vào phần thi
  useEffect(() => {
    setIsLoading(true);
    dispatch(setExamAttemps([]));
    const results = _.get(examQuestion, "results");
    if (!!results && Array.isArray(results) && results.length > 0) {
      getExamAttemptsList(results[0]);
    } else {
      setIsLoading(false);
    }
  }, [examQuestion,query]);
  // Lấy danh sách thống kê
  function getExamAttemptsList(exQuestionInfo: any) {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        examQuestion: _.get(exQuestionInfo, "id", ""),
      }),
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin thống kê không thành công!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(getExamAttempts(payload));
  }
  return (
    <>
    <StatisticalDetail isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} />
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Title level={4}>Danh sách kết quả thí sinh</Title>
        <Button
        type="primary"
        onClick={()=>{setIsOpenDetail(true)}}
        >
          <FileSearchOutlined />
          Thống kê chi tiết
        </Button>
      </div>
      <Space size={48} align="start">
        <Space direction="vertical">
          {!!_.get(examAttempts, "totalResults", null) && (
            <CustomLabelValue
              hasMarginleft={false}
              label="Số người thi"
              value={_.get(examAttempts, "totalResults", 0)}
            />
          )}
        </Space>
      </Space>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={_.get(examAttempts, "results", [])}
        pagination={{
          total: _.get(examAttempts, "totalResults", []) ?? 0,
          current: _.get(examAttempts, "page", []),
          pageSize: _.get(examAttempts, "limit", []),
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          showSizeChanger: true,
          onShowSizeChange(page, pageSize) {
            console.log(page,pageSize)
            if (pageSize !== _.get(query, "limit", 0)) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          }
        }}
        scroll={{ x: 1300, y: 500 }}
      />
    </>
  );
}

export default memo(Statistical);

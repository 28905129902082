import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import {
  PlusSquareOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import type { InputRef, UploadFile } from "antd";
import { Popconfirm } from "antd";
import _ from "lodash";
import * as yup from "yup";
import { ColumnsType } from "antd/lib/table";
import getRuleOfTable, { convertToBase64 } from "utils";
import { Switch, notification } from "antd";
import { closeModal } from "utils";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import {
  AnswerQuestionBank,
  PayloadType,
  QuestionBankType,
  SectionType,
  SelectOptionType,
} from "type";
import {
  createQuestionBank,
  getQuestionBanks,
  statisticalFieldBysection,
  updateQuestionBank,
} from "features/questionbanksSlice";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { getFieldBySectionID } from "features/fieldSlice";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { createExamQuestion } from "features/examQuestionSlice";
import { getSections } from "features/sectionSlice";
import {
  createExamGroup,
  getExamGroups,
  updateExamGroup,
} from "features/examGroupSlice";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
const { Title } = Typography;
interface DataType {
  key: number;
  code: string;
  name: string;
  des: string;
  icon: string;
  sections: string[];
  isAdd: boolean;
}
interface CUQuestion {
  section: SectionType;
  questionUpdate?: QuestionBankType;
}
const initDataSource = [
  {
    key: 0,
    code: "",
    name: "",
    des: "",
    icon: "",
    sections: [],
    isAdd: true,
  },
];

function getInitDataSource(dataUpdate: DataType[] | undefined) {
  const data = [
    {
      key: 0,
      code: "",
      name: "",
      des: "",
      icon: "",
      sections: [],
      isAdd: true,
    },
  ];
  if (!!dataUpdate) {
    const sections = _.get(dataUpdate, "sections", []);
    if (Array.isArray(sections)) {
      const results = sections.map((item: SectionType, index: number) => {
        return {
          ...item,
          key: index,
          code: item.code,
          name: item.name,
          des: item.des,
          icon: "",
          sections: [],
          isAdd: false,
        };
      });
      return [
        ...results,
        {
          ...data[0],
          key: results.length,
          isAdd: true,
        },
      ];
    }
  }
  return data;
}
interface DataType {
  key: number;
  code: string;
  name: string;
  des: string;
  icon: string;
  sections: string[];
}

function CUExamGroupSetting() {
  const dispatch = useDispatch();
  const { dataUpdate, open } = useSelector((state: any) => state.modalReducer);
  const [sectionOptions, setSectionOptions] = useState<SelectOptionType[]>([]);
  const [formLeft] = useForm();
  const [formRight] = useForm();
  const [dataSource, setDataSource] = useState<DataType[]>(
    getInitDataSource(dataUpdate)
  );
  const [icon, setIcon] = useState<any>();
  const { query } = useSelector((state: any) => state.examGroupReducer);
  //Lấy danh sách nhóm phần thi
  const handleGetExamGroups = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        populate: "sections",
      }),
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Lấy danh sách nhóm phần thi thất bại",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getExamGroups(payload));
  };
  // Lấy options cho ô select mã phần thi con
  const getFieldsOptions = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 99999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(sections) {
          const results = _.get(sections, "results", []);
          if (!!results && Array.isArray(results)) {
            setSectionOptions(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSections(payload));
  };
  // Khởi tạo giá trị cho form
  useEffect(() => {
    if (!open) {
      formLeft.resetFields();
      formRight.resetFields();
      setDataSource(initDataSource);
    }
  }, [open]);
  useEffect(() => {
    getFieldsOptions();
    if (!!dataUpdate) {
      // Khởi tạo form sửa
      formLeft.setFieldValue("code", _.get(dataUpdate, "code", ""));
      formLeft.setFieldValue("name", _.get(dataUpdate, "name", ""));
      formLeft.setFieldValue("des", _.get(dataUpdate, "des", ""));
      formLeft.setFieldValue("icon", _.get(dataUpdate, "icon", ""));
      const sections = _.get(dataUpdate, "sections", []);
      if (Array.isArray(sections)) {
        formRight.setFieldValue(
          "code",
          sections.map((item: SectionType) => ({
            label: item.code,
            value: item.id,
          }))
        );
        formRight.setFieldValue(
          "name",
          sections.map((item: SectionType) => item.name)
        );
      }
      setIcon(_.get(dataUpdate, "icon", ""));
      setDataSource(getInitDataSource(dataUpdate));
      return;
    }
  }, [dataUpdate]);
  //Bảng chọn đáp án
  const examSchema = yup.object().shape({
    code: yup.lazy((item) => {
      switch (typeof item) {
        case "object":
          return yup.object().required("bac");
        default:
          return yup.string().required("Mã phần thi không được để trống!");
      }
    }),
  });

  const getValidateField = (key: string) => {
    const yupSync = {
      async validator({ field }: any, value: any) {
        await examSchema.validateSyncAt(key, { [key]: value });
      },
    };
    return yupSync;
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      width: 50,
      align: "center",
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: "Mã phần thi con",
      dataIndex: "code",
      key: "code",
      align: "center",
      render(value, record: DataType, index) {
        return (
          <Form.Item
            name={["code", record.key]}
            rules={getRuleOfTable(index, dataSource, getValidateField("code"))}
          >
            <Select
              options={sectionOptions
                .filter((item: SelectOptionType) => {
                  let arr = formRight.getFieldValue("code");
                  if (Array.isArray(arr)) {
                    arr = arr
                      .filter(
                        (item: string | SelectOptionType | undefined) => !!item
                      )
                      .map((item: string | SelectOptionType) => {
                        if (typeof item === "object") {
                          return item.value;
                        }
                        return item;
                      });
                    return !arr.includes(item.value);
                  }

                  return true;
                })
                .map((item: SelectOptionType) => {
                  return {
                    label: _.get(item, "code", ""),
                    value: item.value,
                  };
                })}
              placeholder="Nhập mã phần thi con"
              onSelect={(val, { label, value }: any) => {
                formRight.setFieldValue(
                  ["name", record.key],
                  sectionOptions.find((item: SelectOptionType) => {
                    return item.value === value;
                  })?.label ?? ""
                );
                // handleSave(record)
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Tên phần thi con",
      dataIndex: "name",
      key: "name",
      align: "center",
      render(value, record, index) {
        return (
          <Form.Item name={["name", record.key]}>
            {formRight.getFieldValue(["name", record.key])}
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "isAdd",
      key: "isAdd",
      align: "center",
      render(value, record, index) {
        if (value) {
          return (
            <PlusSquareOutlined
              onClick={async () => {
                try {
                  const values = await formRight.validateFields();
                  if (!!values) handleAdd();
                } catch (error) {}
              }}
              style={{
                fontSize: 18,
                color: "#1890FF",
              }}
            />
          );
        }
        return (
          <Popconfirm
            title={"Bạn có chắc chắn muốn xóa đề thi này?"}
            onConfirm={() => {
              handleDelete(record.key);
            }}
            cancelText={"Huỷ"}
          >
            <CloseCircleOutlined
              style={{
                fontSize: 18,
                color: "#FF7875",
              }}
            />
          </Popconfirm>
        );
      },
    },
  ];
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const [count, setCount] = useState(!!dataUpdate ? dataSource.length : 1);
  const handleAdd = () => {
    const newItem: DataType = {
      key: count,
      code: "",
      name: "",
      des: "",
      icon: "",
      sections: [],
      isAdd: true,
    };
    const newData = [...dataSource, newItem];
    newData.map((item: DataType, index: number) => {
      // item.key = index;
      if (index === newData.length - 1) {
        item.isAdd = true;
      } else item.isAdd = false;
    });
    setDataSource([...newData]);
    setCount(count + 1);
  };
  const deleteFieldFormRight = (fieldName: string, iRemove: number) => {
    const arrField = formRight.getFieldValue(fieldName);
    if (!!arrField && Array.isArray(arrField)) {
      formRight.setFieldValue(
        fieldName,
        arrField.map((item: string | undefined, index: number) => {
          if (index === iRemove) {
            item = undefined;
          }
          return item;
        })
      );
    }
  };
  const handleDelete = (key: number) => {
    const newData: DataType[] = dataSource
      .filter((item: DataType) => {
        return item.key !== key;
      })
      .map((item: DataType, index: number) => {
        // item.key = index;
        return item;
      });
    deleteFieldFormRight("code", key);
    setDataSource([...newData]);
  };
  //Xử lý submit cả 2 form
  const handleSubmitForm = async () => {
    try {
      formLeft.submit();
      formRight.submit();
      const valuesFormLeft = await formLeft.validateFields();
      const valuesFormRight = await formRight.validateFields();
      if (!!dataUpdate) {
        handleUpdateQuestion(valuesFormLeft, valuesFormRight);
        return;
      }
      handleCreateQuestion(valuesFormLeft, valuesFormRight);
    } catch (error) {}
  };

  const handleCreateQuestion = (valuesFormLeft: any, valuesFormRight: any) => {
    const codeSections = _.get(valuesFormRight, "code", "");
    if (
      (Array.isArray(codeSections) && codeSections.length == 0) ||
      codeSections.length == 1
    ) {
      notification.error({
        message: "Thêm mới nhóm phần thi thất bại!",
        description: "Vui lòng thêm mới ít nhất một phần thi con!",
      });
      return;
    }
    const body = {
      code: _.get(valuesFormLeft, "code", ""),
      name: _.get(valuesFormLeft, "name", ""),
      des: _.get(valuesFormLeft, "des", ""),
      icon: icon,
      sections: _.isArray(codeSections)
        ? codeSections
            .slice(0, codeSections.length - 1)
            .filter((item: string | undefined) => !!item)
        : [],
    };
    const payload: PayloadType = {
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Thêm mới nhóm phần thi thành công!",
          });
          handleCloseForm();
          handleGetExamGroups();
        },
        failed(errorMessage) {
          notification.error({
            message: "Thêm mới nhóm phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };

    dispatch(createExamGroup(payload));
  };
  const handleUpdateQuestion = (valuesFormLeft: any, valuesFormRight: any) => {
    const codeSections = _.get(valuesFormRight, "code", "");
    if (
      (Array.isArray(codeSections) && codeSections.length == 0) ||
      codeSections.length == 1
    ) {
      notification.error({
        message: "Cập nhật nhóm phần thi thất bại!",
        description: "Vui lòng thêm mới ít nhất một phần thi con!",
      });
      return;
    }
    const body = {
      code: _.get(valuesFormLeft, "code", ""),
      name: _.get(valuesFormLeft, "name", ""),
      des: _.get(valuesFormLeft, "des", ""),
      icon: icon,
      sections: _.isArray(codeSections)
        ? codeSections
            .slice(0, codeSections.length - 1)
            .filter((item: string | undefined | SelectOptionType) => !!item)
            .map((item: SelectOptionType | string) => {
              if (typeof item === "object") {
                return item.value;
              }
              return item;
            })
        : [],
    };
    const payload: PayloadType = {
      params: dataUpdate?.id,
      body,
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật nhóm phần thi thành công!",
          });
          handleCloseForm();
          handleGetExamGroups();
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật nhóm phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateExamGroup(payload));
  };
  const handleCloseForm = () => {
    formLeft.resetFields();
    formRight.resetFields();
    setDataSource(initDataSource);
    closeModal(dispatch, setModal);
    setIcon(null);
  };
  //
  const formLeftSchema = yup.object().shape({
    code: yup.string().required("Mã nhóm phần thi không được để trống!"),
    name: yup.string().required("Tên nhóm phần thi không được để trống!"),
    icon: yup.lazy((rcFile) => {
      switch (typeof rcFile) {
        case "string":
          return yup.string().required("Không được để trống");
        case "object":
          return yup
            .object()
            .test(
              "check-icon",
              "Icon phải có kích thước nhỏ hơn 200KB!",
              (rcFile) => {
                if (_.isArray(_.get(rcFile, "fileList", []))) {
                  const checkFile = _.get(rcFile, "fileList", []).some(
                    (file: RcFile) => {
                      return file.size <= 200000;
                    }
                  );
                  return checkFile;
                }
                return true;
              }
            )
            .required("Vui lòng chọn icon đại diện cho nhóm phần thi!");
        default:
          return yup.string().required("Không được để trống");
      }
    }),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await formLeftSchema.validateSyncAt(field, { [field]: value });
    },
  };
  function handleFileChange(info: any) {
    if (
      _.isArray(_.get(info, "fileList", [])) &&
      _.get(info, "fileList", []).length > 0
    ) {
      const objFileObj = info.fileList[0].originFileObj;
      convertToBase64(objFileObj, (base64) => {
        setIcon(base64);
        // formLeft.setFieldValue("icon", objFileObj);
        // formLeft.validateFields();
      });
    }
  }
  return (
    <Form>
      <div className="ant-modal-body">
        <Row>
          <Col
            span={12}
            style={{
              paddingRight: 24,
            }}
          >
            <Form labelCol={{ span: 8 }} form={formLeft}>
              <Form.Item
                label={"Mã nhóm phần thi"}
                name={"code"}
                required
                rules={[yupSync]}
              >
                <Input placeholder="Nhập vào mã phần thi" />
              </Form.Item>
              <Form.Item
                label={"Tên nhóm phần thi"}
                name={"name"}
                required
                rules={[yupSync]}
              >
                <Input placeholder="Nhập vào tên phần thi" />
              </Form.Item>
              <Form.Item
                label={"Icon"}
                name={"icon"}
                required
                rules={[yupSync]}
              >
                <Upload
                  name="icon"
                  listType="picture-card"
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  //   onChange={handleChange}
                >
                  {icon ? (
                    <img
                      src={icon}
                      width={"100%"}
                      style={{
                        padding: "12px",
                      }}
                    />
                  ) : (
                    <Space direction="vertical">
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Tải lên</div>
                    </Space>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item label={"Mô tả"} name={"des"}>
                <TextArea rows={3} placeholder={"Nhập mô tả"} />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <Title level={5}>Danh sách phần thi con</Title>
            <Form form={formRight}>
              <Table
                className="custom-form-item"
                rowKey={"key"}
                size="middle"
                onRow={(record) => ({
                  onBlur: () => handleSave(record),
                })}
                rowClassName={() => "editable-row"}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                // scroll={{
                //   x: 700,
                //   y: 700,
                // }}
              />
            </Form>
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            handleCloseForm();
          }}
        >
          Huỷ
        </Button>
        <Button
          type="primary"
          onClick={() => {
            handleSubmitForm();
          }}
        >
          Xác nhận
        </Button>
      </div>
    </Form>
  );
}

export default CUExamGroupSetting;

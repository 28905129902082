import {
  setSection,
  getSectionsSuccess,
  getSectionsMenuSuccess,
  getFieldsSuccess,
} from "features/sectionSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getSectionsMenu(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.section}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer) {
        yield put(getSectionsMenuSuccess(_.get(res, "data", {})));
      }
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getSections(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.section}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getSectionsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getFields(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer, params } = data.payload;
  const url = `${ServiceURL.section}/fields/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getFieldsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getSection(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.section}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer) yield put(setSection(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createSection(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.section;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* addFields(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.section}/fields/${params}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateSection(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.section}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteSection(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.section}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* sectionSaga() {
  yield takeLatest("sectionSlice/getSections", getSections);
  yield takeLatest("sectionSlice/getSectionsMenu", getSectionsMenu);
  yield takeLatest("sectionSlice/createSection", createSection);
  yield takeLatest("sectionSlice/deleteSection", deleteSection);
  yield takeLatest("sectionSlice/getSection", getSection);
  yield takeLatest("sectionSlice/updateSection", updateSection);
  yield takeLatest("sectionSlice/getFields", getFields);
  yield takeLatest("sectionSlice/addFields", addFields);
}

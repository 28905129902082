/**
 * Màn hình thêm mới câu hỏi cho phần thi
 * VD: Trắc nghiệm, Semaphore, Dấu đường,...
 * TABLE dạng chọn nhiều, text và ô select
 */
import React, { ReactElement, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import { ColumnsType } from "antd/lib/table";
import { useForm } from "antd/lib/form/Form";
import { PlusSquareOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { AnswerQuestionBank } from "type";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form";
import * as yup from "yup";
import getRuleOfTable, { getValidateField } from "utils";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { updateNamedExports } from "typescript";
const { Title } = Typography;
interface DataType {
  key: number;
  content: string | ReactElement;
  answer: string | ReactElement;
  isAdd: boolean;
}
function getInitDataSource(dataUpdate: DataType | null) {
  if (!dataUpdate) {
    return [
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ];
  }
  const options = _.get(dataUpdate, "options[0]", []);
  const answers: any = options.map(
    (item: AnswerQuestionBank, index: number) => {
      return {
        key: index,
        content: item.content,
        answer: "",
        isAdd: true,
      };
    }
  );
  return [
    ...answers,
    {
      key: answers.length,
      content: "",
      answer: "",
      isAdd: true,
    },
  ];
}
interface AMultiChoiceTextDrag {
  form: FormInstance;
}
function AMultiChoiceTextDrag({ form }: AMultiChoiceTextDrag) {
  const initKey = 0;
  const [dataSource, setDataSource] = useState<DataType[]>(
    getInitDataSource(null)
  );
  const { dataUpdate } = useSelector((state: any) => state.modalReducer);
  const [count, setCount] = useState(initKey + 1);
  const [correctAnswerCount, setCorrectAnserCount] = useState<number>(0);

  // Khởi tạo giá trị cho form sửa
  useEffect(() => {
    if (!!dataUpdate) {
      const stt: any = [];
      const answer: any = [];
      const init: any = getInitDataSource(dataUpdate).map((item: any) => {
        stt.push(undefined);
        answer.push(false);
        return _.get(item, "content", "");
      });
      const options = _.get(dataUpdate, "options[0]", "");
      const correctAnswer = _.get(dataUpdate, "correctAnswer", []);
      let maxSTT = 0;
      if (_.isArray(correctAnswer)) {
        correctAnswer.map((cr: string) => {
          const item = cr.split(":");
          const sttIndex = stt[parseInt(item[1]) - 1] || [];
          stt[parseInt(item[1]) - 1] = [...sttIndex, parseInt(item[0])];
          answer[parseInt(item[1]) - 1] = true;
          stt[parseInt(item[1]) - 1].map((index: number) => {
            if (index > maxSTT) {
              maxSTT = index;
            }
          });
        });
      }
      form.setFieldValue(
        "content",
        options.map((item: AnswerQuestionBank) => item.content)
      );
      form.setFieldValue("stt", stt);
      form.setFieldValue("answer", stt);
      setCount(init.length);
      setDataSource(getInitDataSource(dataUpdate));
      setCorrectAnserCount(maxSTT);
      return;
    }
    // Khởi tạo form thêm mới
    form.resetFields();
    setCount(1);
    setDataSource([
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ]);
  }, [dataUpdate]);
  const getValuesFromFormTable = () => {
    const { stt, content, answer } = form.getFieldsValue();
    if (!_.isArray(stt) || !_.isArray(content) || !_.isArray(answer))
      return null;
    return content.map((item: string, index: number) => ({
      stt: stt[index],
      answer: answer[index],
      content: item,
    }));
  };
  const MImageSchema = yup.object().shape({
    stt: yup.lazy((stt: any) => {
      switch (typeof stt) {
        case "string":
          return yup.string().required("STT không được để trống!");
        case "object":
          return yup
            .array()
            .required("STT không được để trống!")
            .test(
              "check-arr",
              "STT không được để trống!",
              (sttIndex: number[]) => {
                return sttIndex.length !== 0;
              }
            );

        default:
          return yup.string().required("STT không được để trống");
      }
    }),
    content: yup.string().required("Nội dung không được để trống!"),
  });
  const handleDelete = (record: any, key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      content: "",
      answer: "",
      isAdd: true,
    };
    const newDataSource = [...dataSource, newData];
    setDataSource(newDataSource);
    setCount(count + 1);
  };
  //Tính số đáp án đúng dựa vào các ô được check.
  const caculatorCorrectAnswer = () => {
    const arr = form.getFieldValue("answer");
    if (_.isArray(arr)) {
      const count = dataSource.reduce(
        (sum: number, item: DataType, index: number) => {
          if (!!arr[item.key]) return sum + 1;
          return sum;
        },
        0
      );
      if (count > correctAnswerCount) setCorrectAnserCount(count);
      return;
    }
    setCorrectAnserCount(0);
  };
  useEffect(() => {
    if (!!dataUpdate) {
      handleTableChange();
    }
  });
  const handleTableChange = () => {
    caculatorCorrectAnswer();
    form.validateFields();
  };
  const handleChangeContent = (newData: DataType[]) => {
    //Xử lý danh sách lựa chọn
    const keys = newData.map((item: DataType) => item?.key);
    const itemAdd = newData[newData.length - 1];
    const contents: string[] = form
      .getFieldValue("content")
      .map((item: string | undefined, index: number) => {
        return !keys.includes(index) || index === itemAdd.key
          ? undefined
          : item;
      });
    form.setFieldValue("content", contents);
    //Xử lý danh sách đáp án
    const codeAnswer: number[] = [];
    const answers = form.getFieldValue("answer");
    if (_.isArray(answers)) {
      answers.map((answer: undefined | boolean, index: number) => {
        if (!!answer) {
          const ind = dataSource.findIndex(
            (item: DataType) => item.key === index
          );
          codeAnswer.push(ind + 1);
        }
      });
    }
    form.setFieldValue("code", codeAnswer);
  };
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    caculatorCorrectAnswer();
    setDataSource(newData);
    handleTableChange();
  };
  useEffect(() => {
    console.log(correctAnswerCount);
  }, [correctAnswerCount]);
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      width: 200,
      align: "center",
      render(value, record, index) {
        return !!form.getFieldValue(["answer", _.get(record, "key", "")]) ? (
          <Form.Item
            name={["stt", _.get(record, "key", "")]}
            rules={[getValidateField(MImageSchema, "stt")]}
          >
            <Select
              mode="multiple"
              onChange={() => {
                form.validateFields();
              }}
              options={(function () {
                const options = [];
                for (let i = 1; i <= correctAnswerCount; i++) {
                  options.push({
                    label: i,
                    value: i,
                  });
                }
                return options;
              })()}
              dropdownRender={(menu) => {
                return (
                  <>
                    {menu}
                    <Form
                      name="other-form"
                      style={{
                        margin: 0,
                        padding: 0,
                        marginTop: 8,
                        marginLeft: 8,
                        marginRight: 8,
                        display: "flex",
                        flexGrow: 1,
                      }}
                      onFinish={(values) => {
                        if (!!_.get(values, "totalQuestion", "")) {
                          setCorrectAnserCount(
                            parseInt(_.get(values, "totalQuestion", "0"))
                          );
                          form.validateFields();
                        }
                      }}
                    >
                      <Form.Item
                        name={"totalQuestion"}
                        rules={[
                          () => ({
                            validator(_, value) {
                              const totalQuestion = parseInt(value);
                              if (totalQuestion < 1 || totalQuestion > 50) {
                                return Promise.reject(
                                  new Error("Phải là số từ 1-50")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          name="totalQuestion"
                          type="number"
                          min={0}
                          placeholder="Nhập số"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 8 }}
                        >
                          Thêm
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                );
              }}
            />
          </Form.Item>
        ) : (
          value
        );
      },
    },
    {
      title: "Nội dung đáp án",
      dataIndex: "content",
      width: 250,
      align: "center",
      render(value, record, index) {
        return (
          <Form.Item
            name={["content", _.get(record, "key", "")]}
            rules={getRuleOfTable(
              index,
              dataSource,
              getValidateField(MImageSchema, "content")
            )}
          >
            <Input placeholder="Nhập nội dung đáp án" />
          </Form.Item>
        );
      },
    },
    {
      title: "Đáp án đúng",
      dataIndex: "answer",
      width: 70,
      align: "center",
      render(value, record, index) {
        return (
          <Form.Item
            name={["answer", _.get(record, "key", "")]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => {
                handleSave(record);
                form.validateFields();
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: "10%",
      align: "center",
      fixed: "right",
      render: (val, record, index) => {
        return dataSource.length >= 1 ? (
          <Space>
            {index === dataSource.length - 1 ? (
              <a
                onClick={() => {
                  handleAdd();
                }}
              >
                <PlusSquareOutlined
                  style={{
                    fontSize: 16,
                    color: "#1890FF",
                  }}
                />
              </a>
            ) : (
              <Popconfirm
                title="Xóa đáp án này?"
                onConfirm={() => handleDelete(record, _.get(record, "key", ""))}
              >
                <CloseCircleOutlined
                  style={{
                    fontSize: 16,
                    color: "#FF7875",
                  }}
                />
              </Popconfirm>
            )}
          </Space>
        ) : null;
      },
    },
  ];
  return (
    <Form
      form={form}
      onFinish={(values) => {
        handleChangeContent(dataSource);
      }}
    >
      <Table
        bordered
        className="custom-form-item"
        rowKey={"key"}
        onRow={(record) => ({
          onChange: () => handleSave(record),
        })}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: 700, y: 300 }}
      />
    </Form>
  );
}

export default AMultiChoiceTextDrag;

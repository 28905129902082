import { notification } from "antd";
import {
  getQuestionBankSuccess,
  getQuestionBanksSuccess,
  statisticalFieldBysectionSuccess,
} from "features/questionbanksSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, POST_FILE, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getQuestionBanks(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.questionbank}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getQuestionBanksSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* statisticalFieldBysection(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.questionbank}/stat/${params ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(statisticalFieldBysectionSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* getQuestionBank(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.questionbank}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getQuestionBankSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createQuestionBank(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.questionbank;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateQuestionBank(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.questionbank}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteQuestionBank(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.questionbank}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* uploadQuestionFile(dataUpload: ActionType): any {
  const { callback, data, params } = dataUpload.payload;
  const url = `${ServiceURL.questionbank}/import/${params}`;
  try {
    const res = yield call(POST_FILE, url, data);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* uploadQuestionFromSection(dataUpload: ActionType): any {
  const { callback, params } = dataUpload.payload;
  const url = `${ServiceURL.questionbank}/copy/${_.get(params,"fromSection","")}/${_.get(params,"toSection","")}`;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* questionBankSaga() {
  yield takeLatest("questionBankSlice/getQuestionBanks", getQuestionBanks);
  yield takeLatest("questionBankSlice/getQuestionBank", getQuestionBank);
  yield takeLatest("questionBankSlice/statisticalFieldBysection", statisticalFieldBysection);
  yield takeLatest("questionBankSlice/createQuestionBank", createQuestionBank);
  yield takeLatest("questionBankSlice/updateQuestionBank", updateQuestionBank);
  yield takeLatest("questionBankSlice/deleteQuestionBank", deleteQuestionBank);
  yield takeLatest("questionBankSlice/uploadQuestionFile", uploadQuestionFile);
  yield takeLatest("questionBankSlice/uploadQuestionFromSection", uploadQuestionFromSection);
}

import { useEffect, useState } from "react";
import { Form, Select, Space, Typography } from "antd";
import _ from "lodash";
import { useForm } from "antd/lib/form/Form";
import { SelectOptionType } from "type";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form";
import {
  ALLOW_CONTINUE,
  ANSWER_TYPE,
  AS_TYPE,
  IS_OPTION_FORM,
  Y_N,
} from "const";
import ASinggleChoice from "components/AnswerForm/ASinggleChoice";
import AMultiChoiceTextDrag from "components/AnswerForm/AMultiChoiceTextDrag";
import AMultiChoiceImgDrag from "components/AnswerForm/AMultiChoiceImgDrag";
const { Title } = Typography;
interface AnswerForm {
  form: FormInstance;
}

const DEFAULT_VALUE_FORM = [
  {
    answerType: ANSWER_TYPE[0],
    isRandomOption: IS_OPTION_FORM[2],
    isHaveOption: Y_N[0],
    failProcess: ALLOW_CONTINUE[0],
  },
  {
    answerType: ANSWER_TYPE[1],
    isRandomOption: IS_OPTION_FORM[2],
    isHaveOption: Y_N[0],
    failProcess: ALLOW_CONTINUE[0],
  },
  {
    answerType: ANSWER_TYPE[2],
    isRandomOption: IS_OPTION_FORM[2],
    isHaveOption: Y_N[1],
    failProcess: ALLOW_CONTINUE[0],
  },
];
function AnswerForm({ form }: AnswerForm) {
  const [formSelect] = useForm();
  const { dataUpdate } = useSelector((state: any) => state.modalReducer);
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  const [currentAT, setCurrentAT] = useState<any>();
  const [isHaveOption, setIsHaveOption] = useState<boolean>(true);
  const [isKeepOption, setIsKeepOption] = useState<boolean>(true);
  const [isRandomOption, setIsRandomOptions] = useState<boolean | string>("");
  const [failProcess, setFailProcess] = useState<string>(
    ALLOW_CONTINUE[0].value
  );
  const { open } = useSelector((state: any) => state.modalReducer);
  //Láy giá trị cho form sửa
  function getAnswerTypeByValue(val: string) {
    return _.find(AS_TYPE, function (o) {
      return o === val;
    });
  }
  useEffect(() => {
    if (!!dataUpdate) {
      const {
        answerType,
        isHaveOption,
        isRandomOptions,
        failProcess,
        isKeepOption,
      } = dataUpdate;
      const currentAnswerType = getAnswerTypeByValue(answerType);
      formSelect.setFieldValue("answerType", currentAnswerType);
      formSelect.setFieldValue("isHaveOption", !!isHaveOption);
      formSelect.setFieldValue(
        "isRandomOption",
        isRandomOptions === undefined ? "" : !!isRandomOptions
      );
      formSelect.setFieldValue("failProcess", failProcess);
      setCurrentAT(currentAnswerType);
      setIsRandomOptions(!!isRandomOptions);
      setIsHaveOption(!!isHaveOption);
      setIsKeepOption(!!isKeepOption);
      return;
    }
    const initValueFormSelect = DEFAULT_VALUE_FORM.find((item: any) => {
      return (
        _.get(item, "answerType.value", null) ===
        _.get(sectionActive, "answerType", null)
      );
    });
    setCurrentAT(getAnswerTypeByValue(_.get(sectionActive, "answerType", "")));
    formSelect.setFieldsValue(initValueFormSelect);
  }, [dataUpdate]);
  useEffect(() => {
    console.log("isRandomOptions: ", isRandomOption);
    form.setFieldValue("answerType", currentAT);
    form.setFieldValue("isHaveOption", isHaveOption);
    form.setFieldValue("isRandomOption", isRandomOption);
    form.setFieldValue("isKeepOption", isKeepOption);
    form.setFieldValue("failProcess", failProcess);
  }, [currentAT, isHaveOption, isRandomOption, failProcess, isKeepOption]);

  function getTable() {
    form.setFieldValue("answerType", currentAT);
    switch (currentAT) {
      case AS_TYPE[0]:
        return <ASinggleChoice form={form} />;
      case AS_TYPE[1]:
        return <AMultiChoiceTextDrag form={form} />;
      case AS_TYPE[2]:
        return <AMultiChoiceImgDrag form={form} />;
      default:
        return <div></div>;
    }
  }
  return (
    <>
      <Form
        form={formSelect}
        labelCol={{
          span: 5,
        }}
        labelAlign="left"
      >
        <Form.Item
          label={"Dạng đáp án"}
          name={"answerType"}
          initialValue={currentAT}
        >
          <Select
            options={ANSWER_TYPE}
            allowClear
            defaultValue={currentAT}
            placeholder={"Chọn dạng đáp án"}
            onChange={(
              value: any,
              options: SelectOptionType | SelectOptionType[]
            ) => {
              if (!_.isArray(options)) setCurrentAT(options.value);
            }}
          />
        </Form.Item>
        <Form.Item label={"Đảo đáp án"} name={"isRandomOption"}>
          <Select
            options={IS_OPTION_FORM}
            allowClear
            placeholder={"Chọn đảo đáp án"}
            onChange={(value, option) => {
              setIsRandomOptions(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label={"Có lựa chọn"}
          name={"isHaveOption"}
          initialValue={isHaveOption}
        >
          <Select
            onChange={(value, option) => {
              setIsHaveOption(value);
            }}
            options={Y_N}
            allowClear
            placeholder={"Cho phép lựa chọn"}
          />
        </Form.Item>

        <Form.Item
          label={"Giữ lựa chọn"}
          name={"isKeepOption"}
          initialValue={isKeepOption}
        >
          <Select
            onChange={(value, option) => {
              setIsKeepOption(value);
            }}
            options={Y_N}
            allowClear
            placeholder={"Cho phép giữ lựa chọn"}
          />
        </Form.Item>

        <Form.Item label={"Xử lý khi sai"} name={"failProcess"}>
          <Select
            options={ALLOW_CONTINUE}
            allowClear
            placeholder={"Cho phép dừng phần thi khi trả lời sai"}
            onChange={(value, option) => {
              setFailProcess(value);
            }}
          />
        </Form.Item>
      </Form>
      <div
        style={{
          minHeight: 300,
        }}
      >
        <Title level={5}>Danh sách đáp án</Title>
        <Space
          direction="vertical"
          style={{
            width: "100%",
            minHeight: "42vh",
          }}
        >
          {getTable()}
          {/* {getOtherTable()} */}
        </Space>
      </div>
    </>
  );
}

export default AnswerForm;

import { notification } from "antd";
import {
  getExamAttemptSuccess,
  getExamAttemptsSuccess,
  getStatisticalExamAttemptSuccess
} from "features/examAttemptSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getClassrooms } from "sagas/classroomSaga";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, GET_FILE, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getExamAttempts(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.examattempt}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", []));
      yield put(getExamAttemptsSuccess(_.get(res, "data", [])));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getStatisticalExamAttempt(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.examattempt}/stats/${params}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", []));
      yield put(getStatisticalExamAttemptSuccess(_.get(res, "data", [])));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getExamAttempt(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.examattempt}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getExamAttemptSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createExamAttempt(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.examattempt;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateExamAttempt(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.examattempt}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteExamAttempt(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.examattempt}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* exportExamAttempts(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.examattempt}/export/${params}`;
  try {
    const res = yield call(GET_FILE, url);
    if (!!res.data?.error?.message) {
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      !!callback?.success && callback.success(res.data);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* examAttemptSaga() {
  yield takeLatest("examAttemptSlice/getExamAttempts", getExamAttempts);
  yield takeLatest("examAttemptSlice/getStatisticalExamAttempt", getStatisticalExamAttempt);
  yield takeLatest("examAttemptSlice/getExamAttempt", getExamAttempt);
  yield takeLatest("examAttemptSlice/createExamAttempt", createExamAttempt);
  yield takeLatest("examAttemptSlice/updateExamAttempt", updateExamAttempt);
  yield takeLatest("examAttemptSlice/deleteExamAttempt", deleteExamAttempt);
  yield takeLatest("examAttemptSlice/exportExamAttempts", exportExamAttempts);
}

import { Badge, Button, Divider, Modal, Popconfirm, Space, Table, Typography } from "antd";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FULL_DATE_FORMAT } from "const";
import { getStatisticalExamAttempt } from "features/examAttemptSlice";
import _ from "lodash";
import { ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PayloadType } from "type";
interface DataType {
  stt: number;
  idNum: string;
  name: string;
}
type PositionType = "right";
const OperationsSlot: Record<PositionType, React.ReactNode> = {
  right: <Button>Right Extra Action</Button>,
};
const onChange = (key: string) => {
  console.log(key);
};
const { Paragraph } = Typography;
interface StatisticalDetail {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
function StatisticalDetail({ isOpen, setIsOpen }: StatisticalDetail) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { examQuestion } = useSelector(
    (state: any) => state.examQuestionReducer
  );
  const { examAttemptDetail } = useSelector(
    (state: any) => state.examAttemptReducer
  );
  function getStatisticalDetailByExamQuestion() {
    setIsLoading(true);
    const payload: PayloadType = {
      params: _.get(examQuestion, "results[0].id", ""),
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          console.log(errorMessage);
          setIsLoading(false);
        },
      },
    };
    dispatch(getStatisticalExamAttempt(payload));
  }
  useEffect(() => {
    if (isOpen) getStatisticalDetailByExamQuestion();
  }, [ isOpen]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <Badge color={"red"} text={"Chưa vào phần thi"} />,
      children: (
        <TableStatisticalDetail
          data={_.get(examAttemptDetail, "waiting", [])}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "2",
      label: <Badge color={"cyan"} text={"Đã tải dữ liệu phần thi"} />,
      children: (
        <TableStatisticalDetail
          data={_.get(examAttemptDetail, "init", [])}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "3",
      label: <Badge color={"blue"} text={"Đang thi"} />,
      children: (
        <TableStatisticalDetail
          data={_.get(examAttemptDetail, "attempting", [])}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "4",
      label: <Badge color={"green"} text={"Hoàn thành"} />,
      children: (
        <TableStatisticalDetail
          data={_.get(examAttemptDetail, "done", [])}
          isLoading={isLoading}
        />
      ),
    },
  ];
  return (
    <Modal
      onCancel={() => {
        setIsOpen(false);
      }}
      centered
      open={isOpen}
      footer={null}
      width={900}
    >
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        tabBarExtraContent={{
          right: (
            <Button
              style={{ marginRight: 24, color: "rgba(1,1,1,0.5)" }}
              onClick={() => {
                getStatisticalDetailByExamQuestion();
              }}
            >
              <ReloadOutlined />
            </Button>
          ),
        }}
      />
    </Modal>
  );
}

interface TableStatisticalDetail {
  data: string[];
  isLoading: boolean;
}
function TableStatisticalDetail({ data, isLoading }: TableStatisticalDetail) {
  const tableData = data.map((str: string, index: number) => {
    const d = str.split(",");
    return {
      stt: index + 1,
      idNum: _.get(d, "[0]", ""),
      name: _.get(d, "[1]", ""),
    };
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: 70,
    },
    {
      title: "Số báo danh",
      dataIndex: "idNum",
      key: "idNum",
      align: "center",
      width: 240,
    },
    {
      title: "Tên thí sinh",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render(value, record, index) {
        return (
          <Space size="middle">
            <Space>
              <Paragraph style={{margin: 0, padding: 0}} copyable={{text: _.get(data,`[${_.get(record,"stt",-1)-1}]`,"")}}></Paragraph>
            </Space>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
      pagination={{
        showTotal: (total, range) => {
          return `Có tất cả ${total} bản ghi`;
        },
      }}
      scroll={{
        x: 500,
        y: 400,
      }}
    />
  );
}

export default StatisticalDetail;

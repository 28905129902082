import { createSlice } from "@reduxjs/toolkit";

export const roleSlice: any = createSlice({
  name: "roleSlice",
  initialState: {
    roles: [],
    query: {},
    role: "",
  },
  reducers: {
    getRoles: (state, action) => {},
    getRoleByName: (state, action) => {},
    getRolesSuccess: (state, action) => {
      state.roles = action.payload;
    },
    getRoleSuccess: (state, action) => {
      state.role = action.payload;
    },
    setRoleQuery: (state, action) => {
      state.query = action.payload;
    },
    createRole: (state, action) => {},
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});
export const { getRoles, setRole, getRolesSuccess,getRoleSuccess, setRoleQuery, createRole, getRoleByName } =
  roleSlice.actions;
export default roleSlice.reducer;

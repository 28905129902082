import { useEffect } from "react";
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { getUser } from "features/userSlice";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PayloadType } from "type";
import { closeModal, getScore } from "utils";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  deleteExamAttempt,
  getExamAttempts,
  setQuery,
} from "features/examAttemptSlice";
import _ from "lodash";
import moment from "moment";
import { FULL_DATE_FORMAT } from "const";

interface UserInfo {
  idUpdate: string | undefined;
}
interface ColumnType {
  day: string;
  type: string;
  point: string;
  time: string;
}
const { Title } = Typography;
function RUser({ idUpdate }: UserInfo) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { open } = useSelector((state: any) => state.modalReducer);
  const { query, examAttempts } = useSelector(
    (state: any) => state.examAttemptReducer
  );
  const [form] = useForm();

  //Lấy thông tin tài khoản người dùng
  function handleGetUserByID() {
    setIsLoading(true);
    const payload: PayloadType = {
      params: idUpdate,
      callback: {
        success(user) {
          if (!!user) {
            form.setFieldsValue(user);
            handleExamAttempts();
            setIsLoading(false);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin người dùng thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
          closeModal(dispatch, setModal);
        },
      },
    };
    dispatch(getUser(payload));
  }
  useEffect(() => {
    if (!open) {
      form.resetFields();
      return;
    }
    handleGetUserByID();
  }, [open]);
  useEffect(() => {
    if (!!idUpdate) {
      handleGetUserByID();
    }
  }, [idUpdate]);
  //Danh sách kết quả thi
  function handleExamAttempts() {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          select: "-attempt",
          examinee: idUpdate,
          populate: "examQuestion.section",
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      ),
      callback: {
        success(user) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin người dùng thất bại!",
            description: errorMessage,
          });
          setIsLoading(false);
          closeModal(dispatch, setModal);
        },
      },
    };
    dispatch(getExamAttempts(payload));
  }
  useEffect(() => {
    if (!!idUpdate) {
      handleExamAttempts();
    }
  }, [query]);

  const handleDeleteExamAttemptsByID = (id: string) => {
    const payload: PayloadType = {
      params: id,
      callback: {
        success(values) {
          notification.success({
            message: "Xoá lần thi thành công!",
          });
          handleExamAttempts();
        },

        failed(errorMessage) {
          notification.error({
            message: "Xoá lần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(deleteExamAttempt(payload));
  };
  const columns: ColumnsType<ColumnType> = [
    {
      title: "Ngày thi",
      dataIndex: "startTime",
      key: "startTime",
      align: "left",
      render(value, record, index) {
        return !!value ? moment(value).format(FULL_DATE_FORMAT) : "";
      },
    },
    {
      title: "Tên phần thi",
      dataIndex: "sectionName",
      key: "name",
      align: "left",
      render(value, record, index) {
        return _.get(record, "examQuestion.section.name", "");
      },
    },
    {
      title: "Điểm",
      dataIndex: "totalScore",
      key: "totalScore",
      align: "center",
      render(value, record, index) {
        return getScore(value);
      },
    },
    {
      title: "Thời gian hoàn thành (Phút)",
      dataIndex: "totalTime",
      key: "totalTime",
      align: "center",
      render(value, record, index) {
        return Math.floor((value / 60) * 10) / 10;
      },
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render(value, record, index) {
        return (
          <Popconfirm
            title={"Bạn có chắc chắn muốn xóa lần thi thi này?"}
            onConfirm={() => {
              // handleDelete(value);
              handleDeleteExamAttemptsByID(_.get(record, "id", ""));
            }}
            cancelText={"Huỷ"}
          >
            <CloseCircleOutlined
              style={{
                fontSize: 18,
                color: "#FF7875",
              }}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <Spin tip={"Đang tải..."} spinning={isLoading}>
      <div className="ant-modal-body">
        <Row>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên tài khoản"
              value={form.getFieldValue("phone")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên thí sinh"
              value={form.getFieldValue("name")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Email"
              value={form.getFieldValue("email")}
            />
            {/* <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Ngày sinh"
              value={form.getFieldValue("")}
            /> */}
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Giới tính"
              value={form.getFieldValue("gender")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Số báo danh"
              value={form.getFieldValue("idNum")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Địa chỉ"
              value={form.getFieldValue("address")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Đơn vị công tác"
              value={form.getFieldValue("organization")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Chức vụ"
              value={form.getFieldValue("position")}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={5}>Lịch sử thi</Title>
            <Table
              dataSource={_.get(examAttempts, "results", [])}
              columns={columns}
              pagination={{
                current: _.get(examAttempts, "page", 1),
                pageSize: _.get(examAttempts, "limit", 1),
                total: _.get(examAttempts, "totalResults", 1),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
                showSizeChanger: true,
                onChange(page, pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: page,
                      limit: pageSize,
                    })
                  );
                },
              }}
              scroll={{ y: "30vh" }}
            />
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Quay lại
        </Button>
      </div>
    </Spin>
  );
}

export default RUser;

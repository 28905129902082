import { notification } from "antd";
import { getCourseSuccess, getCoursesSuccess } from "features/courseSlice";
import queryString from "query-string";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getClassrooms } from "sagas/classroomSaga";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
var _ = require("lodash");
export function* getCourses(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.course}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getCoursesSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getCourse(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.course}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getCourseSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createCourse(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.course;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateCourse(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.course}/${params}`;
  try {
    const res = yield call(PUT, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteCourse(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.course}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (!!res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    let err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* courseSaga() {
  yield takeLatest("courseSlice/getCourses", getCourses);
  yield takeLatest("courseSlice/getCourse", getCourse);
  yield takeLatest("courseSlice/createCourse", createCourse);
  yield takeLatest("courseSlice/updateCourse", updateCourse);
  yield takeLatest("courseSlice/deleteCourse", deleteCourse);
}

/**
 * Tab Nhóm khác
 */
import { useState, useRef, useEffect } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { InputRef } from "antd";
import { Input, Space } from "antd";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUUser from "pages/AdminUser/CUUser";
import RUser from "pages/AdminUser/RUser";
import { useSelector } from "react-redux";
import _ from "lodash";
import { deleteUser, getUsers, setQuery, updateUser } from "features/userSlice";
import queryString from "query-string";
import { getRoleByName } from "features/roleSlice";
import ImportListUser from "pages/User/ImportListUser";
const Highlighter = require("react-highlight-words");
const { Title, Text } = Typography;
interface DataType {
  key: number;
  name: string;
  phone: string;
  payment: string;
  status: number;
  idNum: string;
  createdAt: string;
}
type DataIndex = keyof DataType;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
const { Content } = Layout;
function AdminUser() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const searchInput = useRef<InputRef>(null);
  const { query, users } = useSelector((state: any) => state.userReducer);
  const { role } = useSelector((state: any) => state.roleReducer);

  //Lấy danh sách tài khoản
  function handleGetUsers() {
    if (!role || _.get(role, "name", "") !== "admin") {
      setIsLoading(true);
      const payload: PayloadType = {
        params: "admin",
        callback: {
          success(role) {
            dispatch(
              setQuery({
                ...query,
                roleId: role,
              })
            );
          },
          failed(errorMessage) {
            notification.error({
              message: "Không thể lấy danh sách tài khoản người dùng!",
              description: "Thiếu quyền!",
            });
            setIsLoading(false);
          },
        },
      };
      dispatch(getRoleByName(payload));
      return;
    }
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          roleId: _.get(role, "id", ""),
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(getUsers(payload));
  }
  useEffect(() => {
    handleGetUsers();
  }, [query]);
  useEffect(() => {
    dispatch(
      setQuery({
        page: 1,
        limit: 10,
      })
    );
  }, []);
  //Cột và định nghĩa ô tìm kiếm
  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""] as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Đặt lại
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const { Paragraph } = Typography;
  const columns: ColumnsType<DataType> = [
    {
      title: "Tài khoản",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone", "Tìm kiếm theo số điện thoại"),
      render: (val, record) => {
        return !!val ? (
          <Paragraph copyable={{ text: val.replace(/\s/g, "") }}>
            {val.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3")}
          </Paragraph>
        ) : (
          ""
        );
      },
    },
    {
      title: "Tên tài khoản",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", "Tìm kiếm theo tên tài khoản"),
    },
    // {
    //   title: "Số báo danh",
    //   dataIndex: "idNum",
    //   key: "idNum",
    //   align: "center",
    //   width: 340,
    //   ...getColumnSearchProps("idNum", "Tìm kiếm theo số báo danh"),
    // },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => {
        return <>{!(val === "active") ? "Khóa" : "Hoạt động"}</>;
      },
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Xem thông tin tài khoản",
                  content: <RUser idUpdate={record?.id} />,
                  size: 700,
                };
                dispatch(setModal(payload));
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa thông tin tài khoản quản trị",
                  content: <CUUser />,
                  dataUpdate: record?.id,
                  size: 500,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn ${
                record?.status === "active" ? "khóa" : "mở khóa"
              } tài khoản ${record?.name ?? ""}  không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (!!id) {
                  const payload: PayloadType = {
                    params: id,
                    body: {
                      ...record,
                      status: record?.status === "active" ? "lock" : "active",
                    },
                    callback: {
                      success: () => {
                        notification.success({
                          message:
                            record?.status === "active"
                              ? "Khóa tài khoản người dùng thành công!"
                              : "Mở khóa tài khoản người dùng thành công!",
                        });
                        handleGetUsers();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: `${
                            record?.status === "active" ? "Khóa" : "Mở khóa"
                          } tài khoản người dùng thành công!`,
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(updateUser(payload));
                }
              }}
            >
              <a>{record?.status === "active" ? "Khóa" : "Mở khóa"}</a>
            </CustomPopconfirm>
            <CustomPopconfirm
              title={
                <Text>
                  Bạn có chắc chắn muốn xóa tài khoản
                  <b>{record?.name ?? ""}</b>&nbsp; không ?
                </Text>
              }
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (!!id) {
                  const payload: PayloadType = {
                    params: id,
                    body: {
                      ...record,
                      status: record?.status === "active" ? "lock" : "active",
                    },
                    callback: {
                      success: () => {
                        notification.success({
                          message: `Xóa tài khoản ${
                            record?.name ?? ""
                          }  thành công!`,
                        });
                        handleGetUsers();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: `Xóa tài khoản ${
                            record?.name ?? ""
                          }  không thành công!`,
                        });
                      },
                    },
                  };
                  dispatch(deleteUser(payload));
                }
              }}
            >
              <a>Xóa</a>
            </CustomPopconfirm>
          </Space>
        );
      },
    },
  ];
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    const q = {
      ...query,
      phone: "",
      name: "",
      idNum: "",
    };
    if (dataIndex === "phone") {
      q["phone"] = selectedKeys[0];
    }
    if (dataIndex === "name") {
      q["name"] = selectedKeys[0];
    }
    if (dataIndex === "idNum") {
      q["idNum"] = selectedKeys[0];
    }
    dispatch(
      setQuery({
        ...query,
        ...q,
        page: 1,
        limit: 10,
      })
    );
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
    dispatch(
      setQuery({
        ...query,
        phone: "",
        page: 1,
        limit: 10,
      })
    );
  };
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Tài khoản người dùng quản trị</Title>
          <Space direction="horizontal" size={"middle"}>
            {/* <Button
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Tải lên",
                  content: <ImportListUser 
                  urlFile={process.env.REACT_APP_TEMPLATE_EXCEL_IMPORT_ADMIN_USER}
                  name={<span>Danh sách tài khoản <b>quản trị</b></span>} handleGetListUser={()=>{handleGetUsers()}} />,
                  size: 400,
                };
                dispatch(setModal(payload));
              }}
              size="large"
            >
              Import danh sách
            </Button> */}
            {/* <Button size="large">Xuất danh sách</Button> */}
          </Space>
        </div>
        <Spin tip={"Đang tải..."} spinning={isLoading}>
          <div
            className="space-between"
            style={{
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            <Title level={5}>Danh sách</Title>
            <Space className="custom-placeholder-select">
              {/* <Select
          style={{
            width: 150
          }}
          placeholder={"Tỉnh"} options={PROVINCES.map((item: SelectOptionType) => ({
              label: item.label,
              value: item.label,
            }))} /> */}
              <Button
                type="primary"
                onClick={() => {
                  const payload: ModalInfoType = {
                    open: true,
                    title: "Thêm mới tài khoản",
                    content: <CUUser />,
                    dataUpdate: "",
                    size: 500,
                  };
                  dispatch(setModal(payload));
                }}
              >
                <PlusOutlined />
                Thêm mới
              </Button>
            </Space>
          </div>
          <Table
            columns={columns}
            dataSource={_.get(users, "results", [])}
            pagination={{
              total: _.get(users, "totalResults", []),
              current: _.get(query, "page", 1),
              pageSize: _.get(query, "limit", 10),
              showTotal: (total, range) => {
                return `Có tất cả ${total} bản ghi`;
              },
              onChange(page, pageSize) {
                if (_.get(query, "limit", 0) !== pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: 1,
                      limit: pageSize,
                    })
                  );
                  return;
                }
                dispatch(
                  setQuery({
                    ...query,
                    page,
                    limit: pageSize,
                  })
                );
              },
            }}
            scroll={{ x: 1300 }}
          />
        </Spin>
      </Space>
    </Content>
  );
}

export default AdminUser;

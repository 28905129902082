import { createSlice } from "@reduxjs/toolkit";

export const QuestionBankSlice: any = createSlice({
  name: "questionBankSlice",
  initialState: {
    questions: [],
    query: {
      page: 1,
      limit: 10,
    },
    question: {},
    filters: null,
    fields: [],
    isUpdateForm: false,
  },
  reducers: {
    getQuestionBanks: (state, action) => {},
    getQuestionBank: (state, action) => {},
    getQuestionBanksSuccess: (state, action) => {
      state.questions = action.payload;
    },
    getQuestionBankSuccess: (state, action) => {
      state.question = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setStateQuestionBank: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createQuestionBank: (state, action) => {},
    updateQuestionBank: (state, action) => {},
    deleteQuestionBank: (state, action) => {},
    statisticalFieldBysection: (state, action) => {},
    statisticalFieldBysectionSuccess: (state, action) => {
      state.fields = action.payload;
    },
    uploadQuestionFile: (state, action) => {},
    uploadQuestionFromSection: (state, action) => {},
  },
});
export const {
  statisticalFieldBysection,
  statisticalFieldBysectionSuccess,
  getQuestionBanks,
  getQuestionBank,
  updateQuestionBank,
  getQuestionBanksSuccess,
  getQuestionBankSuccess,
  setQuery,
  setFilter,
  createQuestionBank,
  deleteQuestionBank,
  setStateQuestionBank,
  uploadQuestionFile,
  uploadQuestionFromSection
} = QuestionBankSlice.actions;
export default QuestionBankSlice.reducer;

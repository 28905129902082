/**
 * Màn hình thêm mới câu hỏi cho phần thi
 * VD: Trắc nghiệm, Semaphore, Dấu đường,...
 * TABLE dạng chọn một, text.
 */
import React, { Key, ReactElement, useEffect, useState } from "react";
import { Form, Input, Table, Typography, notification } from "antd";
import _ from "lodash";
import { ColumnsType } from "antd/lib/table";
import { PlusSquareOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { AnswerQuestionBank, QuestionBankType } from "type";

import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form";
interface DataType {
  key: number;
  content: string | ReactElement;
  answer: string | ReactElement;
  isAdd: boolean;
}
function getInitDataSource(questionUpdate: QuestionBankType | undefined) {
  if (!questionUpdate) {
    return [
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ];
  }
  const answers = questionUpdate.options[0].map(
    (answer: AnswerQuestionBank, index: number) => {
      return {
        key: index,
        content: answer.content,
        answer: "",
        isAdd: false,
      };
    }
  );
  return [
    ...answers,
    {
      key: answers.length,
      content: "",
      answer: "",
      isAdd: true,
    },
  ];
}
interface ASinggleChoice {
  form: FormInstance;
}
function ASinggleChoice({ form }: ASinggleChoice) {
  const initKey = 1;
  const { dataUpdate, open } = useSelector((state: any) => state.modalReducer);
  const [keySelected, setKeySelected] = useState<React.Key[] | undefined>(
    undefined
  );
  const [count, setCount] = useState(initKey + 1);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  //Đóng form thì clear dữ liệu
  useEffect(() => {
    if (!open) setDataSource(getInitDataSource(undefined));
  }, [open]);
  // Khởi tạo giá trị cho form sửa
  useEffect(() => {
    if (!!dataUpdate) {
      const init: any = getInitDataSource(dataUpdate).map((item: any) => {
        return _.get(item, "content", "");
      });
      console.log(_.get(dataUpdate, "correctAnswer"));
      const keySelect: number[] = _.get(dataUpdate, "correctAnswer").map(
        (item: string) => {
          if (item.toString().includes(":")) {
            return parseInt(item.split(":")[1].toString()) - 1; // Thứ tự từ 0
          }
          return [-1];
        }
      );
      form.setFieldValue("content", init);
      form.setFieldValue("correctAnswer", keySelect);
      setCount(init.length);
      setKeySelected(keySelect);
      setDataSource(getInitDataSource(dataUpdate));

      return;
    }
    // Khởi tạo form thêm mới
    form.resetFields();
    setCount(1);
    setKeySelected(undefined);
    setDataSource([
      {
        key: 0,
        content: "",
        answer: "",
        isAdd: true,
      },
    ]);
  }, [dataUpdate]);
  //Bảng chọn đáp án
  const columns: ColumnsType<DataType> = [
    {
      title: "Nội dung đáp án",
      dataIndex: "content",
      key: "content",
      align: "center",
      render(text: any, record: DataType, index: number) {
        return (
          <Form.Item
            className="custom-form-item"
            name={["content", record.key]}
            rules={
              index !== dataSource.length - 1 && dataSource.length > 1
                ? [
                    {
                      required: true,
                      message: "Nội dung đáp án không được để trống!",
                    },
                  ]
                : []
            }
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render(value, record, index) {
        if (index === dataSource.length - 1) {
          return (
            <PlusSquareOutlined
              onClick={async () => {
                try {
                  const values = await form.validateFields();
                  if (!!values) handleAdd();
                } catch (error) {}
              }}
              style={{
                fontSize: 18,
                color: "#1890FF",
              }}
            />
          );
        }
        return (
          <CloseCircleOutlined
            onClick={() => {
              handleDelete(record, record.key);
            }}
            style={{
              fontSize: 18,
              color: "#FF7875",
            }}
          />
        );
      },
    },
  ];
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const handleAdd = () => {
    if (dataSource.length > 4) {
      notification.info({
        message: "Không thể thêm nhiều hơn 4 đáp án!",
      });
      return;
    }
    const newItem: DataType = {
      key: count,
      content: "",
      answer: "",
      isAdd: true,
    };
    const newData = [
      ...dataSource.map((item: DataType) => ({ ...item, isAdd: false })),
      newItem,
    ];
    setDataSource([...newData]);
    setCount(count + 1);
  };
  const handleDelete = (record: DataType, key: number) => {
    const newData = dataSource.filter((item: DataType) => {
      return item.key !== key;
    });
    setDataSource([...newData]);
  };
  // Xử  lý phần chọn của cột đáp án
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], a: any, info: any) => {
      if (_.get(info, "type", "") === "single") {
        setKeySelected(selectedRowKeys); //Thứ tự trên bảng
      }
    },
  };
  const handleChangeContent = (newData: DataType[]) => {
    const keys = newData.map((item: DataType) => item?.key);
    const itemAdd = newData[newData.length - 1];
    const contents: string[] = form
      .getFieldValue("content")
      .map((item: string | undefined, index: number) => {
        return !keys.includes(index) || index === _.get(itemAdd, "key", "")
          ? undefined
          : item;
      });
    const temp = _.get(keySelected, "[0]", -1); //Thứ tự từ 1
    const correctKey = dataSource.findIndex(
      (item: DataType) => _.get(item, "key", "").toString() === temp.toString()
    );
    form.setFieldValue("key", correctKey + 1);
    if (correctKey === -1) return;
    form.setFieldValue("content", contents);
  };
  return (
    <>
      <Form
        form={form}
        onFinish={(values) => {
          handleChangeContent(dataSource);
        }}
      >
        <Table
          rowSelection={{
            type: "radio",
            columnTitle: "Đáp án",
            columnWidth: 100,
            selectedRowKeys: keySelected,
            ...rowSelection,
          }}
          rowKey={"key"}
          size="middle"
          onRow={(record) => ({
            onChange: () => handleSave(record),
          })}
          rowClassName={() => "editable-row"}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        ></Table>
      </Form>
    </>
  );
}

export default ASinggleChoice;

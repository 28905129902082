import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Tooltip,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { PROVINCES, USER_SATTUS } from "const";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import { closeModal, getBody, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import _ from "lodash";
import { createUser, getUser, getUsers, updateUser } from "features/userSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
import ResetPassModal from "pages/User/ResetPassModal";
interface UserInfo {
  idUpdate: string | undefined;
}
function CUUser() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const { open, dataUpdate } = useSelector((state: any) => state.modalReducer);
  //Lấy danh sách tài khoản
  function handleGetUser() {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      }),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }
  const formSchema = yup.object().shape({
    name: yup.string().required("Tên thí sinh không được để trống!"),
    email: yup.string().email("Không đúng định dạng email!"),
    phone: yup
      .string()
      .required("Tên tài khoản không được để trống!"),
    address: yup.string().required("Địa chỉ không được để trống!"),
    idNum: yup.string().required("Số báo danh không được để trống!"),
    gender: yup.string().required("Giới tính không được để trống!"),
    password: yup
      .string()
      .required("Mật khẩu không được để trống!")
      .matches(
        /[^wd]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))/g,
        "Mật khẩu phải chứa ít nhất một chữ và một số!"
      )
      .min(8, "Mật khẩu phải có ít nhất 8 ký tự!"),
    confirmPassword: yup
      .string()
      .required("Vui lòng xác nhận lại mật khẩu!")
      .test("check-confirmpass", "Mật khẩu không khớp!", (confirmPass) => {
        return confirmPass === form.getFieldValue("password");
      }),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "name",
      "gender",
      "email",
      "phone",
      "roleId",
      "address",
      "position",
      "organization",
      "idNum",
      "password",
    ]);
    if (!dataUpdate) {
      handleCreateUser(values);
      return;
    }
    handleUpdateUser(values);
  }
  function handleCreateUser(values: UserType) {
    setLoading(true);
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const roleUser: Role | null = results.find(
              (item: Role) => item.name === "user"
            );
            if (!!roleUser) {
              const payload: PayloadType = {
                body: getBody(
                  {
                    ...values,
                    roleId: roleUser.id,
                  },
                  []
                ),
                callback: {
                  success(values) {
                    notification.success({
                      message: "Tạo mới người dùng thành công!",
                    });
                    handleGetUser();
                    closeModal(dispatch, setModal);
                    setLoading(false);
                  },
                  failed(errorMessage) {
                    notification.error({
                      message: "Không thể  tạo mới người dùng!",
                      description: errorMessage,
                    });
                    setLoading(false);
                  },
                },
              };
              dispatch(createUser(payload));
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới người dùng!",
            description: "Thiếu quyền!",
          });
          setLoading(false);
        },
      },
    };
    dispatch(getRoles(payload));
  }
  function handleUpdateUser(values: UserType) {
    setLoading(true);
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const roleUser: Role | null = results.find(
              (item: Role) => item.name === "user"
            );
            if (!!roleUser) {
              const payload: PayloadType = {
                params: dataUpdate,
                body: getBody(
                  {
                    ...values,
                    roleId: roleUser.id,
                  },
                  []
                ),
                callback: {
                  success(values) {
                    notification.success({
                      message: "Cập nhật thông tin người dùng thành công!",
                    });
                    handleGetUser();
                    closeModal(dispatch, setModal);
                    setLoading(false);
                  },
                  failed(errorMessage) {
                    notification.error({
                      message: "Không thể cập nhật thông tin người dùng!",
                      description: errorMessage,
                    });
                    setLoading(false);
                  },
                },
              };
              dispatch(updateUser(payload));
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin người dùng!",
            description: "Thiếu quyền!",
          });
          setLoading(false);
        },
      },
    };
    dispatch(getRoles(payload));
  }
  //Lấy thông tin tài khoản người dùng
  function handleGetUserByID() {
    setLoading(true);
    const payload: PayloadType = {
      params: dataUpdate,
      callback: {
        success(user) {
          if (!!user) {
            form.setFieldsValue(user);
          }
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lấy thông tin người dùng thất bại!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(getUser(payload));
  }
  useEffect(() => {
    if (!!dataUpdate) handleGetUserByID();
  }, [dataUpdate]);
  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (!!open && !!dataUpdate) {
      handleGetUserByID();
    }
  }, [open]);
  return (
    <Spin tip={"Đang tải..."} spinning={loading}>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={() => {
          handleSubmit();
        }}
        autoComplete="off"
      >
        <ResetPassModal
          idUpdate={dataUpdate}
          notificationModal={notificationModal}
          handleToggle={() => {
            setNotificationModal(!notificationModal);
          }}
        />
        <div className="ant-modal-body">
          <Form.Item
            required
            label={"Tên tài khoản"}
            name={"phone"}
            rules={yupSync}
          >
            <Input placeholder="Nhập vào số điện thoại" />
          </Form.Item>
          <Form.Item
            required
            label={"Số báo danh"}
            name={"idNum"}
            rules={yupSync}
          >
            <Input placeholder="Nhập vào số báo danh" />
          </Form.Item>
          <Form.Item label={"Email"} name={"email"} rules={yupSync} >
            <Input placeholder="Nhập vào email" autoComplete={"off"} type="text"  />
          </Form.Item>
          {!!dataUpdate ? (
            <Form.Item required label={"Mật khẩu"}>
              <a
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setNotificationModal(true);
                }}
              >
                Đặt lại mật khẩu
              </a>
            </Form.Item>
          ) : (
            <>
              <Form.Item
                required
                label={"Mật khẩu"}
                name={"password"}
                rules={yupSync}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="Nhập mật khẩu"
                />
              </Form.Item>
              <Form.Item
                required
                label={"Xác nhận mật khẩu"}
                name={"confirmPassword"}
                rules={yupSync}
              >
                <Input.Password
                  autoComplete="off"
                  placeholder="Xác nhận mật khẩu"
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            required
            label={"Tên thí sinh"}
            name={"name"}
            rules={yupSync}
          >
            <Input placeholder="Nhập vào tên thí sinh" />
          </Form.Item>
          <Form.Item
            required
            label={"Giới tính"}
            name={"gender"}
            rules={yupSync}
          >
            <Radio.Group>
              <Radio value={"Nam"}>Nam</Radio>
              <Radio value={"Nữ"}>Nữ</Radio>
              <Radio value={"Khác"}>Khác</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            required
            label={"Địa chỉ"}
            name={"address"}
            rules={yupSync}
          >
            <Input placeholder="Nhập địa chỉ" />
          </Form.Item>
          <Form.Item label={"Đơn vị công tác"} name={"organization"}>
            <Input placeholder="Nhập đơn vị công tác" />
          </Form.Item>
          <Form.Item label={"Chức vụ"} name={"position"}>
            <Input placeholder="Nhập chức vụ" />
          </Form.Item>
        </div>
        <div className="ant-modal-footer">
          <Button
            htmlType="submit"
            type="default"
            onClick={() => {
              closeModal(dispatch, setModal);
            }}
          >
            Huỷ
          </Button>
          <Button
            onClick={() => {
              if (!loading) form.submit();
            }}
            type="primary"
            loading={loading}
          >
            {!!dataUpdate ? "Xác nhận" : "Thêm mới"}
          </Button>
        </div>
      </Form>
    </Spin>
  );
}

export default CUUser;

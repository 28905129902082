/**
 * Màn hình quản trị tổ chức lớp
 */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Popconfirm,
  Row,
  Select,
  Spin,
  Typography,
  notification,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { memo, Dispatch, SetStateAction, useState, useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import {
  ModalInfoType,
  PayloadType,
  QuestionBankType,
  SectionType,
  SelectOptionType,
} from "type";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import _ from "lodash";
import {
  deleteQuestionBank,
  getQuestionBank,
  getQuestionBanks,
  setQuery,
} from "features/questionbanksSlice";
import { setModal } from "features/modalSlice";
import UploadQuestion from "components/QuestionBank/UploadQuestion";
import { AS_TYPE, QS_TYPE, QUESTION_TYPE } from "const";
import CUForm from "components/CUForm";
import UploadQuestionFromSection from "components/QuestionBank/UploadQuestionFromSection";
const parse = require("html-react-parser");

const { Title, Paragraph } = Typography;
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
interface QuestionBank {
  section: SectionType;
}
function QuestionBank({ section }: QuestionBank) {
  const dispatch = useDispatch();
  const { query, questions } = useSelector(
    (state: any) => state.questionbanksReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const { sectionActive } = useSelector((state: any) => state.sectionReducer);
  // useEffect(() => {
  //   dispatch(
  //     setQuery({
  //       ...query,
  //       page: 1,
  //       limit: 10,
  //     })
  //   );
  // }, [sectionActive]);
  //Lấy danh sách câu hỏi
  function handleGetQuestionBank() {
    setIsLoading(true);
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        section: section?.id ?? "",
      }),
      callback: {
        success(values) {
          setIsLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Lỗi lấy danh sách câu hỏi!",
            description: errorMessage,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(getQuestionBanks(payload));
  }
  useEffect(() => {
    handleGetQuestionBank();
  }, [query]);
  //Định nghĩa cột của bảng
  const columns: ColumnsType<QuestionBankType> = [
    {
      title: (
        <h4
          style={{
            margin: 0,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          STT
        </h4>
      ),
      width: 50,
      dataIndex: "no",
      key: "no",
      align: "center",
      render(value, record, index) {
        return (
          index +
          (parseInt(_.get(query, "page", 0)) - 1) *
            parseInt(_.get(query, "limit", 0)) +
          1
        );
      },
    },
    ...getVideoColumn(_.get(questions, "results", "")),
    {
      title: "Nội dung câu hỏi",
      width: 250,
      dataIndex: "content",
      key: "content",
      render(value, record, index) {
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
      },
    },
    ...getOptionColumn(),
    ...getAnswerColumn(),
    {
      title: "Loại câu hỏi",
      className: "align-top",
      width: 120,
      dataIndex: "questionType",
      key: "questionType",
      align: "center",
      render(value, record, index) {
        return (
          QUESTION_TYPE.find((item: SelectOptionType) => item.value === value)
            ?.label ?? ""
        );
      },
    },
    {
      title: "Lĩnh vực",
      width: 120,
      dataIndex: "field",
      className: "align-top",
      key: "field",
      align: "center",
      render(value, record, index) {
        return value;
      },
    },
    {
      title: "Hành động",
      width: 100,
      className: "align-top",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      align: "center",
      render: (value, record: any) => (
        <Space size="middle">
          <Space>
            <a
              onClick={() => {
                const payload: PayloadType = {
                  params: record?.id ?? "",
                  callback: {
                    success(questionUpdate: QuestionBankType) {
                      const payload: ModalInfoType = {
                        open: true,
                        title: `Sửa câu hỏi ${_.get(
                          sectionActive,
                          "name",
                          ""
                        )}`,
                        content: (
                          <CUForm
                            handleGetQuestionBank={() => {
                              handleGetQuestionBank();
                            }}
                          />
                        ),
                        dataUpdate: questionUpdate,
                        size: 1110,
                      };
                      dispatch(setModal(payload));
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Lấy thông tin câu hỏi thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(getQuestionBank(payload));
              }}
            >
              Sửa
            </a>
            <Divider type="vertical" />
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn xóa câu hỏi này không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                handleDeleteQuestion(record?.id ?? "");
              }}
            >
              <a>Xoá</a>
            </CustomPopconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  //Chức năng xóa
  function handleDeleteQuestion(id: string) {
    const payload: PayloadType = {
      params: id,
      callback: {
        success(values) {
          notification.success({
            message: "Xóa câu hỏi thành công!",
          });
          handleGetQuestionBank();
        },
        failed(errorMessage) {
          notification.error({
            message: "Xóa câu hỏi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(deleteQuestionBank(payload));
  }
  return (
    <Spin tip={"Đang tải..."} spinning={isLoading}>
      <div
        className="space-between"
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Title level={4}>Danh sách câu hỏi</Title>
        <Space
          direction="horizontal"
          size={"middle"}
          className="custom-placeholder-select"
        >
          <Button
            type="default"
            size="middle"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Chọn phần thi",
                content: (
                  <UploadQuestionFromSection
                    handleGetQuestionBank={() => {
                      handleGetQuestionBank();
                    }}
                  />
                ),
                size: 320,
              };
              dispatch(setModal(payload));
            }}
          >
            <UploadOutlined />
            Import câu hỏi từ phần thi khác
          </Button>
          <Button
            type="default"
            size="middle"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Tải lên",
                content: (
                  <UploadQuestion
                    handleGetQuestionBank={() => {
                      handleGetQuestionBank();
                    }}
                  />
                ),
                size: 320,
              };
              dispatch(setModal(payload));
            }}
          >
            <UploadOutlined />
            Import câu hỏi
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // const payload: ModalInfoType = {
              //   open: true,
              //   title: "Thêm mới câu hỏi trắc nghiệm",
              //   content: <UQuestion section={section} />,
              //   size: 1110,
              //   dataUpdate: null
              // };
              const payload: ModalInfoType = {
                open: true,
                title: `Thêm mới câu hỏi ${_.get(sectionActive, "name", null)}`,
                content: (
                  <CUForm
                    handleGetQuestionBank={() => {
                      handleGetQuestionBank();
                    }}
                  />
                ),
                size: 1110,
                dataUpdate: null,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={_.get(questions, "results", [])}
        pagination={{
          total: _.get(questions, "totalResults", []) ?? 0,
          current: _.get(questions, "page", []),
          pageSize: _.get(questions, "limit", []),
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          showSizeChanger: true,
          onChange(page, pageSize) {
            if (pageSize !== _.get(query, "limit", 0)) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 2000, y: 500 }}
      />
    </Spin>
  );
}

function getVideoColumn(results: any[]): ColumnsType<QuestionBankType> {
  const isVideo = _.isArray(results)
    ? results.some((res: any) => _.get(res, "questionType", "") === QS_TYPE[1])
    : false;
  if (_.isArray(results) && results.length > 0 && isVideo) {
    return [
      {
        title: "Video",
        dataIndex: "othersContent",
        key: "othersContent",
        width: 120,
        render(value, record, index) {
          const url = _.get(value, "video.url", "");
          return !!url ? (
            <Paragraph copyable={{ text: url }}>
              <a
                href={url}
                target="_blank"
                onClick={() => {
                  window.open(
                    url,
                    "_blank",
                    "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=2500,width=400,height=400"
                  );
                }}
              >
                video_{index}
              </a>
            </Paragraph>
          ) : (
            ""
          );
        },
      },
    ];
  }

  return [];
}
function getOptionColumn(): ColumnsType<QuestionBankType> {
  return [
    {
      title: "Danh sách lựa chọn ",
      width: 300,
      dataIndex: "options",
      key: "options",
      className: "align-top",
      align: "center",
      render(value, record, index) {
        const answerType = _.get(record, "answerType", "");
        const isQuestion1MT =
          !_.get(record, "isRandomOptions", true) &&
          !_.get(record, "isHaveOption", true);
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {value[0].map((item: any, index: number, arr: any[]) => {
              if (answerType === AS_TYPE[1]) {
                // Câu hỏi dạng multiple text drag
                return (
                  <>
                    <span>({item.code})&nbsp;</span>
                    <span>
                      {item.content}
                      {index === arr.length - 1 ? (
                        <span>. &nbsp;</span>
                      ) : (
                        <span>, &nbsp;</span>
                      )}
                    </span>
                  </>
                );
              }
              if (answerType === AS_TYPE[2]) {
                // Câu hỏi dạng multiple iamge drag
                const imageUrl = !!item?.content
                  ? item.content
                  : _.get(item, "image", "");
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>({index + 1})</span>
                    <img
                      src={imageUrl}
                      alt={"img"}
                      style={{
                        maxWidth: 100,
                        margin: 8,
                      }}
                    />
                  </div>
                );
              }
              // Câu hỏi dạng single choice text drag
              if (isQuestion1MT) {
                return (
                  <Space
                    align="start"
                    style={{
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "justify",
                        color:
                          parseInt(`${record.correctAnswer[0]}`) === index + 1
                            ? "#389E0D"
                            : undefined,
                        fontWeight:
                          parseInt(`${record.correctAnswer[0]}`) === index + 1
                            ? 700
                            : 400,
                      }}
                    >
                      {item.content}
                    </p>
                  </Space>
                );
              }
              return (
                <Space
                  align="start"
                  style={{
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      textAlign: "justify",
                      color:
                        parseInt(`${record.correctAnswer[0]}`) === index + 1
                          ? "#389E0D"
                          : undefined,
                      fontWeight:
                        parseInt(`${record.correctAnswer[0]}`) === index + 1
                          ? 700
                          : 400,
                    }}
                  >
                    {String.fromCharCode(65 + index)}. {item.content}
                  </p>
                </Space>
              );
            })}
          </div>
        );
      },
    },
  ];
}
function getAnswerColumn(): ColumnsType<QuestionBankType> {
  return [
    {
      title: "Danh sách đáp án",
      width: 250,
      dataIndex: "correctAnswer",
      key: "correctAnswer",
      className: "align-top",
      align: "center",
      render(value, record, index) {
        const answerType = _.get(record, "answerType", "");
        const codeCR: any = [];
        const answerIndex: string[] = [];
        value.map((item: string) => {
          const code = item.split(":")[0];
          const content = item.split(":")[1];
          codeCR.push(code);
          answerIndex.push(content);
        });
        const options = _.get(record, "options[0]", []);
        const answer = _.find(options, function (o) {
          return answerIndex.includes(o?.code?.toString());
        });
        //multiple choice text drag
        if (answerType === AS_TYPE[1]) {
          const indexContent: any = [];
          answerIndex.map((ind: string, index: number) => {
            const arr = indexContent[parseInt(ind)];
            if (_.isArray(arr))
              indexContent[parseInt(ind)] = [...arr, codeCR[index]];
            else indexContent[parseInt(ind)] = [codeCR[index]];
          });
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {indexContent.map(
                (num: number[] | null, index: number, arr: any[]) => {
                  if (!num) return <></>;
                  const option = _.find(options, function (o) {
                    return o.code.toString() === num.toString();
                  });
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      <span>({index})</span>
                      &nbsp;
                      <span>{option?.content ?? ""}</span>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  );
                }
              )}
            </div>
          );
          // return (
          //   <div
          //     style={{
          //       width: "100%",
          //       display: "flex",
          //       flexWrap: "wrap",
          //     }}
          //   >
          //     {answerIndex.map((item: string, index: number, arr: any[]) => {
          //       const option = _.find(options, function (o) {
          //         return o.code.toString() === item.toString();
          //       });
          //       return (
          //         <span
          //           style={{
          //             color: "green",
          //             fontWeight: "bold",
          //           }}
          //         >
          //           <span>({index + 1})&nbsp;</span>
          //           <span>
          //             {option?.content}
          //             {index === arr.length - 1 ? (
          //               <span>. &nbsp;</span>
          //             ) : (
          //               <span>, &nbsp;</span>
          //             )}
          //           </span>
          //         </span>
          //       );
          //     })}
          //     {/* {_.get(record, "options", [])[0]
          //       .filter((item: AnswerQuestionBank, index: number) => {
          //         return answerIndex.includes(item.code.toString());
          //       })
          //       .map((item: AnswerQuestionBank, index: number, arr: any[]) => {
          //         return (
          //           <span
          //             style={{
          //               color: "green",
          //               fontWeight: "bold",
          //             }}
          //           >
          //             <span>({codeCR[index]})&nbsp;</span>
          //             <span>
          //               {item.content}
          //               {index === arr.length - 1 ? (
          //                 <span>. &nbsp;</span>
          //               ) : (
          //                 <span>, &nbsp;</span>
          //               )}
          //             </span>
          //           </span>
          //         );
          //       })} */}
          //   </div>
          // );
        }
        //multiple choice img drag
        if (answerType === AS_TYPE[2]) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {answerIndex.map((num: string | null, index: number) => {
                if (!num) return <></>;
                const option = _.find(options, function (o) {
                  return o.code.toString() === num.toString();
                });
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>({index + 1})</span>
                    <img
                      src={option?.image}
                      alt={"img"}
                      style={{
                        maxWidth: 80,
                        margin: 8,
                        border: "1px solid green",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        }
        //single choice
        const isQuestion1MT =
          !_.get(record, "isRandomOptions", true) &&
          !_.get(record, "isHaveOption", true);
        if (isQuestion1MT) {
          return (
            <div
              style={{
                width: "100%",
                color: "#389E0D",
                fontWeight: "bold",
              }}
            >
              {answer?.content}
            </div>
          );
        }
        const codeActive = _.get(answer, "code", 0);
        return (
          <div
            style={{
              width: "100%",
              color: "#389E0D",
              fontWeight: "bold",
            }}
          >
            {`${String.fromCharCode(
              64 +
                (typeof codeActive === "number"
                  ? codeActive
                  : parseInt(codeActive))
            )}. ${answer?.content}`}
          </div>
        );
      },
    },
  ];
}
export default memo(QuestionBank);

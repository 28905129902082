import { createSlice } from "@reduxjs/toolkit";

export const ExamAttemptSlice: any = createSlice({
  name: "examAttemptSlice",
  initialState: {
    examAttempts: [],
    examAttemptDetail: [],
    query: {
      sortBy: "totalScore:desc,totalTime",
      populate: "examinee",
      select: "-attempt",
      page: 1,
      limit: 10,
    },
    examAttempt: {},
    filters: null,
    isUpdateForm: false,
  },
  reducers: {
    getExamAttempts: (state, action) => {},
    getExamAttempt: (state, action) => {},
    getStatisticalExamAttempt: (state, action) => {},
    getStatisticalExamAttemptSuccess: (state, action) => {
      state.examAttemptDetail = action.payload;
    },
    getExamAttemptsSuccess: (state, action) => {
      state.examAttempts = action.payload;
    },
    getExamAttemptSuccess: (state, action) => {
      state.examAttempt = action.payload;
    },
    setExamAttemps: (state, action) => {
      state.examAttempts = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setStateExamAttempt: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createExamAttempt: (state, action) => {},
    updateExamAttempt: (state, action) => {},
    deleteExamAttempt: (state, action) => {},
    exportExamAttempts: (state, action) => {},
  },
});
export const {
  getExamAttempts,
  getExamAttempt,
  updateExamAttempt,
  getExamAttemptsSuccess,
  getExamAttemptSuccess,
  setQuery,
  setFilter,
  createExamAttempt,
  deleteExamAttempt,
  setStateExamAttempt,
  setExamAttemps,
  exportExamAttempts,
  getStatisticalExamAttempt,
  getStatisticalExamAttemptSuccess
} = ExamAttemptSlice.actions;
export default ExamAttemptSlice.reducer;

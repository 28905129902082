import { Content } from "antd/lib/layout/layout";

function CustomContent({ children }: any) {
  return (
    <Content
      className="custom-layout-content"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      {children}
    </Content>
  );
}

export default CustomContent;

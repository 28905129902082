import { Col, Empty, Row, Space, Spin } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ExamQuestionType, SectionType } from "type";
import { useState } from "react";
import CUExam from "pages/PageCommon/Exam/CUExam";
import { useDispatch } from "react-redux";
import { getExamQuestionSuccess } from "features/examQuestionSlice";
interface PageCommonType {
  handleGetExamQuestion: () => void;
}
function Exam({ handleGetExamQuestion }: PageCommonType) {
  const [examQuestionInfo, setExamQuestionInfo] = useState<any>();
  const { examQuestion, isLoading } = useSelector(
    (state: any) => state.examQuestionReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const results = _.get(examQuestion, "results");
    if (!!results && Array.isArray(results) && results.length > 0) {
      setExamQuestionInfo(results[0]);
    } else {
      setExamQuestionInfo(null);
      dispatch(getExamQuestionSuccess(null));
    }
  }, [examQuestion]);
  return (
    <Row
      style={{
        // backgroundColor: "green",
        minHeight: "50vh",
        display: "flex",
        alignItems: "stretch",
      }}
    >
      {!!examQuestionInfo ? (
        <Col span={24}>
          <Spin tip={"Đang tải..."} spinning={isLoading}>
            <CUExam handleGetExamQuestion={handleGetExamQuestion} />
          </Spin>
        </Col>
      ) : (
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<p>Chưa có kì thi nào.</p>}
          ></Empty>
        </Col>
      )}
    </Row>
  );
}

export default Exam;

import { createSlice } from "@reduxjs/toolkit";

export const schoolSlice: any = createSlice({
  name: "schoolSlice",
  initialState: {
    schools: [],
    query: {
      page: 1,
      limit: 10,
    },
    filters: null,
    school: {},
  },
  reducers: {
    getSchools: (state, action) => {},
    getSchool: (state, action) => {},
    getSchoolsSuccess: (state, action) => {
      state.schools = action.payload;
    },
    getSchoolSuccess: (state, action) => {
      state.school = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setStateSchool: (state: any, action) => {
      Object.keys(action.payload).map((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createSchool: (state, action) => {},
    updateSchool: (state, action) => {},
    deleteSchool: (state, action) => {},
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
});
export const {
  getSchools,
  getSchool,
  updateSchool,
  getSchoolsSuccess,
  getSchoolSuccess,
  setQuery,
  createSchool,
  deleteSchool,
  setStateSchool,
  setFilter
} = schoolSlice.actions;
export default schoolSlice.reducer;

import accountReducer from "features/accountSlice";
import userReducer from "features/userSlice";
import roleReducer from "features/roleSlice";
import schoolReducer from "features/schoolSlice";
import classroomReducer from "features/classroomSlice";
import provinceReducer from "features/provinceSlice";
import districtReducer from "features/districtSlice";
import awardReducer from "features/awardSlice";
import courseReducer from "features/courseSlice";
import paymentReducer from "./paymentSlice";
import organizationReducer from "features/organizationSlice";
import modalReducer from "features/modalSlice";
//HDH
import sectionReducer from "./sectionSlice";
import routeReducer from "features/routeSlice";
import pageTypeReducer from "features/pageTypeSlice";
import questionbanksReducer from "features/questionbanksSlice";
import fieldReducer from "features/fieldSlice";
import examQuestionReducer from "features/examQuestionSlice";
import examGroupReducer from "./examGroupSlice";
import examAttemptReducer from "features/examAttemptSlice";
export const reducer = {
  accountReducer,
  userReducer,
  roleReducer,
  schoolReducer,
  classroomReducer,
  provinceReducer,
  districtReducer,
  awardReducer,
  courseReducer,
  paymentReducer,
  organizationReducer,
  modalReducer,
  sectionReducer,
  routeReducer,
  pageTypeReducer,
  questionbanksReducer,
  fieldReducer,
  examQuestionReducer,
  examGroupReducer,
  examAttemptReducer
};

/**
 * Màn hình ngân hàng câu hỏi
 */
import { PayloadType, SectionType } from "type";
import { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Modal,
  Tabs,
  TabsProps,
  Typography,
  notification,
} from "antd";
import { Space } from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { ModalInfoType } from "type";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTab } from "features/organizationSlice";
import QuestionBank from "components/QuestionBank";
import Exam from "pages/PageCommon/Exam";
import { setModal } from "features/modalSlice";
import CUExam from "pages/PageCommon/Exam/CUExam";
import Statistical from "pages/PageCommon/Statistical";
import FileDownload from "js-file-download";
import {
  getExamQuestion,
  updateExamQuestion,
} from "features/examQuestionSlice";
import queryString from "query-string";
import { setSectionActive } from "features/sectionSlice";
import { exportExamAttempts } from "features/examAttemptSlice";
import CustomContent from "pages/components/CustomContent";
const { Title } = Typography;
interface PageCommonType {
  section: SectionType;
}

function PageCommon({ section }: PageCommonType) {
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();
  const { tab } = useSelector((state: any) => state.organizationReducer);
  const [tabActive, setTabActive] = useState<any>(tab);
  const [currentExamQuestion, setCurrentExamQuestion] = useState<any>();
  const { examQuestion } = useSelector(
    (state: any) => state.examQuestionReducer
  );
  //Các tabs
  const itemsTabList: TabsProps["items"] = [
    {
      key: "1",
      label: `Ngân hàng câu hỏi`,
      children: <QuestionBank section={section} />,
    },
    {
      key: "2",
      label: `Kỳ thi`,
      children: <Exam handleGetExamQuestion={handleGetExamQuestion} />,
    },
    {
      key: "3",
      label: `Thống kê`,
      children: <Statistical section={section} />,
    },
  ];
  //Lấy kì thi
  function handleGetExamQuestion() {
    const payload: PayloadType = {
      query: queryString.stringify({
        populate: "section",
        section: section?.id ?? "",
      }),
      callback: {
        success(examQuestion) {
          const results = _.get(examQuestion, "results", []);
          if (_.isArray(results) && results.length > 0) {
            setCurrentExamQuestion(results[0]);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Lỗi lấy thông tin kì thi!",
          });
        },
      },
    };
    dispatch(getExamQuestion(payload));
  }
  useEffect(() => {
    handleGetExamQuestion();
    dispatch(setSectionActive(section));
  }, [section]);
  useEffect(() => {
    if (tab === "2") {
      handleGetExamQuestion();
      dispatch(setSectionActive(section));
    }
  }, [tab]);
  const handleDownloadFile = (data: any, filename: any) => {
    FileDownload(data, filename);
  };
  return (
    <CustomContent>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Quản lý thi {_.get(section, "name", "")}</Title>
          <Space className="custom-placeholder-select">
            {tab === "2" && (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    modal.confirm({
                      title: `Xác nhận ${
                        _.get(currentExamQuestion, "status", "") == "active"
                          ? "khóa"
                          : "mở"
                      } kì thi`,
                      icon: <ExclamationCircleOutlined />,
                      content: `Bạn có chắc chắn muốn ${
                        _.get(currentExamQuestion, "status", "") == "active"
                          ? "khóa"
                          : "mở"
                      } kì thi này?`,
                      okText: "Có",
                      cancelText: "Không",
                      onOk: () => {
                        const id = _.get(currentExamQuestion, "id", "");
                        if (!!id) {
                          const payload: PayloadType = {
                            params: id,
                            body: {
                              status:
                                _.get(currentExamQuestion, "status", "") ===
                                "active"
                                  ? "lock"
                                  : "active",
                            },
                            callback: {
                              success(values) {
                                notification.success({
                                  message: `${
                                    _.get(currentExamQuestion, "status", "") ===
                                    "active"
                                      ? "Khóa"
                                      : "Mở"
                                  } kỳ thi thành công!`,
                                });
                                handleGetExamQuestion();
                              },
                              failed(errorMessage) {
                                notification.success({
                                  message: `${
                                    _.get(currentExamQuestion, "status", "") ===
                                    "active"
                                      ? "Khóa"
                                      : "Mở"
                                  } kỳ thi thất bại!`,
                                  description: errorMessage,
                                });
                              },
                            },
                          };
                          dispatch(updateExamQuestion(payload));
                        }
                      },
                    });
                  }}
                  style={{
                    backgroundColor:
                      _.get(currentExamQuestion, "status", "") === "active"
                        ? "#FF7875"
                        : "#4DA81A",
                    display: !!_.get(currentExamQuestion, "status", "")
                      ? undefined
                      : "none",
                  }}
                  icon={
                    _.get(currentExamQuestion, "status", "") === "active" ? (
                      <LockOutlined />
                    ) : (
                      <UnlockOutlined />
                    )
                  }
                >
                  {_.get(currentExamQuestion, "status", "") === "active"
                    ? "Đóng"
                    : "Mở"}{" "}
                  kỳ thi
                </Button>
                {contextHolder}
                <Button
                  type="primary"
                  onClick={() => {
                    const payload: ModalInfoType = {
                      title: "Thêm mới kỳ thi",
                      content: (
                        <CUExam handleGetExamQuestion={handleGetExamQuestion} />
                      ),
                      size: 1300,
                      open: true,
                    };
                    dispatch(setModal(payload));
                  }}
                  icon={<PlusOutlined />}
                  style={{
                    display: !!examQuestion ? "none" : undefined,
                  }}
                >
                  Thêm mới
                </Button>
              </Space>
            )}
            {tab === "3" && (
              <Button
                type="primary"
                onClick={() => {
                  const results = _.get(examQuestion, "results");
                  if (Array.isArray(results) && results.length > 0) {
                    const id = results[0]?.id;
                    if (!!id) {
                      const payload: PayloadType = {
                        params: id,
                        callback: {
                          success(data) {
                            handleDownloadFile(data, `Danh_sach_ket_qua.xlsx`);
                            notification.success({
                              message: "Xuất danh sách thành công!",
                            });
                          },
                          failed(errorMessage) {
                            notification.error({
                              message: "Xuất danh sách thất bại!",
                              description: errorMessage,
                            });
                          },
                        },
                      };
                      dispatch(exportExamAttempts(payload));
                    }
                  }
                }}
                icon={<ExportOutlined />}
              >
                Xuất danh sách
              </Button>
            )}
          </Space>
        </div>
        <Tabs
          tabBarStyle={{
            margin: 0,
            paddingBottom: 12,
          }}
          defaultActiveKey={tabActive}
          onChange={(keyActive) => {
            if (["1", "2", "3"].includes(keyActive)) {
              dispatch(setTab(keyActive));
              setTabActive(keyActive);
            }
          }}
          items={itemsTabList}
        />
      </Space>
    </CustomContent>
  );
}

export default PageCommon;
